import React from 'react'
import { SessionStatus } from './SessionStatus'

export function SessionHeader ({ session, identifierClassName, isLoading, isOnline, children }) {
  if (!session) return null

  return (
    <div className='flex w-full items-center justify-between text-slate'>
      <SessionStatus session={session} isLoading={isLoading} isOnline={isOnline} identifierClassName={identifierClassName}>
        {children}
      </SessionStatus>
    </div>
  )
}
