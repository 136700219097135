import React from 'react'
import mergeClassNames from '../../utils/mergeClassNames'
import ViewRecordingButton from './ViewRecordingButton'
import OpenSessionButton from './OpenSessionButton'

export function SessionAction ({ session, className, viewRecordingClassName }) {
  if (session == null) return null

  const hasRecording = session.state === 'ended' && session.recorded
  const recordingExpired = !hasRecording && session.recording?.deleted

  if (session.state !== 'active' && !hasRecording && !recordingExpired) return null

  if (session.state === 'active') {
    return (
      <OpenSessionButton session={session} className={className} isFullWidth />
    )
  }

  return (
    <ViewRecordingButton
      session={session}
      disabled={recordingExpired}
      className={mergeClassNames(!hasRecording && !recordingExpired && 'invisible lg:hidden', className, viewRecordingClassName)}
    />
  )
}
