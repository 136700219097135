import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { GenerateJoinLink } from '../accounts/GenerateJoinLink'
import JoinToken from '../accounts/JoinToken.state'
import { StepContainer } from './StepContainer'

const DEFAULT_ROLE = 'administrator'

const TeamStep = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const createJoinToken = (role) => dispatch(JoinToken.actionCreators().createJoinToken(role))

  return (
    <StepContainer>
      <StepContainer.Header>
        <StepContainer.Title>{t('Invite your team')}</StepContainer.Title>
        <StepContainer.Description>{t('Add members to your Cobrowse team by sharing the link below. You can generate a new link at any time from your account settings.')}</StepContainer.Description>
      </StepContainer.Header>
      <StepContainer.Body>
        <GenerateJoinLink
          className='gap-y-4'
          formClassName='max-md:flex max-md:flex-col max-md:gap-y-3 md:gap-x-3'
          buttonClassName='max-md:justify-center'
          createJoinToken={createJoinToken}
          defaultRole={DEFAULT_ROLE}
        />
      </StepContainer.Body>
    </StepContainer>
  )
}

export default TeamStep
