import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import AllowedRegions from '../accounts/AllowedRegions.state'
import ArrayMapEditor from '../../components/ArrayMapEditor'
import SessionConfig from '../sessions/SessionConfig.state'
import Label from '../../components/Label'
import Collapsible from '../../components/Collapsible'

const editOnlyKeys = ['text/html']

const AdvancedConfiguration = ({ account }) => {
  const [regions, setRegions] = useState([])
  const [sessionConfig, setSessionConfig] = useState(null)

  useEffect(() => {
    const fetchAllowedRegions = async () => {
      const res = await AllowedRegions.fetch(AllowedRegions.types.GET, {}, { forceAccount: account.license_key })
      const resource = await res.json()
      if (res.status === 200) setRegions(resource.allowed_regions)
    }
    const fetchSessionConfig = async () => {
      const res = await SessionConfig.fetch(SessionConfig.types.GET, {}, { forceAccount: account.license_key })
      const resource = await res.json()
      if (res.status === 200) setSessionConfig(resource)
    }

    fetchAllowedRegions()
    fetchSessionConfig()
  }, [account.license_key])

  const updateAllowedRegions = async (value) => {
    const regions = value.split(',').map(region => region.trim()).filter(region => !!region)
    const res = await AllowedRegions.fetch(AllowedRegions.types.UPDATE, { allowed_regions: regions }, { forceAccount: account.license_key })
    const resource = await res.json()
    if (res.status === 200) setRegions(resource.allowed_regions)
  }

  const updateSessionConfig = async (contentTypes) => {
    const res = await SessionConfig.fetch(SessionConfig.types.UPDATE, { content_types: contentTypes }, { forceAccount: account.license_key })
    const resource = await res.json()
    if (res.status === 200) setSessionConfig(resource)
  }

  return (
    <>
      <Collapsible title='Regions' className='flex flex-col gap-y-2'>
        <Label htmlFor='allowed-regions'>Allowed Regions</Label>
        <Input id='allowed-regions' onBlur={(e) => updateAllowedRegions(e.target.value)} defaultValue={regions.join(',')} />
      </Collapsible>
      <Collapsible title='Universal Cobrowse' className='flex flex-col gap-y-2'>
        <Label>Proxy Content Types</Label>
        {sessionConfig && (
          <ArrayMapEditor
            arrayMap={sessionConfig.content_types}
            onArrayMapChange={updateSessionConfig}
            keyPlaceHolder='text/html'
            valuePlaceHolder='*.mysite.com'
            editOnlyKeys={editOnlyKeys}
          />
        )}
      </Collapsible>
    </>
  )
}

export default AdvancedConfiguration
