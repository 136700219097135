import React, { forwardRef } from 'react'
import Input from './Input'

function isElementInView (container, element) {
  if (container == null || element == null) return false

  const containerRect = container.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()

  return elementRect.right <= containerRect.right
}

export const FocusableInput = forwardRef(({
  onFocus,
  onChange,
  scrollableContainerRef,
  focusableElementRef,
  onEnter,
  onKeyDown,
  ...props
}, ref) => {
  const handleOnFocus = (evt) => {
    onFocus?.(evt)

    focusableElementRef?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }

  const handleOnChange = (evt) => {
    onChange?.(evt)

    requestAnimationFrame(() => {
      if (!isElementInView(scrollableContainerRef?.current, focusableElementRef?.current)) {
        focusableElementRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        })
      }
    })
  }

  const handleKeyDown = (e) => {
    if (onEnter != null && e.key === 'Enter') {
      onEnter()
    }

    onKeyDown?.(e)
  }

  return (
    <Input
      ref={ref}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      {...props}
    />
  )
})
