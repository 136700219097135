import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { Icon } from '../../components/Icon'
import NumberInput from '../../components/NumberInput'
import { SegmentedInput } from '../../components/SegmentedInput'
import mergeClassNames from '../../utils/mergeClassNames'
import {
  PLAN_NAMED_AGENT,
  PLAN_ACTIVE_SESSIONS,
  PLAN_ALL_NAMED_AGENT,
  PLAN_ALL_ACTIVE_SESSIONS
} from './PlanTypes'

// Force these translation keys to be added/updated automatically
// t('month')
// t('year')

const SubscriptionPicker = ({ checkout, plans, planType, onPlanSelected }) => {
  const [paymentInterval, setPaymentInterval] = useState('year')
  const [quantity, setQuantity] = useState(1)
  const { t } = useTranslation()

  const doCheckout = (plan) => {
    onPlanSelected({
      id: plan.id,
      quantity,
      interval: paymentInterval
    })
  }

  const planTypeLabel = () => {
    const labels = {
      [PLAN_NAMED_AGENT]: t('named agents'),
      [PLAN_ACTIVE_SESSIONS]: t('active sessions')
    }

    return labels[planType]
  }

  const planPricePerMonth = (plan) => {
    return plan.pricing[paymentInterval] * Math.max(1, quantity) / (paymentInterval === 'month' ? 1 : 12)
  }

  const renderPlan = (plan) => {
    const isAllPlan = [PLAN_ALL_NAMED_AGENT, PLAN_ALL_ACTIVE_SESSIONS].includes(plan.id)

    return (
      <div
        key={plan.id}
        className={mergeClassNames('flex w-full flex-col gap-6 rounded-3xl bg-gray-50 p-8', isAllPlan && 'bg-slate text-slate-50')}
      >
        <div className='flex flex-col gap-y-2'>
          <h3 className={mergeClassNames('text-2xl font-medium text-slate', isAllPlan && 'text-slate-50')}>{plan.title}</h3>
          <ul className='flex flex-col gap-y-2'>
            {plan.features.map((feature, index) => (
              <li key={index} className='flex w-full items-start gap-x-2 text-base'>
                <div className='mt-1 text-violet'>
                  <Icon type='circle-check-outline' size='small' />
                </div>
                <p className={mergeClassNames('leading-6 text-gray-700', isAllPlan && 'text-slate-400')}>{feature}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-auto flex flex-col gap-y-6'>
          <div className='flex items-center gap-3'>
            <div className={mergeClassNames('text-slate', isAllPlan && 'text-slate-50')}>
              <span className='text-lg'>$</span>
              <span className='text-5xl'>
                {planPricePerMonth(plan)}
              </span>
            </div>
            <div>
              <p className={mergeClassNames('text-xs text-gray-700', isAllPlan && 'text-slate-400')}>
                <span className='lowercase'>{t('/agent per month billed {{interval}}.', { interval: paymentInterval === 'year' ? t('Annually') : t('Monthly') })}</span>
                {' '}
                {t('Each licensed named agent has access during the period. Licenses may not be shared.')}
              </p>
            </div>
          </div>
          <div>
            <Button
              variant='primary'
              thinking={checkout.working}
              disabled={checkout.working}
              onClick={() => doCheckout(plan)}
              className={mergeClassNames('w-full justify-center', isAllPlan && 'bg-lime text-slate hover:bg-lime-400')}
            >
              {t('Checkout')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <div className='flex flex-col flex-wrap gap-6 self-center md:flex-row md:justify-between md:self-auto'>
        <div className='flex items-center justify-center gap-2 text-left md:justify-normal'>
          <NumberInput value={quantity} onChange={setQuantity} label={t('Quantity')} />
          <p className='w-28 text-xs text-gray-700'>{t('Number of {{type}}', { type: planTypeLabel() })}</p>
        </div>
        <div className='self-center lg:self-auto'>
          <SegmentedInput value={paymentInterval} onChange={setPaymentInterval}>
            <SegmentedInput.Option
              defaultChecked
              value='year'
            >
              {t('Annually')} <span className='text-violet-500 ui-checked:text-violet-400'>{t('Save 10%')}</span>
            </SegmentedInput.Option>
            <SegmentedInput.Option value='month'>{t('Monthly')}</SegmentedInput.Option>
          </SegmentedInput>
        </div>
      </div>
      <div className='flex w-full flex-col gap-6 self-center md:flex-row md:gap-8 md:self-center'>
        {plans.map(renderPlan)}
      </div>
    </div>
  )
}

export default SubscriptionPicker
