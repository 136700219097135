import React, { useEffect, useRef, useState } from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const Callout = ({ content, onOpen, onClose, className, contentClassName, children, ...props }) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)
  const contentRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleOpen = () => {
    setOpen(true)

    onOpen && onOpen()
  }

  const handleClose = () => {
    setOpen(false)

    onClose && onClose()
  }

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    handleOpen()
  }

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      handleClose()
    }, 500)
  }

  const handleBlur = (e) => {
    if (wrapperRef.current && (wrapperRef.current.contains(e.relatedTarget))) {
      return
    }

    handleClose()
  }

  const openClassName = 'opacity-100 pointer-events-auto translate-y-0'
  const hoverClassName = 'group-hover:opacity-100 group-hover:pointer-events-auto group-hover:translate-y-0'
  const renderContent = typeof content === 'function'
    ? content
    : () => content

  return (
    <div
      ref={wrapperRef}
      className={mergeClassNames('group relative z-10', className)}
      onFocus={handleOpen}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      {children}
      <div
        ref={contentRef}
        className={mergeClassNames('pointer-events-none absolute block translate-y-1 overflow-hidden rounded-md bg-white text-left opacity-0 shadow-lg transition-all', open && openClassName, hoverClassName, contentClassName)}
      >
        {open && renderContent()}
      </div>
    </div>
  )
}

export default Callout
