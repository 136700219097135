import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../errors/ErrorMessage'
import LoadingScreen from '../../components/LoadingScreen'
import Device from './Device.state'
import FilterParams from '../filters/FilterParams'
import EmbedParams from '../../utils/EmbedParams'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import useGetDevicePresence from '../../hooks/useGetDevicePresence'
import { useDeviceSelector } from '../../hooks/useDeviceSelector'
import { DeviceRow } from './DeviceRow'

const Container = ({ children }) => {
  return (
    <div className='flex basis-full flex-col'>
      {children}
    </div>
  )
}

const FindDevice = () => {
  const [cancelled, setCancelled] = useState(false)
  const dispatch = useDispatch()
  const devices = useDeviceSelector()
  const pollTimeout = useRef(null)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useDocumentTitle(t('Find device'))

  const getPresence = useGetDevicePresence()

  const pollDevices = useCallback(() => {
    if ((!FilterParams.search) && FilterParams.empty()) {
      return Promise.reject(new Error('No filter params'))
    }

    // limit to recently registered devices
    return dispatch(Device.actionCreators().getDevices(undefined, { ...FilterParams.parse(), filter: FilterParams.search, seen_after: EmbedParams.seenAfter() }))
      .then((devices) => {
        // Clear any existing timeout to avoid duplicate polling during development due to strict mode
        clearTimeout(pollTimeout.current)

        pollTimeout.current = setTimeout(() => {
          pollDevices().catch(err => console.error('Device poll error: ', err))
        }, 3000)

        if (devices.length) return Promise.all(devices.map(getPresence))
      })
  }, [dispatch, getPresence])

  useEffect(() => {
    pollDevices().catch(err => console.error('Device poll error: ', err))

    return () => {
      clearTimeout(pollTimeout.current)
    }
  }, [pollDevices])

  const cancel = () => {
    clearTimeout(pollTimeout.current)

    if (EmbedParams.endAction() === 'none') {
      setCancelled(true)
    } else {
      navigate(EmbedParams.endRedirect())
    }
  }

  const devicesCollection = devices.collection

  useEffect(() => {
    if (devicesCollection.length === 1 || (EmbedParams.forceStart() && devicesCollection.length >= 1)) {
      // Check if there is any device that's expecting presence but doesn't have it yet
      if (devicesCollection.some(device => Device.isAwaitingPresence(device))) {
        return
      }

      const connectableDevice = devicesCollection.find(device => device.connectable)

      if (connectableDevice) {
        navigate(`/connect/device/${connectableDevice.id}${window.location.search}`, { replace: true })
      }
    }
  }, [devicesCollection, navigate])

  if ((!FilterParams.search) && FilterParams.empty()) {
    return (
      <Container>
        <ErrorMessage>{t('You must specify at least one filter parameter')}</ErrorMessage>
      </Container>
    )
  }

  if (cancelled) {
    return (
      <Container>
        <div className='container mx-auto flex h-full w-10/12 items-center justify-center'>
          <p>{t('Search stopped')}</p>
        </div>
      </Container>
    )
  }

  if ((!EmbedParams.forceStart()) && devicesCollection.length > 1) {
    return (
      <Container>
        <h1 className='sr-only'>{t('Find device')}</h1>
        <div className='flex flex-col gap-y-2 p-4 pb-2 md:p-6 md:pb-4'>
          <h2 className='text-2xl font-medium'>{t('Multiple devices found')}</h2>
          <p>{t('Multiple devices were found that match your filters. Select which one you would like to connect to:')}</p>
        </div>
        <div className='flex flex-col'>
          {devicesCollection.map((device) => (
            <div className='group/list-item' key={device.id}>
              <DeviceRow
                getPresence={getPresence}
                deviceClassName='cursor-default'
                {...device}
              />
            </div>
          ))}
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <LoadingScreen
        message={t('Searching for devices')}
        cancelText={t('Cancel')}
        onCancel={cancel}
      />
    </Container>
  )
}

export default FindDevice
