import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePageTitle } from '../../hooks/usePageTitle'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import CodeEntry from './CodeEntry'

const CodeEntryScreen = () => {
  const { t } = useTranslation()

  usePageTitle(t('Code entry'))
  useDocumentTitle(t('Code entry'))

  return (
    <div className='flex flex-1 flex-col items-center justify-center text-center'>
      <div className='p-2.5'>{t('Enter a session code from the customer')}</div>
      <CodeEntry />
    </div>
  )
}

export default CodeEntryScreen
