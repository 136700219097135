import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import Button, { buttonClassName } from '../../components/Button'
import CodeEntry from '../sessions/CodeEntry'
import FilterParamsEditor from '../filters/FilterParamsEditor'
import NewDeviceNotification from './NewDeviceNotification'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import Account from '../accounts/Account.state'
import { NavLink } from 'react-router-dom'
import { Icon } from '../../components/Icon'
import { useClickOutside } from '../../hooks/useClickOutside'
import StandardFields from './StandardFields'
import { useHasNewDevices } from './useHasNewDevices'
import { showUpgradeBanner } from '../billing/UpgradeBanner'
import EmbedParams from '../../utils/EmbedParams'
import { tw } from '../../utils/tw'

export function DeviceListHeader ({ devices, filters, refreshDevices, filterEditorRef }) {
  const { t } = useTranslation()
  const account = useActiveAccountSelector()

  const [codeEntryOpen, setCodeEntryOpen] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)

  const closeCodeEntry = useCallback(() => {
    setCodeEntryOpen(false)
  }, [])

  const buttonContainerRef = useRef(null)
  const codeEntryRef = useRef(null)
  useClickOutside(buttonContainerRef, closeCodeEntry, codeEntryOpen)

  const autocompleteFields = useMemo(() => StandardFields.customData(), [])
  const suggestionsFor = useCallback((field) => {
    if (!devices) return []

    const suggestions = devices.collection.map(d => d.custom_data && d.custom_data[field]).filter(v => v)

    return uniq(suggestions.map(v => `${v}`))
  }, [devices])

  const hasNewDevices = useHasNewDevices(devices.collection, filters)

  useEffect(() => {
    if (!codeEntryOpen) {
      codeEntryRef.current.clear()
    } else {
      setShowBackdrop(true)
      codeEntryRef.current.focus(0)
    }
  }, [codeEntryOpen])

  const bottomButtonsTransition = mergeClassNames('opacity-100 transition-opacity duration-100 motion-reduce:transition-none', codeEntryOpen && 'pointer-events-none opacity-0 duration-150')
  const codeEntryInputStyles = [tw`-left-24 md:-left-36`, tw`-left-12 md:-left-24`, tw`left-0 md:-left-12`, tw`left-12 md:left-0`, tw`left-24 md:left-12`, tw`left-36 md:left-24`]

  return (
    <>
      <div className='sticky top-0 z-10 grid gap-2 border-b border-gray-200 bg-white p-3 md:flex md:justify-between md:gap-4 md:px-4 md:py-3'>
        <FilterParamsEditor
          ref={filterEditorRef}
          loading={devices.working}
          placeholder={t('Filter devices...')}
          autocompleteFields={autocompleteFields}
          getSuggestions={suggestionsFor}
          onUpdated={refreshDevices}
        />
        <Button
          variant='text'
          size='small'
          isIconButton
          onClick={refreshDevices}
          {...getTestId('refresh-devices-button')}
          aria-label={t('Refresh')}
          title={t('Refresh')}
          className='max-md:hidden'
        >
          <Icon type='refresh' size='medium' spin={devices.working} reverse />
          <NewDeviceNotification hasNewDevices={hasNewDevices} />
        </Button>
      </div>

      <div className='relative flex justify-between gap-2 border-gray-200 p-3 max-md:border-b md:z-40 md:flex md:gap-4 md:p-0'>
        <div className={mergeClassNames('flex gap-4 md:fixed md:bottom-6 md:left-1/2 md:-translate-x-1/2 md:gap-2 md:p-1', EmbedParams.navigation() && 'md:ms-[30.5px] lg:ms-[74.5px]', showUpgradeBanner(account) && EmbedParams.navigation() && 'lg:ms-[112.5px]', codeEntryOpen && '')}>
          {Account.hasFeature(account.resource, 'present_mode', true) && (
            <NavLink
              className={buttonClassName({ variant: 'primary', isIconButton: true, className: bottomButtonsTransition })}
              to='/dashboard/present'
              {...getTestId('navigation-present')}
            >
              <Icon type='slideshare' />
              <span className='sr-only'>{t('Present')}</span>
            </NavLink>
          )}
          <div className='relative'>
            <Button type='primary' onClick={() => setCodeEntryOpen(true)} className={bottomButtonsTransition} {...getTestId('open-code-entry')}>{t('6-digit code')}</Button>
            <div
              className={mergeClassNames('pointer-events-none absolute left-0 top-1/2 opacity-0 transition-opacity duration-100 motion-reduce:transition-none md:translate-x-1/2', codeEntryOpen && 'pointer-events-auto opacity-100 duration-200')}
              ref={buttonContainerRef}
            >
              <CodeEntry
                focusOnRender
                ref={codeEntryRef}
                inputClassName={(index) => mergeClassNames('absolute left-0 translate-x-full transition-all duration-100 md:translate-x-1/2', codeEntryOpen && codeEntryInputStyles[index], codeEntryOpen && 'duration-200 md:shadow-[0_0_15px_0_theme(colors.white/70)] md:has-[:focus]:!shadow-[0_0_15px_0_theme(colors.white/70)] md:hover:shadow-[0_0_15px_0_theme(colors.white/70)]')}
              >
                <Button
                  variant='text'
                  isIconButton
                  onClick={() => setCodeEntryOpen(false)}
                  className={mergeClassNames('absolute left-0 translate-x-full opacity-0 transition-all duration-100 md:translate-x-1/2', codeEntryOpen && 'left-48 opacity-100 duration-200 md:left-36')}
                  {...getTestId('close-code-entry')}
                >
                  <Icon type='close' size='medium' />
                </Button>
              </CodeEntry>
            </div>
          </div>
        </div>

        <Button
          variant='text'
          isIconButton
          onClick={refreshDevices}
          {...getTestId('refresh-devices-button')}
          aria-label={t('Refresh')}
          title={t('Refresh')}
          className={mergeClassNames('opacity-100 transition-opacity duration-300 motion-reduce:transition-none md:hidden', codeEntryOpen && 'opacity-0')}
        >
          <Icon type='refresh' size='medium' spin={devices.working} reverse />
          <NewDeviceNotification hasNewDevices={hasNewDevices} />
        </Button>
      </div>
      <div
        className={mergeClassNames('transition-all duration-300 motion-reduce:transition-none md:fixed md:z-30', showBackdrop && ' md:inset-0 md:backdrop-blur', !codeEntryOpen && 'md:!backdrop-blur-0')}
        onTransitionEnd={() => {
          if (showBackdrop && !codeEntryOpen) setShowBackdrop(false)
        }}
      />
    </>
  )
}
