import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'
import { Icon } from '../../components/Icon'

const LoginButton = ({ text, icon, color, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      {...props}
      isFullWidth
      className={mergeClassNames('cursor-pointer gap-x-2 overflow-hidden border border-solid border-transparent p-0 px-2 text-left', className)}
      style={{ background: color }}
    >
      <Icon type={icon} size='large' />
      <div>{text ?? t('Sign in')}</div>
    </Button>
  )
}

export default LoginButton
