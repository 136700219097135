import React from 'react'
import IconContainer from '../../components/IconContainer'

const UserIcon = ({ className, user }) => {
  const initials = () => {
    if (!user) return ''
    if (!user.name) return ''
    if (user.picture) return ''

    return user.name.split(' ')
      .map(i => i.toUpperCase().charAt(0))
      .join('')
      .substring(0, 2)
  }

  if (!user) {
    return null
  }

  return (
    <IconContainer
      className={className}
      role='img'
      title='User profile'
      style={{
        backgroundImage: user.picture ? `url(${user.picture || ''})` : undefined,
        backgroundColor: user.colour
      }}
    >
      <span className='text-slate-900'>{initials()}</span>
    </IconContainer>
  )
}

export default UserIcon
