import React, { useEffect, useRef, useState } from 'react'
import Button from './Button'
import mergeClassNames from '../utils/mergeClassNames'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { Icon } from './Icon'

const CopyText = ({ id, className, label, hideLabel = false, hideConfirmation = false, children, ...props }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const textRef = useRef()
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const copyText = () => {
    navigator.clipboard.writeText(text()).catch(() => {
      // Fallback for iframes without clipboard permissions
      selectText()
      document.execCommand('copy')
    })

    if (!hideConfirmation) {
      setCopied(true)

      timeoutRef.current = setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }

  const selectText = (event) => {
    textRef.current.select()
  }

  const text = () => {
    const value = children

    return Array.isArray(value)
      ? value.join('')
      : value
  }

  return (
    <Input
      ref={textRef}
      id={id}
      className={className}
      value={text()}
      readOnly
      onClick={selectText}
      label={label}
      hideLabel={hideLabel}
      action={
        <>
          <Button
            variant='plain'
            size='none'
            className='group'
            title={t('Copy to clipboard')}
            onClick={copyText}
          >
            <Icon
              type='copy'
              className='p-1 text-gray-600 transition-colors ease-in group-hover:text-slate motion-reduce:transition-none'
            />
          </Button>
          {!hideConfirmation && (
            <div className={
              mergeClassNames(
                'pointer-events-none absolute start-0 top-0 flex size-full items-center justify-center bg-violet-50/50 opacity-0 transition duration-300',
                copied && 'pointer-events-auto opacity-100 backdrop-blur-sm'
              )
            }
            >
              <span>{t('Copied!')}</span>
            </div>
          )}
        </>
      }
      {...props}
    />
  )
}

export default CopyText
