import { useEffect, useState } from 'react'
import Device from './Device.state'
import useThrottle from '../../hooks/useThrottle'

function getMostRecentDeviceTimestamp (devices) {
  if (!devices || devices.length === 0) {
    return 0
  }

  const actives = devices.map(device => device.last_active && new Date(device.last_active).getTime())

  return Math.max(...actives)
}

function newDevicesFound (currentDevices, newDevices) {
  if (!currentDevices || !newDevices) {
    return false
  }

  const mostRecentInDeviceList = getMostRecentDeviceTimestamp(currentDevices)

  return newDevices.find(device => {
    return new Date(device.last_active).getTime() > mostRecentInDeviceList
  })
}

export function useHasNewDevices (devices, filters) {
  const [mountedAt, setMountedAt] = useState()
  const [newDevices, setNewDevices] = useState([])

  const refresh = useThrottle(async () => {
    if (!devices) return

    const mostRecentInDeviceList = getMostRecentDeviceTimestamp(devices)

    // no point trying if we're offline
    if (navigator.onLine === false) return
    // ignore requested right after load
    if ((Date.now() - mountedAt) < 10 * 1000) return
    if (!document.hasFocus()) return
    // no point polling for more if we are already displaying the notification
    if (newDevicesFound()) return

    const res = await Device.fetch(Device.types.GET, {}, { seen_after: new Date(mostRecentInDeviceList), filters })
    const resource = await res.json()

    if (res.status === 200) {
      setNewDevices(resource)
    }
  }, 10 * 1000)

  useEffect(() => {
    window.addEventListener('mousemove', refresh)

    const refreshInterval = setInterval(refresh, 30 * 1000)

    setMountedAt(Date.now())

    return () => {
      window.removeEventListener('mousemove', refresh)
      clearInterval(refreshInterval)
      refresh.cancel()
    }
  }, [refresh])

  return newDevicesFound(devices, newDevices)
}
