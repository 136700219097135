export const UPDATE_UI_STATE = 'ui/UPDATE'

const initialState = {
  selectedColor: null,
  sidebarOpen: false,
  hasActiveDrawings: false,
  pageTitle: ''
}

export default function ui (state = initialState, action) {
  switch (action.type) {
    case UPDATE_UI_STATE:
      return { ...state, ...action.state }

    default:
      return state
  }
}

export const updateUiState = (state) => {
  return {
    type: UPDATE_UI_STATE,
    state
  }
}

export function setPageTitle (pageTitle = '') {
  return updateUiState({ pageTitle })
}
