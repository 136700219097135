import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Alert from './Alert'
import useGlobalErrorSelector from '../hooks/useGlobalErrorSelector'
import { resetGlobalError } from '../modules/errors/Error.state'
import { useTranslation } from 'react-i18next'

const NETWORK_FAILURE_ID = 'network_failure'
const NETWORK_OFFLINE_ID = 'network_offline'
const SESSION_NOT_FOUND_ID = 'session_not_found'

const GlobalError = ({ variant = 'floating', hideChildrenIfBlocking = false, children }) => {
  const dispatch = useDispatch()
  const globalError = useGlobalErrorSelector()
  const { t } = useTranslation()
  const dismissError = () => dispatch(resetGlobalError())
  const isNetworkError = [NETWORK_FAILURE_ID, NETWORK_OFFLINE_ID].includes(globalError?.id)

  // Dismiss the error when the component is mounted just in case the issue
  // that caused it has been resolved
  useEffect(() => {
    dispatch(resetGlobalError())
  }, [dispatch])

  // Ignore session not found errors, triggered when the user enters an
  // invalid session code
  if (globalError?.id === SESSION_NOT_FOUND_ID) {
    // we don't want to show an error but returning children causes everything to unmount
    globalError.id = null
  }

  // Dismiss network errors after a few seconds
  if (isNetworkError) {
    setTimeout(() => {
      dispatch(resetGlobalError())
    }, 5000)
  }

  const blocking = globalError?.id?.endsWith('_limit')
  // "Limit" based errors should be blocking, and
  // it doesn't make sense to dismiss them
  const hideChildren = hideChildrenIfBlocking && blocking

  return (
    <div className={blocking ? 'relative flex size-full items-center justify-center' : 'contents'}>
      {globalError?.id && (
        <Alert
          type='alert'
          variant={variant}
          fixed={!blocking}
          onDismiss={!blocking ? dismissError : undefined}
          aria-live='assertive'
        >
          <span className='sr-only'>{t('An error has occurred')}:</span>
          {!isNetworkError && <span className='me-1 font-bold'>{globalError.id}</span>}
          {t(globalError.message)}
        </Alert>
      )}
      {!hideChildren && children}
    </div>
  )
}

export default GlobalError
