import React, { Component } from 'react'
import { VirtualDOM, CompressionError } from '@cobrowseio/vdom-utils'
import DOMDocument from './DOMDocument'
import DOMEvents from './DOMEvents'
import DOMNode from './DOMNode'
import Frame from './Frame'
import { getScrollBarSize } from '../../utils/scrollbars'
import { getTestId } from '../../utils/getTestId'
import withPriority from '../../utils/withPriority'
import NodeTracker from './NodeTracker'

export default class DOMScreen extends Component {
  constructor () {
    super()
    this.dom = null
    this.frameRef = React.createRef()
    this.state = { scrollbarWidth: 0 }
  }

  componentDidMount () {
    DOMEvents.events.on('event', this.onControlEvent)
    this.onFrames(this.props.display.frames)
    this.props.display.frames.on('frame', this.onFrames)
  }

  componentDidUpdate () {
    this.notifySize()

    if (!this.frameRef.current) return

    const elem = this.frameRef.current
    const iframe = this.frameRef.current?.firstChild

    if (iframe) {
      const iframeWindow = iframe.contentWindow
      const iframeDocument = iframe.contentDocument

      if (!iframeDocument?.documentElement || !iframeDocument?.body) return

      // Get the computed styles for the html and body elements
      const htmlStyles = iframeWindow.getComputedStyle(iframeDocument.documentElement)
      const bodyStyles = iframeWindow.getComputedStyle(iframeDocument.body)

      // Get the overflow properties for the html and body elements
      const htmlOverflow = htmlStyles.overflow
      const bodyOverflow = bodyStyles.overflow
      const htmlOverflowY = htmlStyles.overflowY
      const bodyOverflowY = bodyStyles.overflowY

      // Check if the overflow properties are set to hidden on the body or html elements
      const hasHiddenOverflow = htmlOverflow === 'hidden' || bodyOverflow === 'hidden' || htmlOverflowY === 'hidden' || bodyOverflowY === 'hidden'

      // if the iframe height is bigger than the wrapper height then we know there are scrollbars
      const hasContentOverflow = elem.scrollHeight < iframeDocument.documentElement.scrollHeight

      const hasScrollBar = hasContentOverflow && !hasHiddenOverflow
      const scrollbarWidth = hasScrollBar ? getScrollBarSize(iframeDocument).width : 0
      if (this.state.scrollbarWidth !== scrollbarWidth) {
        this.setState({ scrollbarWidth })
      }
    }
  }

  componentWillUnmount () {
    this.props.display.frames.off('frame', this.onFrames)
    DOMEvents.events.removeListener('event', this.onControlEvent)

    // Reset the node tracker when a session is cleared
    NodeTracker.reset()
  }

  onFrames = (frames) => {
    frames.read('application/x-cbio-vdom-patch').forEach(frame => {
      this.updateVDom(frame.data || frame.dom)
    })
  }

  onControlEvent = (e) => {
    if (e.type === 'scroll') {
      if ((Date.now() - e.target.last_remote_scroll) < 1500) return
      e.target.last_local_scroll = Date.now()
    }

    // track the last input times on any input-able elements
    if (e.type === 'input') {
      e.target.last_local_input = Date.now()
    }

    if (e.type === 'selectionchange') {
      if (e.target.__last_user_selection_ts > Date.now() - 100) return

      e.target.__last_trusted_selection_ts = Date.now()

      // if the element has a ownerDocument then set the timestamp on it as well to avoid
      // handling events on the document
      if (e.target.ownerDocument) {
        e.target.ownerDocument.__last_trusted_selection_ts = Date.now()
      }

      // we process the input text selection on the select events so we set the above timestamps because this
      // event fires faster but we only serialise it and send it to the users when the selection finishes
      // the exception is when an input text is unselected by clicking in itself. In this case the `select`
      // event isn't fired so we process the this anyway
      const activeElement = e.target.getRootNode({ composed: true })?.activeElement
      if (['INPUT', 'TEXTAREA'].includes(activeElement?.tagName) && activeElement.selectionStart !== activeElement.selectionEnd) {
        return
      }
    }

    if (e.type === 'select') {
      if (e.target.__last_user_selection_ts > Date.now() - 100) return

      // mark both the input element and owner document as touched
      e.target.__last_trusted_selection_ts = Date.now()
      e.target.ownerDocument.__last_trusted_selection_ts = Date.now()
    }

    this.props.toolHandler('control', e, {
      x: 0,
      y: 0,
      width: this.props.width,
      height: this.props.height,
      colour: this.props.selectedColor
    })
  }

  updateVDom = (dom) => {
    try {
      this.processFrame(dom)
      this.notifySize()
    } catch (e) {
      if (e instanceof CompressionError) {
        // TODO: Do something useful here?
        //       This will happen when a client sends a diff that
        //       doesn't apply cleanly.
        //       We could request a full snapshot from the client
        //       or maybe just display an error message?
        console.warn('applying diff failed', e)
      } else throw e
    }
  }

  notifySize = () => {
    if (this.dom && this.dom.document.size) {
      this.props.onSize(this.dom.document.size)
    }
  }

  processFrame = (domChanges) => {
    const { patch } = domChanges

    if (!domChanges.document_id) {
      throw new Error('document missing id')
    }

    let dom = this.dom

    // if we either don't have a document yet, or of the document id
    // we're trying to update is different to our existing document id
    // then wipe any existing data, and start tracking a new document
    const containsRoot = patch.find(n => n.id === domChanges.document_id)
    const documentChanged = (!dom) || (dom.id !== domChanges.document_id)
    if (documentChanged && containsRoot) {
      dom = new VirtualDOM(domChanges.document_id)
    } else if (documentChanged && !containsRoot) {
      console.warn('ignoring changes from unknown document id without root', domChanges.document_id)
      return
    }

    if (dom) {
      this.dom = dom.applyPatch(patch)
      this.props.onDOMChange(this.dom)
    }
  }

  renderDOM = (iframe) => {
    if (!this.dom || !this.props.width) return null
    return (
      <DOMDocument
        node={this.dom.document}
        window={iframe.contentWindow}
        proxy={this.props.proxy}
        tool={this.props.tool}
        onDOMError={this.props.onDOMError}
      />
    )
  }

  renderFrame = () => {
    return (
      <Frame
        title='remote-screen'
        className='size-full border-0'
        sandbox='allow-same-origin allow-scripts'
        capabilities={this.props.capabilities}
        tool={this.props.tool}
        isRemoteScreen
        doctype='<!DOCTYPE html>'
        {...getTestId('domscreen-iframe')}
        selectedColor={this.props.selectedColor}
      >
        {this.renderDOM}
      </Frame>
    )
  }

  render () {
    return (
      <div
        className='absolute left-0 top-0 h-full origin-top-left overflow-hidden'
        style={{
          width: this.props.width + this.state.scrollbarWidth,
          height: this.props.height,
          transform: `scale(${this.props.scale})`
        }}
        ref={this.frameRef}
      >
        {this.renderFrame()}
      </div>
    )
  }
}

// register special cases for some tags
DOMNode.registerTag('IFRAME', require('./DOMIFrame').default)
DOMNode.registerTag('HEAD', require('./DOMHead').default)
DOMNode.registerTag('META', require('./DOMMeta').default)
DOMNode.registerTag('STYLE', require('./DOMStyle').default)
DOMNode.registerTag('INPUT', require('./DOMInput').default)
DOMNode.registerTag('SELECT', require('./DOMSelect').default)
DOMNode.registerTag('OPTION', require('./DOMOption').default)
DOMNode.registerTag('TEXTAREA', require('./DOMInput').default) // note: re-uses DOMInput class!
DOMNode.registerTag('CANVAS', require('./DOMCanvas').default)
DOMNode.registerTag('LABEL', require('./DOMLabel').default)

// some tags are always disallowed so we don't render anything
DOMNode.registerTag('SCRIPT', require('./DOMEmpty').default)

// Remote resources are fetched with a priority order. E.g., stylesheets always
// download first, then uncached images
DOMNode.registerTag('LINK', withPriority(require('./DOMLink').default, { calculateRank, loadAttr: 'href' }))
DOMNode.registerTag('IMG', withPriority(require('./DOMImg').default, { calculateRank, loadAttr: 'src' }))

function calculateRank (props, el) {
  const node = props.node ?? {}
  const attributes = node.attributes ?? {}

  switch (el.tagName.toLowerCase()) {
    case 'link':
      // Only schedule link tags that are stylesheets
      return attributes.rel?.toLowerCase() === 'stylesheet' ? 1 : null
    case 'img': {
      const document = el.ownerDocument
      const window = document?.defaultView

      // Not on the DOM yet? Let's not schedule this weird case because it would
      // clog the scheduler if it never "onload"s
      if (!window) return null

      // Don't schedule images that don't consume network resources
      const src = attributes.src
      if (!src || src.startsWith('data:')) return null

      // ignore images with specific loading attributes
      if (attributes.loading) return null

      const style = window.getComputedStyle(el)
      if (style.display === 'none' || style.visibility === 'hidden') {
        // Images that are not visible to the user should be forced to download
        // later than any other network resource
        return Number.MAX_SAFE_INTEGER
      }

      // If the element is further down the page from the current viewport, it
      // comes after all other images
      const { top } = el.getBoundingClientRect()
      if (top > window.innerHeight) return 101

      // This image is visible and in (or above) the viewport, so we load it
      // with highest image priority (arbitrarily 100)
      return 100
    }
  }
}
