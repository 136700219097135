import React from 'react'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'

const iconColors = {
  success: 'text-green-700',
  warning: 'text-yellow-700',
  alert: 'text-red-700',
  info: 'text-slate'
}
const types = {
  success: 'bg-green-50 border-green-500',
  warning: 'bg-yellow-50 border-yellow-500',
  alert: 'bg-red-50 border-red-300 text-red-700',
  info: 'bg-violet-50'
}
const icons = {
  success: 'circle-check',
  warning: 'alert-triangle',
  alert: 'alert-triangle',
  info: 'alert-circle'
}
const variants = {
  inline: 'bg-transparent gap-x-1 p-0',
  'inline-bg': 'text-inherit',
  floating: 'p-3 shadow-risen border border-opacity-50'
}

const AlertIcon = ({ type }) => (
  <Icon type={icons[type]} size='small' className={iconColors[type]} />
)

const Alert = ({ type = 'info', variant = 'inline-bg', icon = 'info', fixed = false, className, onDismiss, hideIcon = false, children, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={mergeClassNames('flex items-center gap-x-2 rounded-md p-2 text-xs font-medium lg:gap-x-3 lg:p-3', types[type], variants[variant], fixed && 'fixed start-1/2 top-2.5 z-50 -translate-x-1/2', className)} {...props}>
      {!hideIcon && <AlertIcon type={icon} className={mergeClassNames(iconColors[type])} />}
      <div>
        {children}
      </div>
      {onDismiss && (
        <button onClick={onDismiss} className='ms-auto'>
          <Icon size='small' type='close' className='text-slate' />
          <span className='sr-only'>{t('Dismiss')}</span>
        </button>
      )}
    </div>
  )
}

export default Alert
