import Debug from 'debug'
import qs from 'qs'
import _ from 'lodash'
import RESTObject from '../redux-rest'
import ServerTime from '../../utils/ServerTime'
import User from '../users/User.state'
import { connect, emit, disconnect, makeUrl, makeProtocols } from '../websockets/Sockets.state'
import { updateFrame, resetFrames } from './Displays.state'
import { updateScreen } from '../screens/Screens.state'
import { updateMouse } from './Mouse.state'
import { updateConnectionMetrics } from './Connectivity.state'
import { updateUiState } from '../ui-state/UI.state'
import { addDOMError } from '../errors/Error.state'
import { ClientTracker } from '../../utils/ClientTracker'
import { generateUUID } from '../../utils/generateUUID'

const debug = Debug('cbio.sessions.SessionState')

export default class Session extends RESTObject {
  static namespace () {
    return 'Session'
  }

  static url (params = {}, context = {}) {
    return `/api/1/sessions/${params.id || params.code || ''}?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }

  static sessionForCode (code) {
    return dispatch => {
      return dispatch(this.get({ id: code }))
        .then(session => {
          if (session.type === 'Session') {
            return session
          } else return false
        })
    }
  }

  static async findClosestRegion (options) {
    try {
      const res = await window.fetch('/api/1/regions/closest', options)
      if (res.ok) return await res.json()
    } catch (e) {
      console.error(e)
    }

    return null
  }

  static registerAgentJoin (session) {
    return async dispatch => {
      if (session && !session.agent) {
        const region = await this.findClosestRegion()
        dispatch(this.update({ id: Session.idFor(session), agent: 'me', region: region?.id }))
      }
    }
  }

  static endSession (session) {
    return this.update({ id: Session.idFor(session), state: 'ended' })
  }

  static remainingTime (session) {
    if (!session) return null
    if (!session.expires) return null
    const { expires } = session
    return Math.max(0, (new Date(expires)).getTime() - ServerTime.now())
  }

  static isFullDevice (session) {
    return session?.full_device === true || session?.full_device === 'on' || session?.full_device === 'requested' || session?.full_device === 'rejected'
  }

  static socketParams (type, state) {
    const url = _.get(state, `resource.${type}_url`)
    const token = _.get(state, `resource.${type}_token`)
    return { url, token }
  }

  static socketUrl (type, state) {
    const { url } = this.socketParams(type, state)
    if (!url) return null
    return makeUrl(url)
  }

  static socketProtocols (type, state) {
    const { token } = this.socketParams(type, state)
    if (!token) return null
    return makeProtocols(token)
  }

  static isStreamAvailable (session) {
    return !!session?.stream_token
  }

  static streamSocket () {
    return {
      connect: () => (dispatch, getState) => {
        dispatch(connect('stream', {
          getUrl: async () => Session.socketUrl('stream', Session.fromState(getState())),
          getProtocols: async () => Session.socketProtocols('stream', Session.fromState(getState())),
          configureSocket: socket => {
            socket.on('frame', function (resource) {
              if (debug.enabled) {
                debug('receive', 'frame', JSON.stringify(resource).length, resource)
              }
              dispatch(updateFrame(resource, socket))
            })
            socket.on('screen', function (resource) {
              debug('receive', 'screen', resource)
              dispatch(updateScreen(resource))
            })
            socket.on('mouse', function (resource) {
              debug('receive', 'mouse', resource)
              dispatch(updateMouse(resource))
            })

            socket.clients = new ClientTracker(generateUUID())
            socket.clients.on('join', c => console.log('joined', c))
            socket.clients.on('leave', c => console.log('left', c))
            socket.clients.on('ping', (id, timeout) => socket.send('client', { id, timeout }))
            socket.on('client', ({ id, timeout }) => socket.clients.track(id, timeout))

            // socket probing
            // we send a probe message every few seconds that
            // another device in the same session (i.e. the
            // customers mobile device) must respond to with
            // and alive message.
            socket.on('open', function () {
              debug('receive', 'open')
              socket.send('filter', { events: ['frame', 'screen', 'mouse', 'client', 'alive'] })
              clearInterval(socket.probeInterval)
              socket.probeInterval = setInterval(() => {
                socket.send('probe', { timestamp: Date.now() })
              }, 3 * 1000)
              socket.clients.ping()
            })
            // clean up the interval timer on disconnection
            socket.on('close', () => {
              debug('receive', 'close')
              clearInterval(socket.probeInterval)
              socket.clients?.destroy()
            })
            // calculate RTT metrics on alive responses and push them
            // into redux so the UI can do useful stuff like showing
            // degraded connection messages
            socket.on('alive', message => {
              debug('receive', 'alive', message)
              dispatch(updateConnectionMetrics({
                rtt: Date.now() - message.timestamp,
                last_alive: Date.now()
              }))
            })
            // frames also update the alive timestamp
            socket.on('frame', () => {
              dispatch(updateConnectionMetrics({
                last_alive: Date.now()
              }))
            })
          }
        }))
      },
      disconnect: () => disconnect('stream'),
      emit: (type, message) => {
        debug('emit', type, message)
        return emit('stream', type, message)
      }
    }
  }

  static subscribe () {
    return (dispatch, getState) => {
      function doSubscribe (socket, options = { debounce: true }) {
        // get the latest session state from redux
        const session = Session.fromState(getState()).resource
        if (session && session.control_token) {
          // de-duplciate successive calls to subscribe() for the
          // same session by checking if the sesison id was the latest
          // one we subscribed to
          if ((!options.debounce) || socket.last_subscribed_session !== session.id) {
            socket.last_subscribed_session = session.id
            // subscribe using the latest token
            const token = session.control_token
            // we need to fetch the latest session if we've only
            // just subscribed, there might have been changes sent
            // via the socket that we missed
            const onSuccess = (returned) => {
              if (token === returned) {
                socket.off('subscribe:success', onSuccess)
                dispatch(Session.get({ id: session.id }))
              }
            }
            socket.on('subscribe:success', onSuccess)
            socket.send('subscribe', token)
            dispatch(Session.get({ id: session.id }))
          }
        }
      }

      function configureSocket (socket) {
        // make sure we only configure a socket once
        if (socket.session_events_configured) return
        socket.session_events_configured = true

        // session object updates come over the control socket
        // so we can cache the latest changes
        socket.on('session', resource => dispatch(Session.cache(resource)))
        socket.on('subscribe:error', token => console.warn('Session subscribe failed', token))
        socket.on('open', () => {
          // each time the socket is opened, we need to re-subscribe to
          // the current session and make sure session events are sent
          // through to us
          socket.send('filter', { events: ['session'] })
          doSubscribe(socket, { debounce: false })
        })
        socket.send('filter', { events: ['session'] })
      }

      const socket = dispatch(User.getSocket())
      configureSocket(socket)
      doSubscribe(socket)
    }
  }

  static sendStreamMessage (event, data) {
    return (dispatch, getState) => {
      const user = User.fromState(getState())
      data.agent = (user.resource && user.resource.id) || 'guest'
      data.timestamp = new Date().toISOString()
      dispatch(this.streamSocket().emit(event, data))
    }
  }

  static sendSync (sync = {}) {
    return (dispatch) => {
      sync.type = 'Sync'
      if (!this._syncInstance) this._syncInstance = Math.floor(Math.random() * 10000)
      sync.id = `${this._syncInstance}${sync.id ?? Math.floor(Math.random() * 10000)}`
      dispatch(this.streamSocket().emit('sync', sync))
    }
  }

  static sendControlEvent (e, display) {
    return this.sendStreamMessage(e.type.toLowerCase(), { ...e, id: '0', display })
  }

  static sendLaserEvent (e, display) {
    // legacy support for phase param, replaced by state
    e.phase = e.state.replace('touch', '')
    return this.sendStreamMessage('laser', { ...e, type: 'Laser', id: '0', display })
  }

  static sendImageDrawEvent (e, display) {
    return (dispatch) => {
      dispatch(updateUiState({ drawing: e }))
      dispatch(this.sendStreamMessage('drawing', { ...e, type: 'Drawing', display }))
    }
  }

  static onDOMError (nodeId, error, display) {
    return (dispatch) => {
      dispatch(addDOMError({
        timestamp: new Date(),
        message: error.message,
        cause: error.cause
      }))

      const remoteError = {
        type: 'RemoteError',
        target: nodeId,
        error: {
          message: error.message,
          cause: error.cause
        },
        display
      }
      dispatch(this.sendStreamMessage('remote-error', remoteError))
    }
  }

  static sanitize (session) {
    return {
      id: session.id,
      type: session.type,
      state: session.state,
      created: session.created,
      activated: session.activated,
      updated: session.updated,
      ended: session.ended,
      full_device: session.full_device,
      remote_control: session.remote_control
    }
  }

  static resetSessionContent () {
    return dispatch => {
      this._syncInstance = null
      dispatch(updateConnectionMetrics(false))
      dispatch(resetFrames())
      dispatch(updateScreen(false))
      dispatch(updateMouse(false))
    }
  }

  static resetAll () {
    return dispatch => {
      dispatch(this.reset())
      dispatch(this.resetSessionContent())
    }
  }

  static actionCreators () {
    return {
      ...super.actionCreators(),
      endSession: this.endSession.bind(this),
      resetSession: this.resetAll.bind(this),
      resetSessionContent: this.resetSessionContent.bind(this),
      registerAgentJoin: this.registerAgentJoin.bind(this),
      sessionForCode: this.sessionForCode.bind(this),
      subscribeSession: this.subscribe.bind(this),
      sendStreamMessage: this.sendStreamMessage.bind(this),
      sendSync: this.sendSync.bind(this),
      sendControlEvent: this.sendControlEvent.bind(this),
      sendLaserEvent: this.sendLaserEvent.bind(this),
      sendImageDrawEvent: this.sendImageDrawEvent.bind(this),
      openStreamSocket: this.streamSocket().connect.bind(this),
      closeStreamSocket: this.streamSocket().disconnect.bind(this),
      onDOMError: this.onDOMError.bind(this)
    }
  }
}
