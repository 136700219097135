import React from 'react'
import { useTranslation } from 'react-i18next'
import LoadingScreen from '../../components/LoadingScreen'
import mergeClassNames from '../../utils/mergeClassNames'

const PendingSession = ({ session, endSession }) => {
  const { t } = useTranslation()

  const handleEndSession = () => {
    endSession && endSession(session)
  }

  return (
    <div className={mergeClassNames('flex size-full items-center justify-center bg-slate-900 text-lg text-slate-50')}>
      <LoadingScreen
        message={session.state === 'authorizing' ? t('Waiting for authorization') : t('Waiting for device')}
        cancelText={t('Cancel')}
        onCancel={handleEndSession}
      />
    </div>
  )
}

export default PendingSession
