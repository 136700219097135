import React from 'react'
import parser from 'ua-parser-js'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'

const browsers = [
  'chrome',
  'edge',
  'firefox',
  'opera',
  'safari',
  'samsung-internet'
]

const BrowserIcon = ({ userAgent, className }) => {
  const ua = parser(userAgent)
  const browserName = ua?.browser?.name
  const browserKey = browserName?.toLowerCase()
    .replace(/ /g, '-')
    .replace('mobile-', '') // Use the same icon for mobile and desktop Safari
  const iconType = browsers.includes(browserKey) ? 'browser-' + browserKey : 'globe'

  return (
    <Icon
      type={iconType}
      className={mergeClassNames(iconType !== 'globe' && 'text-transparent', className)}
    />
  )
}

export default BrowserIcon
