import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'
import TextArea from '../../components/TextArea'
import { Icon } from '../../components/Icon'

function FeedbackInput ({ label, onReasonSelect, value }) {
  return (
    <label className='group flex cursor-pointer items-center gap-x-2 rounded-lg transition-colors'>
      <input value={value} type='checkbox' className='size-5 shrink-0 cursor-pointer appearance-none rounded-md border border-slate-400 bg-cover bg-center bg-no-repeat text-white transition-colors checked:border-none checked:bg-checked checked:bg-cover checked:bg-no-repeat hover:border-slate-50 checked:hover:border-none' onChange={onReasonSelect} />
      {label}
    </label>
  )
}

const SessionRating = ({ ratingComplete }) => {
  const [rating, setRating] = useState(null)
  const [feedback, setFeedback] = useState([])
  const [issues, setIssues] = useState('')
  const [complete, setComplete] = useState(false)
  const { t } = useTranslation()

  const onRatingComplete = useCallback(() => {
    setComplete(true)
    ratingComplete({ rating, feedback: [...feedback, issues].filter(v => v) })
  }, [rating, feedback, issues, ratingComplete])

  useEffect(() => {
    if (rating > 3) {
      onRatingComplete()
    }
  }, [rating, onRatingComplete])

  function renderRating () {
    return (
      <div className='flex flex-row-reverse justify-center'>
        {[5, 4, 3, 2, 1].map((score) => (
          <button
            key={score}
            className={mergeClassNames('peer cursor-pointer px-1.5 text-[40px] text-white transition-opacity duration-300 peer-hover:text-violet hover:text-violet [&_.rating-star-outline]:fill-white [&_.rating-star-outline]:peer-hover:fill-violet [&_.rating-star-outline]:hover:fill-violet [&_path]:fill-none [&_path]:peer-hover:fill-violet [&_path]:hover:fill-violet', rating >= score && 'text-violet [&_.rating-star-outline]:fill-violet [&_path]:fill-violet')}
            onClick={() => setRating(score)}
          >
            <Icon type='star' size='large' />
            <span className='sr-only'>{t('Rate with a score of {{score}}', { score })}</span>
          </button>
        ))}
      </div>
    )
  }

  function onReasonSelect (e) {
    const value = e.target.value
    if (e.target.checked) setFeedback([...feedback, value])
    else setFeedback(feedback.filter(v => v !== value))
  }

  const hasFeedbackVisible = rating != null && rating <= 3

  function renderFeedback () {
    if (!hasFeedbackVisible) return null

    return (
      <div className='grid w-[400px] gap-y-6'>
        <div className='flex flex-col gap-y-3'>
          <FeedbackInput
            label={t('It was too hard to start the session with the user')}
            onReasonSelect={onReasonSelect}
            value='hard_to_start_session'
          />
          <FeedbackInput
            label={t('The screen was too slow to load or update')}
            onReasonSelect={onReasonSelect}
            value='screen_too_slow'
          />
          <FeedbackInput
            label={t('Parts of the screen did not load correctly')}
            onReasonSelect={onReasonSelect}
            value='screen_broken'
          />
          <FeedbackInput
            label={t('The tools were not helpful or too hard to use')}
            onReasonSelect={onReasonSelect}
            value='tools_not_helpful'
          />
        </div>
        <TextArea placeholder={t('Please describe any issues you experienced')} onChange={(e) => setIssues(e.target.value)} className='h-[72px] bg-white/15 text-sm text-slate-50/75 [&::placeholder]:w-full [&::placeholder]:text-slate-50/75' />
      </div>
    )
  }

  function renderComplete () {
    if (!complete) return null
    else return <div>{t('Thank you for your feedback.')}</div>
  }

  if (complete) return renderComplete()

  return (
    <>
      <div className={mergeClassNames('flex flex-col gap-y-4 px-4', !rating && 'absolute bottom-6')}>
        <p className='text-center text-xs uppercase text-gray-500'>{t('Rate your experience')}</p>
        {renderRating()}
      </div>
      <div className={mergeClassNames('flex flex-col gap-y-4 px-4', hasFeedbackVisible && 'gap-y-6')}>
        {renderFeedback()}
        <Button variant='primary' className='self-center' onClick={onRatingComplete}>{t('Continue')}</Button>
      </div>
    </>
  )
}

export default SessionRating
