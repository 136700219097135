import React from 'react'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../utils/mergeClassNames'
import ButtonComponent from './Button'
import { Icon } from './Icon'

const SettingsGroupRoot = ({ children, singleColumn = false, className, ...props }) => (
  <div className={mergeClassNames('group grid bg-white py-6 md:gap-x-14 md:gap-y-3 md:px-2', singleColumn ? 'grid-cols-1' : 'grid-cols-[1fr_auto] md:grid-cols-[3fr_2fr]', className)} {...props}>
    {children}
  </div>
)

const Title = ({ children, className, icon, ...props }) => (
  <div className={mergeClassNames('flex items-center gap-x-2')}>
    {icon && <Icon type={icon} size='small' className='w-auto text-gray-700' />}
    <h2 className={mergeClassNames('text-sm text-slate md:text-lg', className)} {...props}>{children}</h2>
  </div>
)

const HelpText = ({ children, className, placement = 'top', ...props }) => (
  <p className={mergeClassNames('col-span-2 row-start-2 mt-1 text-xs text-gray-700 group-has-[.sg-button]:col-span-1 md:sticky md:top-32 md:col-span-1 md:self-start md:text-sm md:group-has-[.sg-button]:mt-4', placement === 'bottom' && 'md:group-has-[.sg-button]:mt-auto', className)} {...props}>
    {children}
  </p>
)

const Button = ({ children, className, ...props }) => (
  <div className={
    mergeClassNames(
      'relative row-span-2 ms-2 flex justify-end md:sticky md:top-16 md:col-start-2 md:row-span-1 md:row-start-1 md:ms-0 md:justify-start md:self-start md:bg-inherit',
      'sg-button' // We use this class to check for the button existence in the HelpText component. It does not apply any styles.
    )
  }
  >
    <div className='absolute inset-x-0 -top-4 -z-10 hidden h-4 bg-inherit md:block' />
    <ButtonComponent className={mergeClassNames('md:h-9', className)} {...props}>{children}</ButtonComponent>
    <div className='absolute inset-x-0 -bottom-4 -z-10 hidden h-4 bg-gradient-to-b from-white to-transparent md:block' />
  </div>
)

const SaveButton = ({ className, ...props }) => {
  const { t } = useTranslation()

  return (
    <Button className={className} {...props}>
      {t('Save')}
    </Button>
  )
}

const Body = ({ children, className, ...props }) => (
  <div className={mergeClassNames('col-span-2 mt-4 md:col-span-1 md:col-start-1 md:row-start-2 md:mt-0', className)} {...props}>
    {children}
  </div>
)

const Footer = ({ children, className, ...props }) => (
  <div className={mergeClassNames('bg-black-100', className)} {...props}>
    {children}
  </div>
)

const Header = ({ children, className, ...props }) => (
  <div className={mergeClassNames('col-span-2 flex flex-col gap-y-2', className)} {...props}>
    {children}
  </div>
)

export const SettingsGroup = Object.assign(SettingsGroupRoot, { Header, HelpText, Body, Footer, Title, Button, SaveButton })
