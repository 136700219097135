import React from 'react'
import { useTranslation } from 'react-i18next'

const NewDeviceNotification = ({ hasNewDevices }) => {
  const { t } = useTranslation()

  if (!hasNewDevices) return null

  return (
    <div
      className='absolute end-0.5 top-0.5 block size-3.5 rounded-full border-2 border-white bg-black'
      title={t('New devices found.')}
    />
  )
}

export default NewDeviceNotification
