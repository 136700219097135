import { ReactComponent as AlertCircle } from './alert-circle.svg'
import { ReactComponent as AlertTriangle } from './alert-triangle.svg'
import { ReactComponent as Android } from './android.svg'
import { ReactComponent as App } from './app.svg'
import { ReactComponent as Apple } from './apple.svg'
import { ReactComponent as ArrowLeft } from './arrow-left.svg'
import { ReactComponent as ArrowNarrowUp } from './arrow-narrow-up.svg'
import { ReactComponent as Asl } from './asl.svg'
import { ReactComponent as Attach } from './attach.svg'
import { ReactComponent as Attention } from './attention.svg'
import { ReactComponent as AuditTrail } from './audit-trail.svg'
import { ReactComponent as BellAlt } from './bell-alt.svg'
import { ReactComponent as Bell } from './bell.svg'
import { ReactComponent as Billing } from './billing.svg'
import { ReactComponent as Blank } from './blank.svg'
import { ReactComponent as BookOpen } from './book-open.svg'
import { ReactComponent as Browser } from './browser.svg'
import { ReactComponent as BrowserChrome } from './browser-chrome.svg'
import { ReactComponent as BrowserEdge } from './browser-edge.svg'
import { ReactComponent as BrowserFirefox } from './browser-firefox.svg'
import { ReactComponent as BrowserOpera } from './browser-opera.svg'
import { ReactComponent as BrowserSafari } from './browser-safari.svg'
import { ReactComponent as BrowserSamsungInternet } from './browser-samsung-internet.svg'
import { ReactComponent as Bubble } from './bubble.svg'
import { ReactComponent as Bug } from './bug.svg'
import { ReactComponent as CalendarEvent } from './calendar-event.svg'
import { ReactComponent as Camera } from './camera.svg'
import { ReactComponent as Cancel } from './cancel.svg'
import { ReactComponent as Ccw } from './ccw.svg'
import { ReactComponent as ChartLine } from './chart-line.svg'
import { ReactComponent as ChevronUp } from './chevron-up.svg'
import { ReactComponent as Circle } from './circle.svg'
import { ReactComponent as CircleCheck } from './circle-check.svg'
import { ReactComponent as CircleCheckOutline } from './circle-check-outline.svg'
import { ReactComponent as CircleMinus } from './circle-minus.svg'
import { ReactComponent as ClockCircled } from './clock-circled.svg'
import { ReactComponent as ClockHour3 } from './clock-hour-3.svg'
import { ReactComponent as Clock } from './clock.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Cloud } from './cloud.svg'
import { ReactComponent as Cog1 } from './cog-1.svg'
import { ReactComponent as CogAlt } from './cog-alt.svg'
import { ReactComponent as Cog } from './cog.svg'
import { ReactComponent as Comment1 } from './comment-1.svg'
import { ReactComponent as Comment } from './comment.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as CreditCard } from './credit-card.svg'
import { ReactComponent as Cube } from './cube.svg'
import { ReactComponent as Cw } from './cw.svg'
import { ReactComponent as Database } from './database.svg'
import { ReactComponent as Desktop } from './desktop.svg'
import { ReactComponent as DeviceMobile } from './device-mobile.svg'
import { ReactComponent as DeviceMobileSearch } from './device-mobile-search.svg'
import { ReactComponent as Devices } from './devices.svg'
import { ReactComponent as DevicesCog } from './devices-cog.svg'
import { ReactComponent as Docs } from './docs.svg'
import { ReactComponent as Dot3 } from './dot-3.svg'
import { ReactComponent as DownCircled } from './down-circled.svg'
import { ReactComponent as DownOpen } from './down-open.svg'
import { ReactComponent as Download } from './download.svg'
import { ReactComponent as DownloadCloud } from './download-cloud.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as EllipsisH } from './ellipsis-h.svg'
import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Flutter } from './flutter.svg'
import { ReactComponent as Font } from './font.svg'
import { ReactComponent as Gauge } from './gauge.svg'
import { ReactComponent as GeneralSettings } from './general-settings.svg'
import { ReactComponent as Github } from './github.svg'
import { ReactComponent as Globe } from './globe.svg'
import { ReactComponent as GlobeSimple } from './globe-simple.svg'
import { ReactComponent as Google } from './google.svg'
import { ReactComponent as HandPointer } from './hand-pointer.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as History } from './history.svg'
import { ReactComponent as Inbox } from './inbox.svg'
import { ReactComponent as InfoCircle } from './info-circle.svg'
import { ReactComponent as Integrations } from './integrations.svg'
import { ReactComponent as Intercom } from './intercom.svg'
import { ReactComponent as Key } from './key.svg'
import { ReactComponent as Laserpointer } from './laserpointer.svg'
import { ReactComponent as LeftCircled } from './left-circled.svg'
import { ReactComponent as LeftOpenBig } from './left-open-big.svg'
import { ReactComponent as LinkExt } from './link-ext.svg'
import { ReactComponent as ListAlt } from './list-alt.svg'
import { ReactComponent as Locale } from './locale.svg'
import { ReactComponent as Lock1 } from './lock-1.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Logout } from './logout.svg'
import { ReactComponent as Macos } from './macos.svg'
import { ReactComponent as Mail } from './mail.svg'
import { ReactComponent as MailForward } from './mail-forward.svg'
import { ReactComponent as Megaphone } from './megaphone.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as MicrosoftDotnet } from './microsoft-dotnet.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as MobileFill } from './mobile-fill.svg'
import { ReactComponent as Mobile } from './mobile.svg'
import { ReactComponent as Money } from './money.svg'
import { ReactComponent as MousePointer } from './mouse-pointer.svg'
import { ReactComponent as Nice } from './nice.svg'
import { ReactComponent as Numbers } from './numbers.svg'
import { ReactComponent as Ok } from './ok.svg'
import { ReactComponent as Params } from './params.svg'
import { ReactComponent as Pencil1 } from './pencil-1.svg'
import { ReactComponent as Phone } from './phone.svg'
import { ReactComponent as Picture } from './picture.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as PlayerPlay } from './player-play.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as PushNotifications } from './push-notifications.svg'
import { ReactComponent as React } from './react.svg'
import { ReactComponent as Redaction } from './redaction.svg'
import { ReactComponent as Refresh } from './refresh.svg'
import { ReactComponent as ResizeFull } from './resize-full.svg'
import { ReactComponent as RightCircled } from './right-circled.svg'
import { ReactComponent as RightOpenBig } from './right-open-big.svg'
import { ReactComponent as Salesforce } from './salesforce.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Shield } from './shield.svg'
import { ReactComponent as Slideshare } from './slideshare.svg'
import { ReactComponent as SortNumeric } from './sort-numeric.svg'
import { ReactComponent as Spin2 } from './spin2.svg'
import { ReactComponent as Stop } from './stop.svg'
import { ReactComponent as Stopwatch } from './stopwatch.svg'
import { ReactComponent as Tag } from './tag.svg'
import { ReactComponent as Talkdesk } from './talkdesk.svg'
import { ReactComponent as Team } from './team.svg'
import { ReactComponent as Tools } from './tools.svg'
import { ReactComponent as TrashEmpty } from './trash-empty.svg'
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as UpCircled } from './up-circled.svg'
import { ReactComponent as UserId } from './user-id.svg'
import { ReactComponent as UserO } from './user-o.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Users } from './users.svg'
import { ReactComponent as VirtualColumnKey } from './virtual-column-key.svg'
import { ReactComponent as Wand } from './wand.svg'
import { ReactComponent as Windows } from './windows.svg'
import { ReactComponent as Zendesk } from './zendesk.svg'
import { ReactComponent as ZoomIn } from './zoom-in.svg'
import { ReactComponent as ZoomOut } from './zoom-out.svg'
import { ReactComponent as DisappearingInk } from './disappearing-ink.svg'
import { ReactComponent as InkPen } from './ink-pen.svg'
import { ReactComponent as FullDevice } from './full-device.svg'
import { ReactComponent as Capabilities } from './capabilities.svg'
import { ReactComponent as OsVersion } from './os-version.svg'
import { ReactComponent as Timezone } from './timezone.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as Checked } from './checked.svg'
import { ReactComponent as TooltipArrow } from './tooltip-arrow.svg'
import { ReactComponent as X } from './x.svg'
import { ReactComponent as Upgrade } from './upgrade.svg'

export {
  AlertCircle,
  AlertTriangle,
  Android,
  App,
  Apple,
  ArrowLeft,
  ArrowNarrowUp,
  Asl,
  Attach,
  Attention,
  AuditTrail,
  BellAlt,
  Bell,
  Billing,
  Blank,
  BookOpen,
  Browser,
  BrowserChrome,
  BrowserEdge,
  BrowserFirefox,
  BrowserOpera,
  BrowserSafari,
  BrowserSamsungInternet,
  Bubble,
  Bug,
  CalendarEvent,
  Camera,
  Cancel,
  Ccw,
  ChartLine,
  ChevronUp,
  Circle,
  CircleCheck,
  CircleCheckOutline,
  CircleMinus,
  ClockCircled,
  ClockHour3,
  Clock,
  Close,
  Cloud,
  Cog1,
  CogAlt,
  Cog,
  Comment1,
  Comment,
  Copy,
  CreditCard,
  Cube,
  Cw,
  Database,
  Desktop,
  DeviceMobile,
  DeviceMobileSearch,
  Devices,
  DevicesCog,
  Docs,
  Dot3,
  DownCircled,
  DownOpen,
  Download,
  DownloadCloud,
  Edit,
  EllipsisH,
  Facebook,
  Flutter,
  Font,
  Gauge,
  GeneralSettings,
  Github,
  Globe,
  GlobeSimple,
  Google,
  HandPointer,
  Heart,
  History,
  Inbox,
  InfoCircle,
  Integrations,
  Intercom,
  Key,
  Laserpointer,
  LeftCircled,
  LeftOpenBig,
  LinkExt,
  ListAlt,
  Locale,
  Lock1,
  Lock,
  Logout,
  Macos,
  Mail,
  MailForward,
  Megaphone,
  Menu,
  MicrosoftDotnet,
  Minus,
  MobileFill,
  Mobile,
  Money,
  MousePointer,
  Nice,
  Numbers,
  Ok,
  Params,
  Pencil1,
  Phone,
  Picture,
  Play,
  PlayerPlay,
  Plus,
  PushNotifications,
  React,
  Redaction,
  Refresh,
  ResizeFull,
  RightCircled,
  RightOpenBig,
  Salesforce,
  Search,
  Shield,
  Slideshare,
  SortNumeric,
  Spin2,
  Stop,
  Stopwatch,
  Talkdesk,
  Tag,
  Team,
  Tools,
  TrashEmpty,
  Twitter,
  UpCircled,
  UserId,
  UserO,
  User,
  Users,
  VirtualColumnKey,
  Wand,
  Windows,
  Zendesk,
  ZoomIn,
  ZoomOut,
  DisappearingInk,
  InkPen,
  FullDevice,
  Capabilities,
  OsVersion,
  Timezone,
  Star,
  Checked,
  TooltipArrow,
  X,
  Upgrade
}
