import React from 'react'
import RemoteScreen from '../screens/RemoteScreen'
import mergeClassNames from '../../utils/mergeClassNames'

export function DisplayPicker ({ displays, activeDisplayKey, changeDisplay }) {
  const displayKeys = Object.keys(displays)
  if (displayKeys.length <= 1) return null

  return (
    <div className='my-2 flex justify-center gap-x-2 transition-all delay-200 duration-150 ease-in-out'>
      {displayKeys.map((display) => {
        const selected = display === activeDisplayKey

        return (
          <div className={mergeClassNames('max-h-[60px] border-[3px]', selected ? 'border-red-500' : 'border-black')} key={display} onClick={() => changeDisplay(display)}>
            <RemoteScreen height={60} scale={1} display={displays[display]} />
          </div>
        )
      })}
    </div>
  )
}
