import React, { useEffect, useRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'

export function ScrollableContainerShadows ({ scrollableContainerRef, className, rightClassName, leftClassName }) {
  const leftShadow = useRef(null)
  const rightShadow = useRef(null)

  useEffect(() => {
    if (!scrollableContainerRef.current) return null

    const container = scrollableContainerRef.current

    const handleScroll = () => {
      const maxScrollLeft = container.scrollWidth - container.clientWidth
      const currentScrollLeft = container.scrollLeft

      if (currentScrollLeft === 0) {
        leftShadow.current.style.opacity = 0
      } else {
        leftShadow.current.style.opacity = 100
      }

      if (maxScrollLeft > currentScrollLeft) {
        rightShadow.current.style.opacity = 100
      } else {
        rightShadow.current.style.opacity = 0
      }
    }

    container.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    handleScroll()

    return () => {
      container.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [scrollableContainerRef])

  return (
    <>
      <div className={mergeClassNames('pointer-events-none absolute left-0 w-20 bg-gradient-to-r from-white to-transparent opacity-0 transition-opacity duration-75 motion-reduce:transition-none', className, leftClassName)} ref={leftShadow} />
      <div className={mergeClassNames('pointer-events-none absolute right-0 w-20 bg-gradient-to-l from-white to-transparent opacity-100 transition-opacity duration-75 motion-reduce:transition-none', className, rightClassName)} ref={rightShadow} />
    </>
  )
}
