import React, { Component } from 'react'
import DOMNode from './DOMNode'

export default class DOMMeta extends Component {
  static nodeFilter (node) {
    return node
  }

  render () {
    if (this.props.node.attributes?.['http-equiv'] === 'Content-Security-Policy') return null
    return <DOMNode {...this.props} />
  }
}
