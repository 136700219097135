import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Account from '../accounts/Account.state'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import TeamStep from './TeamStep'
import DevelopStep from './DevelopStep'
import DoneStep from './DoneStep'
import Controls from './Controls'
import { ORDER } from './constants'

const WelcomeTutorial = ({ stage }) => {
  const { t } = useTranslation()
  const account = useActiveAccountSelector()
  const navigate = useNavigate()
  const currentStage = stage || ORDER[0]

  useDocumentTitle(t('Welcome tutorial'))

  useEffect(() => {
    if (!Account.hasFeature(account.resource, 'manage_members', false)) {
      return navigate('/dashboard', { replace: true })
    }
  }, [account.resource, navigate])

  const renderStageContent = () => {
    switch (currentStage) {
      case 'team':
        return <TeamStep />
      case 'develop':
        return <DevelopStep licenseKey={account.resource.license_key} />
      case 'done':
        return <DoneStep />
      default:
        return null
    }
  }

  // currently there's no onboarding flow for support agents at all
  useEffect(() => {
    if (!Account.hasFeature(account.resource, 'manage_members', false)) {
      return navigate('/dashboard')
    }
  }, [account.resource, navigate])

  return (
    <div className='flex grow items-center justify-center bg-gray-50'>
      <h1 className='sr-only'>{t('Welcome tutorial')}</h1>
      <div className='flex w-full max-w-lg flex-col gap-y-4 p-6 md:p-0'>
        {renderStageContent()}
        <Controls currentStage={currentStage} />
      </div>
    </div>
  )
}

export default WelcomeTutorial

export function WelcomeTutorialStage () {
  const { stage } = useParams()

  return <WelcomeTutorial {...{ stage }} />
}
