import React from 'react'
import i18n from '../../i18n'
import { Icon } from '../../components/Icon'

const icon = (type) => (<Icon size='extra-small' className='self-center text-violet-500' type={type} />)

export default class StandardFields {
  static deviceData = () => {
    return {
      app_id: {
        field: 'app_id',
        label: i18n.t('App ID'),
        description: i18n.t('Application ID'),
        icon: icon('tag'),
        iconType: 'tag',
        capitalize: false
      },
      app_name: {
        field: 'app_name',
        label: i18n.t('App'),
        description: i18n.t('Application name'),
        icon: icon('app'),
        iconType: 'app'
      },
      app_version: {
        field: 'app_version',
        label: i18n.t('App version'),
        description: i18n.t('Application version'),
        icon: icon('tag'),
        iconType: 'tag'
      },
      device: {
        field: 'device',
        label: i18n.t('Device'),
        description: i18n.t('Device'),
        icon: icon('browser'),
        iconType: 'browser'
      },
      device_timezone: {
        field: 'device_timezone',
        label: i18n.t('Device timezone'),
        description: i18n.t('Device timezone'),
        icon: icon('timezone'),
        iconType: 'timezone'
      },
      sdk_version: {
        field: 'sdk_version',
        label: i18n.t('Cobrowse SDK'),
        description: i18n.t('Cobrowse SDK version'),
        icon: icon('tools'),
        iconType: 'tools'
      },
      platform: {
        field: 'platform',
        label: i18n.t('Platform'),
        description: i18n.t('Platform'),
        icon: icon('shield'),
        iconType: 'shield'
      },
      device_locale: {
        field: 'device_locale',
        label: i18n.t('Locale'),
        description: i18n.t('Locale'),
        icon: icon('locale'),
        iconType: 'locale'
      },
      os_version: {
        field: 'os_version',
        label: i18n.t('OS version'),
        description: i18n.t('OS version'),
        icon: icon('os-version'),
        iconType: 'os-version'
      },
      os_api_level: {
        field: 'os_api_level',
        label: i18n.t('API'),
        description: i18n.t('API level')
      },
      app_build_configuration: {
        field: 'app_build_configuration',
        label: i18n.t('Build type'),
        description: i18n.t('Build type'),
        icon: icon('bug'),
        iconType: 'bug'
      },
      app_build: {
        field: 'app_build',
        label: i18n.t('Build number'),
        description: i18n.t('Build number'),
        icon: icon('tools'),
        iconType: 'tools'
      },
      push_enabled: {
        field: 'push_enabled',
        label: i18n.t('Push'),
        description: i18n.t('Allows push notifications')
      },
      full_device_control: {
        field: 'full_device_control',
        label: i18n.t('Full device control'),
        description: i18n.t('Full device control configured'),
        icon: icon('hand-pointer'),
        iconType: 'hand-pointer'
      },
      capabilities: {
        field: 'capabilities',
        label: i18n.t('Capabilities'),
        description: i18n.t('Capabilities available in the session'),
        icon: icon('capabilities'),
        iconType: 'capabilities'
      }
    }
  }

  static customData = () => {
    return {
      user_email: {
        field: 'user_email',
        label: i18n.t('Email'),
        description: i18n.t('Filter by user email address'),
        icon: icon('mail'),
        iconType: 'mail',
        capitalize: false
      },
      user_id: {
        field: 'user_id',
        label: i18n.t('User ID'),
        description: i18n.t('Filter by user ID'),
        icon: icon('user'),
        iconType: 'user'
      },
      device_id: {
        field: 'device_id',
        label: i18n.t('Device ID'),
        description: i18n.t('Filter by device ID'),
        icon: icon('user-id'),
        iconType: 'user-id'
      },
      device_name: {
        field: 'device_name',
        label: i18n.t('Device name'),
        description: i18n.t('Filter by device name'),
        icon: icon('mobile'),
        iconType: 'mobile'
      }
    }
  }
}
