/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'

const buttons = [
  {
    id: 'web',
    text: 'Web',
    icon: 'globe',
    href: 'https://docs.cobrowse.io/sdk-installation/web'
  },
  {
    id: 'ios-native',
    text: 'iOS',
    icon: 'apple',
    href: 'https://docs.cobrowse.io/sdk-installation/ios'
  },
  {
    id: 'android-native',
    text: 'Android',
    icon: 'android',
    href: 'https://docs.cobrowse.io/sdk-installation/android'
  },
  {
    id: 'react-native',
    text: 'React Native',
    icon: 'react',
    href: 'https://docs.cobrowse.io/sdk-installation/react-native'
  },
  {
    id: 'dotnet',
    text: '.NET',
    icon: 'microsoft-dotnet',
    href: 'https://docs.cobrowse.io/sdk-installation/dotnet'
  },
  {
    id: 'macos',
    text: 'macOS',
    icon: 'macos',
    href: 'https://docs.cobrowse.io/sdk-installation/macos'
  },
  {
    id: 'windows',
    text: 'Windows',
    icon: 'windows',
    href: 'https://docs.cobrowse.io/sdk-installation/windows'
  },
  {
    id: 'flutter',
    text: 'Flutter',
    icon: 'flutter',
    href: 'https://docs.cobrowse.io/sdk-installation/flutter'
  }
]

const SDKButton = ({ id, className, children, ...props }) => (
  <a
    id={`${id}-sdk`}
    target='_blank'
    className={mergeClassNames('flex items-center gap-2 rounded-lg bg-gray-50 px-4 py-2.5 transition-all duration-150 ease-in hover:bg-gray-200 md:w-[100px] md:shrink md:flex-col md:gap-1 md:p-1 md:pb-2', className)}
    {...props}
  >
    {children}
  </a>
)

const SDKButtons = ({ className, itemClassName }) => {
  return (
    <div className={mergeClassNames('flex flex-wrap justify-center gap-2', className)}>
      {buttons.map((button) => (
        <SDKButton id={button.id} href={button.href} key={button.id} className={itemClassName}>
          <Icon type={button.icon} size='medium' className='md:hidden' />
          <div className='hidden py-3 md:block'>
            <Icon type={button.icon} size='large' />
          </div>
          <span className='text-xs'>{button.text}</span>
        </SDKButton>
      ))}
    </div>
  )
}

export default SDKButtons
