/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import qs from 'qs'
import env from '../../utils/env'
import SubscriptionView from './Subscription'
import Subscription from './Subscription.state'
import SubscriptionPicker from './SubscriptionPicker'
import PaymentSource from './PaymentSource.state'
import StripeCheckout from './StripeCheckout.state'
import Invoices from './Invoices.state'
import Account from '../accounts/Account.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import { SettingsContainer } from '../../components/SettingsContainer'
import { PLAN_NAMED_AGENT, PLAN_ACTIVE_SESSIONS } from './PlanTypes'
import mergeClassNames from '../../utils/mergeClassNames'
import Alert from '../../components/Alert'
import Loading from '../../components/Loading'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import { Icon } from '../../components/Icon'

const BillingSettings = () => {
  const [stripe, setStripe] = useState()
  const subscription = useSelector((state) => Subscription.fromState(state))
  const source = useSelector((state) => PaymentSource.fromState(state))
  const invoices = useSelector((state) => Invoices.fromState(state))
  const checkout = useSelector((state) => StripeCheckout.fromState(state))
  const account = useActiveAccountSelector()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(Subscription.actionCreators().getSubscription())
    dispatch(PaymentSource.actionCreators().getPaymentSource())
    dispatch(Invoices.actionCreators().getInvoices())

    const configureStripe = () => {
      if (!window.stripeInstance) {
        window.stripeInstance = window.Stripe(env.REACT_APP_STRIPE_PUBLIC_KEY)
      }

      setStripe(window.stripeInstance)
    }
    const stripeScriptTag = document.querySelector('#stripe-js')

    if (window.Stripe) {
      configureStripe()
    } else {
      stripeScriptTag?.addEventListener('load', configureStripe)
    }

    return () => {
      stripeScriptTag?.removeEventListener('load', configureStripe)
    }
  }, [dispatch])

  useEffect(() => {
    // Reset the subscription state if there is an error so that we don't end up
    // with stale state when switching between accounts
    if (subscription.error) {
      dispatch(Subscription.actionCreators().resetSubscription())
    }
  }, [subscription.error, dispatch])

  const doCheckout = async (plan) => {
    try {
      const checkout = await dispatch(StripeCheckout.actionCreators().createStripeCheckout({
        mode: 'subscription',
        plan
      }))

      stripe.redirectToCheckout({ sessionId: checkout.id })
    } catch (error) {
      console.log(error)
    }
  }

  const editCard = async () => {
    try {
      const checkout = await dispatch(StripeCheckout.actionCreators().createStripeCheckout({ mode: 'setup' }))

      stripe.redirectToCheckout({ sessionId: checkout.id })
    } catch (error) {
      console.log(error)
    }
  }

  const updateSubscriptionQuantity = (quantity) => {
    dispatch(Subscription.actionCreators().updateSubscription({ quantity }))
  }

  const planType = () => {
    const query = qs.parse(window.location.search.substring(1))

    return query.type ?? PLAN_NAMED_AGENT
  }

  const plans = () => {
    const plans = {
      [PLAN_NAMED_AGENT]: [
        {
          id: 'web',
          title: t('Website'),
          subtitle: t('co-browsing'),
          features: [
            t('Fast and reliable on any website, including mobile browsers'),
            t('Supports 3rd party content like PDFs')
          ],
          pricing: { month: 20, year: 216 }
        }, {
          id: 'all',
          title: t('All platforms'),
          subtitle: t('co-browsing'),
          features: [
            t('All from website co-browsing'),
            t('Additional native co-browsing across Android, iOS, RN, .NET, Flutter, MacOS and Windows')
          ],
          pricing: { month: 40, year: 432 }
        }
      ],
      [PLAN_ACTIVE_SESSIONS]: [
        {
          id: 'web-concurrent',
          title: t('Website'),
          subtitle: t('co-browsing'),
          features: [
            t('Fast and reliable on any website, including mobile browsers'),
            t('Supports 3rd party content like PDFs')
          ],
          pricing: { month: 60, year: 648 }
        }, {
          id: 'all-concurrent',
          title: t('All platforms'),
          subtitle: t('co-browsing'),
          features: [
            t('All from website co-browsing'),
            t('Additional native co-browsing across Android, iOS, RN, .NET, Flutter, MacOS and Windows')
          ],
          pricing: { month: 120, year: 1296 }
        }
      ]
    }

    return plans[planType()]
  }

  const showSignup = () => {
    return !subscription.resource?.items && !subscription.working
  }

  const renderSourceDetails = () => {
    if (!source.resource) {
      return null
    }

    // eslint-disable-next-line camelcase
    const { last4, brand, exp_year, exp_month } = source.resource
    return (
      <div className='flex items-center gap-x-3'>
        <Icon type='credit-card' size='large' />
        <span>
          <Trans>
            {/* eslint-disable-next-line camelcase */}
            {{ brand }} ending in <span>{{ last4 }}</span> expiring <span>{{ exp_month }} / {{ exp_year }}</span>
          </Trans>
        </span>
      </div>
    )
  }

  const renderPaymentSource = () => {
    if (!source.resource) {
      return null
    }

    return (
      <SettingsGroup key='source'>
        <SettingsGroup.Title>{t('Billing details')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('Enter the card details that will be used to pay for your subscription.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Button thinking={checkout.working} disabled={checkout.working} onClick={editCard}>{t('Change')}</SettingsGroup.Button>
        <SettingsGroup.Body>
          {renderSourceDetails()}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderSignup = () => {
    return (
      <div className='relative'>
        <SubscriptionPicker
          checkout={checkout}
          onPlanSelected={doCheckout}
          plans={plans()}
          planType={planType()}
        />
      </div>
    )
  }

  const renderSubscription = () => {
    if (showSignup()) {
      return renderSignup()
    }

    if (subscription.resource?.items) {
      return [
        <SubscriptionView
          key='sub'
          subscription={subscription.resource}
          working={subscription.working}
          onQuantityChanged={updateSubscriptionQuantity}
        />,
        renderPaymentSource()
      ]
    }
  }

  const renderInvoice = (invoice) => {
    return (
      <div key={invoice.id} className='flex justify-between gap-x-3'>
        <div className='flex items-center gap-x-3'>
          <Icon type='credit-card' size='large' />
          <span><span>${(invoice.amount_due / 100).toFixed(2)}</span> {t('charged on')} <span>{moment(invoice.date * 1000).format('MMMM Do YYYY')}</span></span>
        </div>
        <a className='min-w-fit px-4 py-3 text-slate' target='_blank' rel='noopener noreferrer' href={invoice.hosted_invoice_url}>{t('View invoice')}</a>
      </div>
    )
  }

  const renderInvoices = () => {
    if (invoices.collection.length <= 0) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title>{t('Past invoices')}</SettingsGroup.Title>
        <SettingsGroup.Body className='flex flex-col gap-y-4 md:col-span-2'>
          {invoices.collection.map(renderInvoice)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderError = () => {
    const { error } = checkout

    if (!error) {
      return null
    }

    return <Alert type='alert'>{error.message}</Alert>
  }

  if (!Account.hasFeature(account.resource, 'manage_billing')) {
    return (
      <div className='mt-12 text-center'>
        <p className='font-bold'>{t('Looking to upgrade?')}</p>
        <p>{t('Please sign in as an administrator to manage your billing settings.')}</p>
      </div>
    )
  }

  if (!Account.hasFeature(account.resource, 'stripe_billing')) {
    return (
      <SettingsContainer>
        <div className='mt-4 flex h-full flex-col justify-center text-center'>
          <p className='text-sm font-medium text-slate'>{t('Self-service billing is not available for your account.')}</p>
          <p className='text-xs text-gray-700'><Trans>Please contact <a href='mailto:hello@cobrowse.io' target='_blank'>hello@cobrowse.io</a> to manage your billing.</Trans></p>
        </div>
      </SettingsContainer>
    )
  }

  return (
    <SettingsContainer>
      <div className={mergeClassNames('flex flex-col px-2 py-6', showSignup() && 'gap-y-4')}>
        <p className='text-xs text-gray-700'>
          {t('Cobrowse is 100% free to use with no expiry during development and testing.')}
        </p>
        {subscription.working
          ? (
            <Loading className='min-h-[300px]' />
            )
          : (
            <SettingsContainer.Body className={mergeClassNames(showSignup() && 'divide-y-0')}>
              {renderError()}
              {renderSubscription()}
              {renderInvoices()}
            </SettingsContainer.Body>
            )}
      </div>
    </SettingsContainer>
  )
}

export default BillingSettings
