export function useIsDeviceLoading (device) {
  if (device == null) return false

  const { notification_token: notificationToken, presence } = device

  return notificationToken && !presence
}

export function useIsDeviceOnline (device) {
  if (device == null) return false

  const { presence } = device

  return !!(presence && presence.present)
}

export function useIsDeviceConnectable (device) {
  return Boolean(device?.connectable)
}
