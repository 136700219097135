import React, { useEffect, useRef } from 'react'
import Loading from './Loading'
import { getTestId } from '../utils/getTestId'
import mergeClassNames from '../utils/mergeClassNames'
import Button from './Button'
import { useTranslation } from 'react-i18next'

const StandardList = ({
  className,
  scrollableContentClassName,
  listContainerClassName,
  style,
  testId,
  children,
  items,
  loading,
  renderItem,
  renderEmptyList,
  onBottomReached,
  hasMore = true,
  hasError = false
}) => {
  const topRef = useRef()
  const bottomRef = useRef()

  const { t } = useTranslation()

  useEffect(() => {
    if (!onBottomReached || items.length === 0 || !hasMore || hasError) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries

      if (entry.isIntersecting) {
        onBottomReached()
      }
    })

    observer.observe(bottomRef.current)

    return () => observer.disconnect()
  }, [onBottomReached, items.length, hasMore, hasError])

  const renderRow = (item, { index, style }) => {
    return (
      <div key={index} style={style} className='group/list-item contents'>
        {renderItem(item)}
      </div>
    )
  }

  const renderList = (items) => {
    return items.map((item, index) => renderRow(item, { index }))
  }

  const renderContents = (items) => {
    if (items.length === 0 && loading) {
      return <Loading className='h-full py-8' />
    }

    if (items.length === 0) {
      return renderEmptyList()
    }

    return renderList(items)
  }

  return (
    <div
      className={mergeClassNames('relative flex size-full min-h-0 min-w-0 flex-col', className)}
      style={style}
      {...getTestId(testId)}
      data-standard-list='true'
    >
      <div className={mergeClassNames('flex h-full flex-col gap-y-4 sm:gap-y-0', scrollableContentClassName)}>
        <div className='absolute top-0 empty:mb-0 sm:mb-4' ref={topRef}>
          {children}
        </div>
        <div className={mergeClassNames('group/list col-span-1 grid auto-rows-auto gap-0', listContainerClassName)}>
          {renderContents(items)}
          {loading && items.length !== 0 && <Loading className='py-8 md:pb-0' />}
          {hasError && (
            <div className='flex justify-center pt-8'>
              <Button variant='text' size='small' onClick={onBottomReached}>{t('Load More')}</Button>
            </div>
          )}
        </div>
        <div className='absolute bottom-0' ref={bottomRef} />
      </div>
    </div>
  )
}

export default StandardList
