import { useMemo } from 'react'
import { omit, pick } from 'lodash'
import StandardFields from '../modules/devices/StandardFields'
import { useNormalisedDeviceInfo } from './useNormalisedDeviceInfo'
import { useTranslation } from 'react-i18next'

const NUMBER_OF_FIELDS_TO_SHOW = 2

const selectPrioritizedItems = (items, priorityArray) => {
  const filteredItems = Object.fromEntries(Object.entries(items).filter(([, value]) => !!value))
  const selectedItems = pick(filteredItems, priorityArray)

  return Object.fromEntries(
    Object.entries(selectedItems).slice(0, NUMBER_OF_FIELDS_TO_SHOW)
  )
}

export function useDeviceInfoData (device, customData, shouldPrioritize = false) {
  const deviceInfo = useNormalisedDeviceInfo(device)
  const { t } = useTranslation()

  return useMemo(() => {
    const sections = [
      {
        category: t('Custom data'),
        type: 'customData',
        fieldInfo: StandardFields.customData()
      },
      {
        category: t('Device information'),
        type: 'deviceInfo',
        fieldInfo: StandardFields.deviceData()
      }
    ]

    if (!shouldPrioritize) {
      return {
        customData,
        deviceInfo,
        sections
      }
    }

    const fieldPriority = [
      'user_email',
      'user_id',
      'device_id',
      'app_name',
      'app_version',
      ...Object.keys(customData || {}),
      ...Object.keys(deviceInfo || {})
    ]

    // Show the device locale as an extra prioritized item only if it's set
    const prioritized = selectPrioritizedItems({ ...customData, ...deviceInfo }, fieldPriority)
    const customDataItems = omit(customData, Object.keys(prioritized))
    const deviceInfoItems = omit(deviceInfo, Object.keys(prioritized))

    return {
      customData: customDataItems,
      deviceInfo: deviceInfoItems,
      prioritized,
      sections
    }
  }, [shouldPrioritize, customData, deviceInfo, t])
}
