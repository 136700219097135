import qs from 'qs'
import RESTObject from '../redux-rest'
import { UPDATE_UI_STATE, updateUiState } from '../ui-state/UI.state.js'

export default class Account extends RESTObject {
  static namespace () {
    return 'Account'
  }

  static url (params = {}, context = {}) {
    return `/api/1/accounts/${params.id || ''}?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }

  static activeAccount (state = {}) {
    const activeLicense = state.ui.license_key
    const accounts = Account.fromState(state)
    let account = accounts.collection.find(ac => ac.license_key === activeLicense)
    if (!account) account = accounts.collection.find(ac => ac.license_key === window?.localStorage?.['cobrowse-account'])
    const byActive = accounts.collection.sort((a, b) => (b.devices_active - a.devices_active))
    if (!account) account = byActive.length && byActive[0]
    return { ...accounts, resource: account }
  }

  static setActiveAccount (account) {
    try {
      window.localStorage.setItem('cobrowse-account', account.license_key)
    } catch (e) {
      console.warn('Failed to set cobrowse-account in local storage', e)
    }
    return updateUiState({ license_key: account.license_key })
  }

  static actionCreators () {
    return {
      ...super.actionCreators(),
      setActiveAccount: this.setActiveAccount.bind(this)
    }
  }

  static hasFeature (account, feature, defaultValue = false) {
    const { features } = account
    if (typeof features?.[feature] === 'undefined') return defaultValue
    return features[feature]
  }
}

export const accountHeaderMiddleware = () => next => action => {
  if (action.type === UPDATE_UI_STATE && action.state && action.state.license_key) {
    RESTObject.setHeader('X-CobrowseLicense', action.state.license_key)
  }
  return next(action)
}
