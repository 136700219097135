import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import MagicLink from './MagicLink.state'
import Input from '../../components/Input'
import { useNavigate } from 'react-router-dom'
import Alert from '../../components/Alert'

const MagicLinkLogin = ({ disabled, text, query, process }) => {
  const [email, setEmail] = useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const magic = useSelector((state) => MagicLink.fromState(state))
  const navigate = useNavigate()

  // Clear the magic link state when the component is unmounted to avoid showing the "success" message when the user
  // navigates to the create account page
  useEffect(() => {
    return () => {
      dispatch(MagicLink.actionCreators().resetMagicLink())
    }
  }, [dispatch])

  useEffect(() => {
    if (magic.resource && magic.resource.success) {
      navigate('/magic/success', { state: { process } })
    }
  }, [magic.resource, navigate, process])

  const createMagicLink = (email, query) => (
    dispatch(MagicLink.actionCreators().createMagicLink({ email }, query))
  )

  const sendLink = (e) => {
    if (disabled) {
      return false
    }

    e.preventDefault()
    e.stopPropagation()

    if (!magic.working) {
      createMagicLink(email, query)
    }

    return false
  }

  const renderError = () => {
    const { error } = magic

    // When the createMagicLink action is rejected due to a network error, the error property will be null because
    // there won't be any response from the API. We want to show a generic error message in this case.
    if (error || error === null) {
      return <Alert type='alert' variant='inline' icon='alert' className='mb-6'>{error?.message || error?.id || t('An error has occurred')}</Alert>
    }

    return null
  }

  const renderForm = () => {
    return (
      <form className='flex flex-col gap-y-2' onSubmit={sendLink}>
        <Input required className='h-10' type='email' name='email' placeholder={t('Enter your email')} autoComplete='email' disabled={disabled} onChange={e => setEmail(e.target.value)} />
        <Button variant='primary' isFullWidth disabled={disabled || !email} thinking={magic.working}>{text}</Button>
      </form>
    )
  }

  return (
    <div>
      {renderError()}
      {renderForm()}
    </div>
  )
}

export default MagicLinkLogin
