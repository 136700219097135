import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { tw } from '../utils/tw'
import { Icon } from './Icon'

export const buttonClassName = ({
  variant = 'primary',
  className = '',
  overrides = '',
  size = 'big',
  env = 'light',
  isIconButton = false,
  isFullWidth = false
} = {}) => {
  const variants = {
    primary: tw`bg-violet text-slate-50 hover:bg-violet-800`,
    secondary: tw`bg-slate-800 text-white hover:bg-slate-900`,
    tertiary: tw`bg-slate-50 text-slate-900 hover:bg-gray-100`,
    text: tw`text-slate-900 data-[disabled=true]:bg-white data-[disabled=true]:text-gray-300 hover:bg-gray-100 disabled:bg-white disabled:text-gray-300`,
    error: tw`bg-red-500 text-white hover:bg-red-600 data-[disabled=true]:hover:bg-red-500 disabled:hover:bg-red-500`
  }

  const envOverrides = {
    dark: {
      secondary: tw`bg-gray-50 text-slate-900 hover:bg-white hover:text-slate-950`,
      tertiary: tw`bg-gray-800 text-slate-50 hover:bg-gray-800/50`,
      text: tw`text-slate-50 hover:bg-slate-800`
    }
  }

  const sizes = {
    'extra-large': mergeClassNames('h-12 px-4', isIconButton && 'w-12'),
    big: mergeClassNames('h-10 px-4', isIconButton && 'w-10'),
    medium: mergeClassNames('h-9 px-4', isIconButton && 'w-9'),
    small: mergeClassNames('h-8 px-3', isIconButton && 'w-8'),
    none: ''
  }

  const defaultClassName = tw`relative flex cursor-pointer items-center gap-x-2 rounded-lg text-sm font-medium outline-none data-[disabled=true]:cursor-not-allowed data-[disabled=true]:bg-gray-100 data-[disabled=true]:text-gray-600 data-[disabled=true]:hover:bg-gray-100 focus:outline-none focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-violet disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-600 disabled:hover:bg-gray-100`
  const darkDefaultClassName = tw`data-[disabled=true]:bg-slate-800 data-[disabled=true]:text-gray-600 data-[disabled=true]:hover:bg-slate-800 data-[disabled=true]:hover:text-gray-600 disabled:bg-slate-800 disabled:text-gray-600 disabled:hover:bg-slate-800 disabled:hover:text-gray-600`

  return mergeClassNames(
    variant !== 'plain' && defaultClassName,
    variants[variant],
    sizes[size],
    envOverrides[env]?.[variant],
    env === 'dark' && darkDefaultClassName,
    (isIconButton || isFullWidth) && 'justify-center',
    isFullWidth && 'w-full',
    className,
    overrides
  )
}

const Button = forwardRef(({
  variant = 'primary',
  size = 'big',
  env = 'light',
  isIconButton = false,
  isFullWidth = false,
  disabled,
  style,
  onClick,
  className,
  thinking,
  children,
  Component = 'button',
  ...props
}, ref) => {
  return (
    <Component
      ref={ref}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={buttonClassName({ variant, className, overrides: thinking && 'overflow-hidden', size, isIconButton, env, isFullWidth })}
      {...props}
    >
      {children}
      {!!thinking && <span className='absolute inset-0 flex items-center justify-center rounded-lg bg-inherit'><Icon type='spin2' spin /></span>}
    </Component>
  )
})

export default Button
