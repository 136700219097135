import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const Loading = ({ className, spinnerClassName, children }) => {
  return (
    <div className={mergeClassNames('flex flex-col items-center justify-center gap-y-2', className)}>
      <div className={mergeClassNames('grid size-6 animate-spinner-front before:animate-spinner-back before:rounded-full before:border-slate-900 before:[grid-area:1/1] after:animate-spinner-back after:rounded-full after:border-4 after:border-b-slate-200 after:border-l-slate-200 after:border-r-slate-900 after:border-t-slate-900 after:[animation-direction:reverse] after:[grid-area:1/1]', spinnerClassName)} />
      {children}
    </div>
  )
}

export default Loading
