import React from 'react'
import Alert from '../../components/Alert'
import { useTranslation } from 'react-i18next'

const defaultSelector = (platform) => {
  switch (platform) {
    case 'web':
      return '.redacted'
    case 'ios':
      return 'UILabel#12456'
    case 'android':
      return 'TextInput#mySecretTextField'
  }
}

const platformText = (platform) => {
  if (platform === 'ios') {
    return 'iOS'
  }

  return platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase()
}

const RedactionConfigBlock = ({
  platform,
  sdkVersion,
  limitations,
  renderTextArea
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='flex flex-col gap-y-1.5'>
        <p className='text-xs'>{t('Redaction')}</p>
        {renderTextArea(
          `${platform}_redaction_selectors`,
          t('{{ platform }} redaction selectors', { platform: platformText(platform) }),
          defaultSelector(platform),
          'redaction',
          platform
        )}
      </div>
      <div className='flex flex-col gap-y-1.5'>
        <p className='text-xs'>{t('Unredaction')}</p>
        <Alert type='warning' className='font-normal text-gray-800'>
          <strong className='block font-semibold text-slate'>
            {t('Requires {{platform}} SDK version {{sdkVersion}} or above.', { platform: platformText(platform), sdkVersion })}
          </strong>
          {limitations && ` ${limitations}`}
        </Alert>
        {renderTextArea(
          `${platform}_unredaction_selectors`,
          t('{{ platform }} unredaction selectors', { platform: platformText(platform) }),
          platform === 'web' ? '.unredacted' : defaultSelector(platform),
          'unredaction',
          platform
        )}
      </div>
    </>
  )
}

export default RedactionConfigBlock
