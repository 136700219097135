import React, { useState, useCallback } from 'react'
import StandardList from '../../components/StandardList'
import useDebounce from '../../hooks/useDebounce'
import useThrottle from '../../hooks/useThrottle'
import AccountDetails from './AccountDetails'
import EmptyList from './EmptyList'
import ListControls from './ListControls'
import AccountEditorSidebar from './AccountEditorSidebar'
import { useSidebarState } from '../../components/Sidebar'

const AdminAccountList = ({
  accounts,
  user,
  getAccount,
  updateAccount,
  updateFeatureSwitches,
  refresh
}) => {
  const [filter, setFilter] = useState('')
  const [sort, setSort] = useState('created')
  const [expandedAccountId, setExpandedAccountId] = useState(null)
  const [, toggle] = useSidebarState()

  const onFilterUpdated = (filter) => {
    setFilter(filter)
    refreshItems()
  }

  const onSortUpdated = (sort) => {
    setSort(sort)
    refreshItems()
  }

  const refreshItems = useDebounce(() => {
    refresh(filters())
  }, 500)

  const loadMore = useThrottle(() => {
    if (accounts.working) {
      return
    }

    refresh({
      ...filters(),
      skip: accounts.collection.length
    }, { merge: true })
  }, 200)

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  const filters = () => {
    return { filter, sort }
  }

  const updateLimit = (account, field, value) => {
    const previousValue = account.limits[field]
    const newValueIsNotNumber = value === undefined || value === null || Number.isNaN(value)
    const previousValueIsNotNumber = previousValue === undefined || previousValue === null || Number.isNaN(previousValue)

    if (value === previousValue || (newValueIsNotNumber && previousValueIsNotNumber)) {
      return
    }

    updateAccount({
      id: account.id,
      limits: {
        [field]: value
      }
    }, {
      forceAccount: account.license_key
    })
  }

  const updateFeatureSwitch = async (account, feature, state) => {
    await updateFeatureSwitches({
      [feature]: { enabled: state }
    }, {
      forceAccount: account.license_key
    })

    refreshItems()
  }

  const updateStripeSubscription = (account, subscription) => {
    const previousValue = account.stripe.subscription
    const previousValueIsEmpty = !previousValue
    const newValueIsEmpty = !subscription

    if (previousValue === subscription || (previousValueIsEmpty && newValueIsEmpty)) {
      return
    }

    updateAccount({
      id: account.id,
      stripe: { subscription }
    }, {
      forceAccount: account.license_key
    })
  }

  const expandedAccount = expandedAccountId
    ? accounts.collection.find(account => account.id === expandedAccountId)
    : null

  const renderItem = useCallback((account) => {
    const handleOpenSidebar = (account) => {
      toggle(true)
      setExpandedAccountId(account.id)
    }

    return (
      <AccountDetails
        account={account}
        openSidebar={handleOpenSidebar}
      />
    )
  }, [toggle])

  const renderEmptyList = useCallback(() => (
    <EmptyList accounts={accounts} filter={filter} />
  ), [accounts, filter])

  return (
    <>
      <div className='flex flex-col gap-y-4'>
        <ListControls
          filter={filter}
          onFilterUpdated={onFilterUpdated}
          onSortUpdated={onSortUpdated}
        />
      </div>
      <div className='h-full overflow-auto'>
        <StandardList
          items={accounts.collection}
          renderItem={renderItem}
          renderEmptyList={renderEmptyList}
          onBottomReached={loadMore}
          listContainerClassName='divide-y divide-gray-200 *:block md:pb-0'
        />
        <AccountEditorSidebar
          account={expandedAccount}
          user={user}
          updateFeatureSwitch={updateFeatureSwitch}
          updateLimit={updateLimit}
          updateStripeSubscription={updateStripeSubscription}
          handleKeyDown={handleKeyDown}
        />
      </div>
    </>
  )
}

export default AdminAccountList
