import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'
import Button from './Button'
import { InputWithError } from './InputWithError'

export function SettingsColorRow ({ color, onRemove, onChange, disableRemoving = false, animateOnMount = false, error, ...props }) {
  const [show, setShow] = useState(true)
  const { t } = useTranslation()

  return (
    <Transition
      appear={animateOnMount}
      show={show}
      className={mergeClassNames('flex h-14 gap-x-4 overflow-hidden transition-[max-height,opacity] duration-300 motion-reduce:transition-none', error && 'h-[78px]')}
      enterFrom='opacity-0 max-h-0'
      enterTo='opacity-100 max-h-20'
      leaveFrom='opacity-100 max-h-20'
      leaveTo='opacity-0 max-h-0'
      afterLeave={onRemove}
    >
      <label className='group/color-label relative size-9 cursor-pointer rounded-lg border border-gray-100' style={{ backgroundColor: color }}>
        <input type='color' value={color} className='invisible' onChange={(evt) => onChange(evt.target.value)} />
        <div className='pointer-events-none absolute inset-0 flex items-center justify-center text-gray-700 opacity-0 transition-opacity duration-200 group-hover/color-label:opacity-100'>
          <Icon type='ink-pen' size='small' />
        </div>
      </label>
      <InputWithError
        type='text'
        value={color}
        onChange={(evt) => onChange(evt.target.value)}
        placeholder='#FFFFFF'
        error={error}
        {...props}
      />
      <Button variant='text' size='medium' isIconButton onClick={() => setShow(false)} disabled={disableRemoving} className={mergeClassNames(disableRemoving && 'transition-all duration-300 motion-reduce:transition-none')}>
        <span className='sr-only'>{t('Remove color {{color}}', { color })}</span>
        <Icon type='circle-minus' size='small' />
      </Button>
    </Transition>
  )
}
