import React from 'react'

const EmptyList = ({ accounts, filter }) => {
  if (accounts.working) {
    return null
  }

  return (
    <div className='p-3 text-center'>
      <p>No accounts found for {filter}.</p>
    </div>
  )
}

export default EmptyList
