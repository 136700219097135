import React, { Suspense } from 'react'
import useActiveAccountSelector from '../hooks/useActiveAccountSelector'
import { useSelector } from 'react-redux'
import User from '../modules/users/User.state'
import LoadingScreen from '../components/LoadingScreen'
import ErrorMessage from '../modules/errors/ErrorMessage'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import PostMessageAPI from '../modules/post-message/PostMessageAPI'
import { PostMessageAPIv2 } from '../modules/post-message/PostMessageAPIv2'

const Layout = () => {
  const account = useActiveAccountSelector()
  const user = useSelector(state => User.fromState(state))

  if ((!user.resource) && (user.error)) {
    return <Suspense fallback={<LoadingScreen />}><ErrorMessage error={user.error} /></Suspense>
  }

  return (
    <div key={account.resource.id} className='flex h-full flex-col'>
      <ScrollRestoration />
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
      <PostMessageAPI />
      <PostMessageAPIv2 />
    </div>
  )
}

export default Layout
