/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { StepContainer } from './StepContainer'
import SDKButtons from '../../components/SDKButtons'
import Alert from '../../components/Alert'
import CopyText from '../../components/CopyText'

const DevelopStep = ({ licenseKey }) => {
  const { t } = useTranslation()

  return (
    <StepContainer>
      <StepContainer.Header>
        <StepContainer.Title>{t('Add the Cobrowse SDKs')}</StepContainer.Title>
        <StepContainer.Description>
          {t("We've provided a set of easy to use SDKs to integrate into all of your apps or websites. They take only a few minutes of developer time to integrate.")}
        </StepContainer.Description>
        <Alert hideIcon>
          <Trans>Contact us any time at <a className='text-violet underline' href='mailto:hello@cobrowse.io' target='_blank'>hello@cobrowse.io</a> and we{'\''}ll be happy to help!</Trans>
        </Alert>
      </StepContainer.Header>
      <StepContainer.Body>
        <div className='text-center'>
          <SDKButtons
            className='grid auto-rows-fr grid-cols-4 gap-2'
            itemClassName='flex-col justify-center md:w-auto'
          />
        </div>
        <CopyText label={t("You'll need your license key:")}>{licenseKey}</CopyText>
      </StepContainer.Body>
    </StepContainer>
  )
}

export default DevelopStep
