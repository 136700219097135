import React from 'react'
import { useTranslation } from 'react-i18next'

const SkipToMainContent = () => {
  const { t } = useTranslation()

  return (
    <a
      href='#main-content'
      className='sr-only bg-slate-100 text-black outline-dashed focus:not-sr-only focus:absolute focus:start-0 focus:top-0 focus:z-[9999] focus:p-4'
    >
      {t('Skip to main content')}
    </a>
  )
}

export default SkipToMainContent
