import React from 'react'
import Button from './Button'
import Loading from './Loading'
import { Icon } from './Icon'

const LoadingScreen = ({ loading, message, cancelText, onCancel }) => {
  return (
    <div className='m-auto flex flex-col'>
      {loading === false ? message : <Loading>{message}</Loading>}
      {onCancel && (
        <Button variant='error' className='mt-4 h-10 w-auto self-center rounded-[62px] bg-red-500 px-5 py-1 hover:bg-red-600 disabled:hover:bg-red-600' onClick={onCancel} aria-label={cancelText}>
          <Icon type='phone' size='large' />
        </Button>
      )}
    </div>
  )
}

export default LoadingScreen
