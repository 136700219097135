import React from 'react'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../utils/mergeClassNames'
import { getTestId } from '../utils/getTestId'

const ResourceInfo = ({ actionButton, className, children, openSidebar }) => {
  const { t } = useTranslation()

  // This is an anti-pattern but a necessary evil to make it accessible. Having an interactive element
  // (the connect / session recording link) within a button is a bad practice but, as the entire row
  // needs to be actionable, I don't think there's another way around it.
  // Turning it into a button instead of a div with role='button' would mean it's not valid HTML.

  return (
    <div
      role='button'
      className={mergeClassNames('grid w-full min-w-0 cursor-pointer gap-4 border-b border-b-gray-200 px-3 py-4 hover:bg-violet-50 focus:outline-none focus-visible:bg-violet-50 md:gap-3 md:px-6', className)}
      {...getTestId('device-item')}
      onClick={openSidebar}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.target === e.currentTarget && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault()
          openSidebar()
        }
      }}
      aria-label={t('Open device details')}
    >
      <div className='grid w-full min-w-0 gap-4 md:flex md:justify-between md:gap-3'>
        {children}
        <div className='flex items-center empty:hidden'>
          {actionButton}
        </div>
      </div>
    </div>
  )
}

export default ResourceInfo
