import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { inputClassName } from './Input'

const Select = forwardRef(({ variant = 'primary', disabled, readOnly = false, className, children, ...props }, ref) => {
  const variants = {
    simple: 'bg-transparent w-auto pe-8 text-right'
  }

  return (
    <select
      ref={ref}
      className={mergeClassNames(variant !== 'plain' && inputClassName, variants[variant], 'cursor-pointer appearance-none bg-[url(./icons/down-open.svg)] bg-[length:auto_15px] bg-[position:calc(100%-8px)_50%] bg-no-repeat focus:outline-none disabled:cursor-not-allowed disabled:shadow-none rtl:bg-[position:8px]', readOnly && 'bg-none', className)}
      disabled={disabled}
      {...props}
    >{children}
    </select>
  )
})

export default Select
