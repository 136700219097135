import { useTranslation } from 'react-i18next'

const transformLocaleValue = (value, currentLocale = 'en') => {
  try {
    const languageNames = new Intl.DisplayNames([currentLocale], { type: 'language' })

    return languageNames.of(value)
  } catch (e) {
    console.warn(`Failed to transform locale value: ${value}`, e)

    return value
  }
}

export function useDeviceInfoValue (value, code, info, isTableView) {
  const { i18n } = useTranslation()

  // if any of this return null so it can be acted upon
  if (value === null || value === '') return null

  if (value === true) return 'YES'
  if (value === false) return 'NO'

  let transformedValue = value

  if (code === 'device_locale') {
    transformedValue = transformLocaleValue(value, i18n.language)
  }

  if (isTableView && typeof transformedValue === 'string' && (info.capitalize == null || info.capitalize)) {
    transformedValue = transformedValue.charAt(0).toUpperCase() + transformedValue.slice(1)
  }

  return transformedValue
}
