import React from 'react'
import Input from './Input'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'

export function InputWithError ({
  error,
  wrapperClassName,
  className,
  ...props
}) {
  return (
    <div className={mergeClassNames('w-full', wrapperClassName)}>
      <Input
        className={mergeClassNames(
          error && 'shadow-[inset_0_0_0_1px_theme(colors.red.500)] has-[:focus]:!shadow-[inset_0_0_0_1px_theme(colors.red.500)] hover:shadow-[inset_0_0_0_1px_theme(colors.red.200)] focus:shadow-[inset_0_0_0_1px_theme(colors.red.500)]',
          className
        )}
        {...props}
      />
      {error && (
        <div className='mt-1.5 flex items-center gap-x-1.5 text-xs text-red-500'>
          <Icon type='info-circle' size='extra-small' />
          {error}
        </div>
      )}
    </div>
  )
}
