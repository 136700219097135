import { useToggle } from '../hooks/useToggle'
import React, { useCallback, useRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { useClickOutside } from '../hooks/useClickOutside'

export function ExpandableVerticalMenu ({ Button, children, className, menuClassname, isHorizontal = false }) {
  const ref = useRef(null)
  const [isExpanded, toggle] = useToggle()
  const close = useCallback(() => toggle(false), [toggle])

  useClickOutside(ref, close, isExpanded)

  return (
    <div
      className={mergeClassNames('relative flex size-12 items-center justify-center md:rounded', className)}
      onClick={toggle}
      ref={ref}
    >
      <Button isExpanded={isExpanded} />
      <div
        className={mergeClassNames(
          'absolute bottom-14 z-50 flex h-fit translate-x-0 items-center justify-center gap-4 rounded-full bg-slate-800 p-4 shadow transition-opacity duration-300 ease-in-out motion-reduce:transition-none md:gap-2',
          !isHorizontal && 'flex-col',
          isExpanded
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-0',
          menuClassname
        )}
      >
        {children}
      </div>
    </div>
  )
}
