import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import Button from './Button'
import { Icon } from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

const Dialog = ({
  isOpen = false,
  onAccept,
  onCancel,
  onClose,
  acceptText,
  cancelText,
  acceptProps = {},
  cancelProps = {},
  titleText,
  id = 'modal',
  className,
  panelClassName,
  bodyClassName,
  children
}) => {
  const { t } = useTranslation()

  const handleOnAccept = (e) => {
    onAccept?.(e)
  }

  const handleOnCancel = (e) => {
    onCancel?.(e)
  }

  const handleOnClose = (e) => {
    onClose?.(e)
  }

  const accept = typeof onAccept === 'function'
    ? (<Button onClick={handleOnAccept} {...acceptProps}>{acceptText || t('Accept')}</Button>)
    : null
  const cancel = typeof onCancel === 'function'
    ? (<Button variant='tertiary' onClick={handleOnCancel} {...cancelProps}>{cancelText || t('Cancel')}</Button>)
    : null
  const close = typeof onClose === 'function'
    ? (
      <Button variant='plain' size='small' onClick={handleOnClose}>
        <Icon type='x' size='small' />
      </Button>
      )
    : null

  const showHeader = !!titleText
  const showFooter = accept || cancel

  return (
    <HeadlessDialog
      open={isOpen}
      onClose={handleOnClose}
      id={id}
      className='relative z-50'
    >
      <div className='fixed inset-0 bg-slate/85 backdrop-blur' aria-hidden='true' />
      <div className={mergeClassNames('fixed inset-0 flex items-center justify-center p-4', className)}>
        <HeadlessDialog.Panel
          className={mergeClassNames('mx-auto max-w-screen-md rounded-lg bg-white px-3 pb-3 pt-2', panelClassName)}
        >
          {showHeader && (
            <div className='mb-2 flex items-center justify-between'>
              <HeadlessDialog.Title className='text-sm font-medium'>
                {titleText}
              </HeadlessDialog.Title>
              {close}
            </div>
          )}
          <div className={mergeClassNames('flex flex-col gap-y-4 text-gray-700', bodyClassName)}>
            {children}
          </div>
          {showFooter && (
            <div className='mt-4 flex flex-row justify-between gap-2.5 md:justify-end'>
              {cancel}
              {accept}
            </div>
          )}
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  )
}

export default Dialog
