import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuditEventList from './AuditEventList'
import AuditEvent from './AuditEvent.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import { SettingsContainer } from '../../components/SettingsContainer'

const AuditSettings = () => {
  const dispatch = useDispatch()
  const audit = useSelector(state => AuditEvent.fromState(state))
  const { t } = useTranslation()

  const refresh = useCallback((params = {}, options = {}) => {
    return dispatch(AuditEvent.actionCreators().getAuditEvents(undefined, {
      resource_type: ['AccountMember', 'Account', 'SAMLProvider', 'JoinToken'],
      ...params
    }, options))
  }, [dispatch])

  useEffect(() => {
    refresh()
  }, [refresh])

  const renderAuditTrail = () => {
    if (!audit.collection) {
      return null
    }

    return (
      <SettingsGroup className='h-full pt-0' singleColumn>
        <SettingsGroup.Body className='mt-0'>
          <AuditEventList events={audit} refresh={refresh} className='h-full' />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  return (
    <SettingsContainer className='gap-y-4 divide-y-0 pt-6'>
      <div>
        <p className='text-xs text-gray-700'>
          {t('These are all the events that have occurred in your account.')}
        </p>
      </div>
      <div>
        {renderAuditTrail()}
      </div>
    </SettingsContainer>
  )
}

export default AuditSettings
