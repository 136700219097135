import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../../components/Link'
import Button from '../../components/Button'
import Account from '../accounts/Account.state'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'

const MessageWrapper = ({ children }) => {
  return <p className='mb-4 text-2xl font-medium'>{children}</p>
}

const ErrorMessage = ({ error, children, env = 'light' }) => {
  const account = useActiveAccountSelector()
  const { t } = useTranslation()

  const retry = () => {
    window.location.reload()
  }

  const renderLoginRequired = () => {
    return (
      <div>
        <MessageWrapper>{error.message}</MessageWrapper>
        <p>{t('Your login session may have expired, please login and try again.')}</p>
      </div>
    )
  }

  const renderOverConcurrentSessionsError = () => {
    return (
      <div>
        <MessageWrapper>{error.message}</MessageWrapper>
        <p className='mb-4'>{t('This means other users in your account have active sessions so you may not start a new one. End one of the other sessions before starting a new session.')}</p>
        <div className='flex flex-col items-center gap-y-4'>
          <Button onClick={retry}>{t('Retry')}</Button>
          <span><Link to='/dashboard/history' env={env}>{t('Manage all sessions')}</Link></span>
        </div>
      </div>
    )
  }

  const renderOverPeriodSessionsError = () => {
    const canManageBilling = Account.hasFeature(account.resource, 'manage_billing', false)
    return (
      <div>
        <MessageWrapper>{children}</MessageWrapper>
        {canManageBilling
          ? <p className='mb-4'>{t('Your account has reached its license limit for this billing period.')}</p>
          : <p className='mb-4'>{t('Your account has reached its license limit for this billing period.')} {t('Please contact your account administrator to manage your account licenses.')}</p>}
        <div className='flex flex-col items-center gap-y-4'>
          <Button onClick={retry}>{t('Retry')}</Button>
          {canManageBilling ? <Link to='/dashboard/settings/billing' env={env}>{t('Manage billing')}</Link> : null}
        </div>
      </div>
    )
  }

  const renderError = () => {
    if (error?.id === 'session_concurrency_limit') {
      return renderOverConcurrentSessionsError()
    }

    if (error?.id === 'session_usage_limit') {
      return renderOverPeriodSessionsError()
    }

    if (error?.id === 'auth_login_required') {
      return renderLoginRequired()
    }

    const inIframe = (window.self !== window.top)

    return (
      <div>
        <MessageWrapper>{children}</MessageWrapper>
        {error ? <p className='mb-4'>{error.message}</p> : null}
        {inIframe ? null : <a href={`/dashboard${window.location.search}`} className={env === 'dark' ? 'text-slate-50' : 'text-slate-900'}>{t('Back to safety')}</a>}
      </div>
    )
  }

  return (
    <div className='container flex h-full max-w-4xl items-center justify-center px-5 text-center md:mx-auto'>
      {renderError()}
    </div>
  )
}

export default ErrorMessage
