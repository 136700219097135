import React from 'react'
import { buttonClassName } from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'

const ViewAccountButton = ({ account }) => {
  if (!account) {
    return null
  }

  return (
    <a className={mergeClassNames(buttonClassName({ isFullWidth: true }), 'mt-4')} href={`/dashboard?forceAccount=${account.license_key}`} target='_blank' rel='noopener noreferrer'>
      View account
    </a>
  )
}

export default ViewAccountButton
