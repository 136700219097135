import React from 'react'
import mergeClassNames from '../../utils/mergeClassNames'
import { useHasPaidSubscription } from '../../hooks/useHasPaidSubscription'

const OrganisationName = ({ variant = 'pill', account }) => {
  const hasPaidSubscription = useHasPaidSubscription(account)

  if (variant === 'text') {
    return (
      <div className='text-sm font-medium text-slate'>
        {account?.organisation_name || 'Unnamed account'}
      </div>
    )
  }

  return (
    <div className='flex items-center gap-x-2.5 self-start rounded-full border border-gray-200 bg-gray-50 px-3 py-2 text-slate'>
      <div className={
        mergeClassNames(
          'size-[7px] rounded-full bg-gray-500 outline outline-2 outline-gray-500/25',
          hasPaidSubscription && 'bg-violet outline-violet/25'
        )
      }
      />
      <div className='text-xs font-medium'>
        {account?.organisation_name || 'Unnamed account'}
      </div>
    </div>
  )
}

export default OrganisationName
