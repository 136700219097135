import React from 'react'
import { useTranslation } from 'react-i18next'
import LinkButton from '../../components/LinkButton'
import { ORDER } from './constants'

const Controls = ({ currentStage }) => {
  const { t } = useTranslation()
  const next = ORDER[ORDER.indexOf(currentStage) + 1]
  const prev = ORDER[ORDER.indexOf(currentStage) - 1]

  return (
    <div className='static inset-x-6 bottom-8 flex items-center gap-x-6 md:!static [@media(min-height:650px)]:fixed'>
      {(next && prev) ? <LinkButton variant='tertiary' className='md:h-8 md:px-3' to='/dashboard'>{t('Skip tutorial')}</LinkButton> : null}
      <div className='ms-auto flex items-center gap-x-6'>
        {prev ? <LinkButton variant='tertiary' className='md:h-8 md:px-3' to={`/welcome/${prev}`}>{t('Back')}</LinkButton> : null}
        {next
          ? <LinkButton className='md:h-8 md:px-3' to={`/welcome/${next}`}>{t('Next')}</LinkButton>
          : <LinkButton className='md:h-8 md:px-3' to='/dashboard'>{t('Finish')}</LinkButton>}
      </div>
    </div>
  )
}

export default Controls
