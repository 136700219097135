import React from 'react'
import LinkButton from '../../components/LinkButton'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'

const OpenSessionButton = ({ session, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <LinkButton
      target='_blank'
      to={`/session/${session.id}${window.location.search}`}
      {...getTestId('open-session-button')}
      variant='primary'
      className={mergeClassNames('flex h-11 w-full gap-x-2 whitespace-nowrap text-center sm:h-9', className)}
      {...props}
    >
      {t('Open session')}
    </LinkButton>
  )
}

export default OpenSessionButton
