import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPageTitle } from '../modules/ui-state/UI.state'

export function usePageTitle (title) {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(setPageTitle(title))

    return () => dispatch(setPageTitle(''))
  }, [title, dispatch])
}
