import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { AuthContainer } from '../auth/AuthContainer'

const ZendeskLoginScreen = () => {
  const [subdomain, setSubdomain] = useState(window.localStorage?.zendeskSubdomain)
  const { t } = useTranslation()

  const updateSubdomain = (e) => {
    const newSubdomain = e.target.value

    setSubdomain(newSubdomain)

    try {
      window.localStorage.setItem('zendeskSubdomain', newSubdomain)
    } catch (e) {
      console.warn('Failed to set zendeskSubdomain in local storage', e)
    }
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      doLogin()
    }
  }

  const doLogin = () => {
    if (!subdomain) {
      return
    }

    const params = qs.parse(window.location.search.substring(1))
    const link = `/api/1/zendesk/auth?${qs.stringify({ ...params, subdomain })}`

    window.location.href = link
  }

  return (
    <AuthContainer className='size-full bg-white'>
      <AuthContainer.Body className='flex flex-col gap-y-4'>
        <img alt='Zendesk' className='h-5 self-start' src={require('./zendesk-logo.svg').default} />
        <div className='flex flex-col gap-y-4'>
          <div>
            <h1 className='mb-4 text-xl font-medium'>{t('Sign in with {{provider}}', { provider: 'Zendesk' })}</h1>
            <p className='text-sm text-gray-700'>{t('Enter your Zendesk subdomain to access your Zendesk-linked Cobrowse account.')}</p>
          </div>
          <div className='flex flex-col gap-y-4'>
            <div className='flex items-center text-gray-700'>
              <Input className='flex-1 has-[:focus]:!shadow-[inset_0_0_0_1px_theme(colors.gray.500)] hover:shadow-[inset_0_0_0_1px_theme(colors.gray.200)] focus:shadow-[inset_0_0_0_1px_theme(colors.gray.700)]' onKeyDown={onEnter} onChange={updateSubdomain} value={subdomain} type='text' />.zendesk.com
            </div>
            <Button className='justify-center bg-[#06615a] enabled:hover:bg-[#079080]' onClick={doLogin} disabled={!subdomain}>{t('Sign in with {{provider}}', { provider: 'Zendesk' })}</Button>
          </div>
        </div>
      </AuthContainer.Body>
    </AuthContainer>
  )
}

export default ZendeskLoginScreen
