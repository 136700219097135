import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AdminAccountList from './AdminAccountList'
import AccountSearch from './AccountSearch.state'
import User from '../users/User.state'
import FeatureSwitches from '../accounts/FeatureSwitches.state'

const AdminDashboard = () => {
  const accounts = useSelector(state => AccountSearch.fromState(state))
  const user = useSelector(state => User.fromState(state))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(AccountSearch.actionCreators().getAccountSearch())
  }, [dispatch])

  useEffect(() => {
    if (!user.resource.superuser) {
      navigate('/dashboard', { replace: true })
    }
  }, [user.resource.superuser, navigate])

  const refresh = (params = {}, options = {}) => {
    getAccountSearch({}, params, options)
  }

  const getAccountSearch = (resource = {}, params = {}, options = {}) => {
    dispatch(AccountSearch.actionCreators().getAccountSearch(resource, params, options))
  }

  const updateAccountSearch = (resource = {}, params = {}, options = {}) => {
    dispatch(AccountSearch.actionCreators().updateAccountSearch(resource, params, options))
  }

  const updateFeatureSwitches = (resource = {}, params = {}, options = {}) => {
    dispatch(FeatureSwitches.actionCreators().updateFeatureSwitches(resource, params, options))
  }

  return (
    <div className='flex h-full flex-col overflow-hidden'>
      <h1 className='border-b border-gray-200 py-3.5 ps-3 text-2xl font-medium md:border-b-0 md:pb-1.5 md:ps-4 md:pt-3.5'>Accounts</h1>
      <AdminAccountList
        accounts={accounts}
        user={user}
        getAccount={getAccountSearch}
        updateAccount={updateAccountSearch}
        updateFeatureSwitches={updateFeatureSwitches}
        refresh={refresh}
      />
    </div>
  )
}

export default AdminDashboard
