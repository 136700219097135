import React from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import LoginButtons from './LoginButtons'
import Link from '../../components/Link'
import { AuthContainer } from './AuthContainer'
import useDocumentTitle from '../../hooks/useDocumentTitle'

const LoginScreen = () => {
  const { redirectTo } = qs.parse(window.location.search.substring(1))
  const { t } = useTranslation()

  useDocumentTitle(t('Sign in'))

  return (
    <AuthContainer>
      {/* TODO: decide if we want to keep this */}
      {/* <a href='/'>
        <Panel className='absolute end-8 top-4 rounded-lg p-1 text-black md:end-12'>
          <Icon type='close' />
        </Panel>
      </a> */}
      <AuthContainer.Body>
        <div className='flex flex-col gap-y-4'>
          <h1 className='text-xl font-medium'>{t('Sign in to your account')}</h1>
          <LoginButtons query={{ redirectTo }} />
          <p className='text-xs text-gray-700'>{t("Don't have an account?")} <Link className='text-slate no-underline' to='/register'>{t('Create a new account')}</Link></p>
        </div>
      </AuthContainer.Body>
    </AuthContainer>
  )
}

export default LoginScreen
