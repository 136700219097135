import React, { useLayoutEffect } from 'react'
import SessionScreen from '../sessions/SessionScreen'
import { embedParamsOverrides } from '../../utils/EmbedParams'
import { useDispatch, useSelector } from 'react-redux'
import Session from '../sessions/Session.state'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import Button from '../../components/Button'

function isLoading (session) {
  if (session?.resource == null) return session.working

  return ['pending', 'authorizing'].includes(session.resource.state)
}

export function PresentSession () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  const session = useSelector(state => Session.fromState(state))

  useLayoutEffect(() => {
    embedParamsOverrides.overrides = {
      agent_tools: 'none',
      device_controls: 'none',
      end_action: 'none',
      popout: 'none',
      session_details: 'none'
    }

    return () => {
      embedParamsOverrides.overrides = {}
    }
  }, [dispatch, params])

  useLayoutEffect(() => {
    if (!session.state) dispatch(Session.actionCreators().getSession({ id: params.id }))
  }, [dispatch, params, session.state])

  dispatch(Session.actionCreators().subscribeSession())

  if (isLoading(session)) {
    return (
      <div className='flex size-full items-center justify-center bg-slate-900 text-slate-50'>
        <Loading>{t('Waiting for presentation to begin')}</Loading>
      </div>
    )
  }

  if (session.error !== false) {
    return (
      <div className='flex size-full flex-col items-center justify-center gap-y-4 bg-slate-900 text-slate-50'>
        <p>{t('An error occurred.')}</p>
        <Button onClick={() => window.location.reload()}>{t('Retry')}</Button>
      </div>
    )
  }

  if (session.resource.state === 'ended') {
    return (
      <div className='flex size-full flex-col items-center justify-center bg-slate-900 text-slate-50'>
        <h2 className='text-lg font-medium'>{t('This presentation has ended.')}</h2>
      </div>
    )
  }

  return (
    <div className='size-full bg-slate-900 p-4'>
      <SessionScreen />
    </div>
  )
}
