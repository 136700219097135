import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DateRangePicker from '../../components/DateRangePicker'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'

const SessionListDownloadFilters = ({ onDownload, thinking, error }) => {
  const presetDays = [30, 60, 90]
  const { t } = useTranslation()
  const [selectedDates, setSelectedDates] = useState([moment().subtract(presetDays[0], 'days').toDate(), moment().toDate()])
  const handleOnDownload = () => onDownload(selectedDates)
  const updateDateRange = (days) => setSelectedDates([moment().subtract(days, 'days').toDate(), moment().toDate()])

  return (
    <>
      <div className='mb-4 flex flex-col gap-y-4'>
        <div>
          <DateRangePicker inline onChange={setSelectedDates} selectedDates={selectedDates} />
        </div>
        <div className='mt-2 flex gap-x-2'>
          {presetDays.map((days) => (
            <Button
              variant='tertiary'
              isFullWidth
              onClick={() => updateDateRange(days)}
              key={days}
              size='small'
            >
              {t('{{days}} days', { days })}
            </Button>
          ))}
        </div>
      </div>
      <div className='grid items-center gap-4'>
        {error?.show && (
          <div className={mergeClassNames(error.severity === 'warning' && 'text-slate-900', error.severity === 'error' && 'text-red-500')}>
            {error.message}
          </div>
        )}
        <Button
          thinking={thinking}
          onClick={handleOnDownload}
          isFullWidth
          size='medium'
        >
          {t('Download')}
        </Button>
      </div>
    </>
  )
}

export default SessionListDownloadFilters
