import { useMemo } from 'react'
import parser from 'ua-parser-js'
import deviceNameFromModel from '../modules/devices/DeviceNames'
import { omit } from 'lodash'

export function useNormalisedDeviceInfo (device) {
  return useMemo(() => {
    const info = { ...device }

    delete info.app_installation_id

    if (info.platform === 'web') {
      const ua = parser(info.device)

      info.device = `${ua.os.name} / ${ua.browser.name}`
    } else if (info.device) {
      info.device = deviceNameFromModel(info.device)
    }

    return omit(info, [
      'push_enabled',
      'horizontal_accuracy',
      'vertical_accuracy',
      'latitude',
      'longitude',
      'altitude',
      'video_codecs'
    ])
  }, [device])
}
