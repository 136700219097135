import React, { useEffect, useRef, useState, useLayoutEffect, Fragment, useCallback } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import mergeClassNames from '../../utils/mergeClassNames'
import { FocusableInput } from '../../components/FocusableInput'
import { autoUpdate, hide, shift, useFloating } from '@floating-ui/react-dom'
import { getTestId } from '../../utils/getTestId'

export function FilterTag ({
  label,
  field,
  filterContainerRef,
  suggestions = [],
  commitFilter,
  value = '',
  onUpdate,
  removeTag
}) {
  const [isDirty, setIsDirty] = useState(value !== '')
  const [isFocused, setIsFocused] = useState(false)
  const { refs, floatingStyles, middlewareData } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [shift(), hide()]
  })

  const inputRef = useRef(null)
  const containerRef = useRef(null)
  const sizeRef = useRef(null)

  const timeoutId = useRef(null)

  const filteredSuggestions = suggestions.filter(suggestion => suggestion.includes(value))

  useLayoutEffect(() => {
    setIsDirty(value !== '')

    sizeRef.current.textContent = value
    inputRef.current.style.width = `${sizeRef.current.offsetWidth}px`
  }, [value])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [])

  const onChangeOption = useCallback((val) => {
    if (val === null) return
    onUpdate(field, val)
    commitFilter()
  }, [field, onUpdate, commitFilter])

  const onBlur = useCallback(() => {
    commitFilter()

    if (value === '') removeTag(field)
  }, [commitFilter, removeTag, field, value])

  const onIconClick = (evt) => {
    if (isDirty) {
      evt.stopPropagation()
    }

    inputRef.current.style.transitionProperty = 'width'

    onUpdate(field, '')
    commitFilter()
    setIsDirty(false)
    inputRef.current.focus()

    timeoutId.current = setTimeout(() => {
      inputRef.current.style.transitionProperty = undefined
    }, 150)
  }

  const onInputChange = (evt) => {
    setIsDirty(evt.target.value !== '')
    onUpdate(field, evt.target.value)

    if (evt.target.value === '') {
      commitFilter()
    }
  }

  const onInputFocus = () => {
    setIsFocused(true)
  }
  const onInputBlur = () => {
    setIsFocused(false)
  }

  return (
    <Combobox
      as='div'
      nullable
      onChange={onChangeOption}
      ref={(elem) => {
        containerRef.current = elem
        refs.setReference(elem)
      }}
      onClick={() => inputRef.current.focus()}
      onBlurCapture={onBlur}
      value={value}
      {...getTestId('filter-tag')}
      className='h-8'
    >
      <Combobox.Input
        as={FocusableInput}
        ref={inputRef}
        autoComplete='off'
        icon='plus'
        label={label}
        value={value}
        className={mergeClassNames('h-8 w-max min-w-max flex-1 cursor-pointer flex-nowrap overflow-hidden py-0', isDirty && 'bg-violet-50')}
        inputClassName={mergeClassNames('w-1 min-w-1 text-violet transition-none duration-150 ease-in-out motion-reduce:transition-none', !isFocused && 'cursor-pointer')}
        iconClassName={mergeClassNames('text-gray-700 transition-all motion-reduce:transition-none', isDirty && 'rotate-45', isFocused && !isDirty && 'scale-x-0 opacity-0')}
        labelClassName={mergeClassNames('cursor-pointer', isDirty && 'text-slate-900')}
        inputWrapperClassName={mergeClassNames('gap-x-0', isDirty && 'gap-x-2')}
        iconSize='small'
        onChange={onInputChange}
        iconOnClick={onIconClick}
        focusableElementRef={containerRef}
        scrollableContainerRef={filterContainerRef}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onEnter={() => {
          if (filteredSuggestions.length === 0) {
            commitFilter()
          }
        }}
        {...getTestId(`filter-${field}`)}

      />

      <Transition
        as={Fragment}
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Combobox.Options
          className='absolute mt-1 max-h-32 overflow-auto rounded-md bg-white p-2 text-xs shadow-lg ring-1 ring-black/5 empty:hidden focus:outline-none'
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            visibility: middlewareData.hide?.referenceHidden
              ? 'hidden'
              : 'visible'
          }}
        >
          {filteredSuggestions.length > 0 && (
            <Combobox.Option
              key={value}
              className='invisible relative max-h-0 select-none'
              value={value}
            >
              <span className='block truncate font-normal ui-selected:font-medium'>
                {value}
              </span>
            </Combobox.Option>
          )}
          {filteredSuggestions.map((suggestion) => (
            <Combobox.Option
              key={suggestion}
              className='relative cursor-pointer select-none rounded-md px-4 py-2 ui-active:bg-slate-100'
              value={suggestion}
              {...getTestId('filter-tag-option')}
            >
              <span
                className='block truncate font-normal ui-selected:font-medium'
              >
                {suggestion}
              </span>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Transition>
      <span className='invisible h-0 max-h-0' ref={sizeRef} />
    </Combobox>
  )
}
