import React from 'react'
import { useTranslation } from 'react-i18next'
import SDKButtons from '../../components/SDKButtons'
import CopyText from '../../components/CopyText'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import { Icon } from '../../components/Icon'

const EmptyDeviceList = () => {
  const account = useActiveAccountSelector()
  const { t } = useTranslation()

  return (
    <div className='flex h-full flex-col gap-8 px-3 py-4 md:px-20 md:py-12'>
      <div className='flex h-full flex-col items-center justify-center gap-y-3'>
        <div className='flex flex-col items-center gap-y-2 text-center'>
          <div className='rounded-full border border-gray-200 p-3'>
            <Icon type='device-mobile-search' size='large' />
          </div>
          <h2 className='text-xl font-medium md:text-2xl'>{t("We haven't seen any of your devices yet")}</h2>
          <p className='text-sm font-medium text-gray-700'>{t('Have you added our SDKs to your app or website?')}</p>
        </div>
        <div className='flex flex-wrap items-center justify-center gap-x-2 rounded px-3 py-2 text-base text-slate-900'>
          <CopyText
            label={t("You'll need your license key:")}
            className='text-sm font-medium'
          >
            {account.resource.license_key}
          </CopyText>
        </div>
      </div>
      <div className='flex flex-col items-center gap-y-3'>
        <p className='text-sm text-gray-700'>{t('Need help setting up? Read docs for:')}</p>
        <SDKButtons />
      </div>
    </div>
  )
}

export default EmptyDeviceList
