import { combineReducers } from 'redux'
import Session from './sessions/Session.state'
import User from './users/User.state'
import Sockets from './websockets/Sockets.state'
import Displays from './sessions/Displays.state'
import Screens from './screens/Screens.state'
import Mouse from './sessions/Mouse.state'
import SessionConfig from './sessions/SessionConfig.state'
import UI from './ui-state/UI.state'
import Connectivity from './sessions/Connectivity.state'
import Device from './devices/Device.state'
import Presence from './devices/Presence.state'
import Account from './accounts/Account.state'
import JWTSSOConfig from './accounts/JWTSSOConfig.state'
import AccountMember from './accounts/AccountMember.state'
import MemberLicense from './accounts/MemberLicense.state'
import JoinToken from './accounts/JoinToken.state'
import Subscription from './billing/Subscription.state'
import PaymentSource from './billing/PaymentSource.state'
import StripeCheckout from './billing/StripeCheckout.state'
import Invoices from './billing/Invoices.state'
import FirebaseConfig from './push-notifications/FirebaseConfig.state'
import PushNotification from './push-notifications/PushNotification.state'
import IntercomConfig from './intercom/IntercomConfig.state'
import SalesforceConfig from './salesforce/SalesforceConfig.state'
import ZendeskConfig from './zendesk/ZendeskConfig.state'
import FeatureSwitches from './accounts/FeatureSwitches.state'
import RedactionConfig from './redaction/RedactionConfig.state'
import SessionAction from './recordings/SessionAction.state'
import SessionRecording from './recordings/SessionRecording.state'
import SAMLProviderConfig from './saml/SAMLProviderConfig.state'
import AuditEvent from './audit/AuditEvent.state'
import MagicLink from './auth/MagicLink.state'
import Config from './admin/Config.state'
import AccountSearch from './admin/AccountSearch.state'
import Presentation from './present/Presentation.state'
import EnterpriseLicense from './admin/EnterpriseLicense.state'
import AllowedRegions from './accounts/AllowedRegions.state'
import TalkdeskConfig from './talkdesk/TalkdeskConfig.state'
import NiceConfig from './nice/NiceConfig.state'
import TrustedOrigins from './post-message/TrustedOrigins.state'
import Error from './errors/Error.state'

export default combineReducers({
  [Session.statePath()]: Session.reducer(),
  [Device.statePath()]: Device.reducer(),
  [User.statePath()]: User.reducer(),
  [Account.statePath()]: Account.reducer(),
  [AccountSearch.statePath()]: AccountSearch.reducer(),
  [AccountMember.statePath()]: AccountMember.reducer(),
  [JoinToken.statePath()]: JoinToken.reducer(),
  [Subscription.statePath()]: Subscription.reducer(),
  [Invoices.statePath()]: Invoices.reducer(),
  [PaymentSource.statePath()]: PaymentSource.reducer(),
  [StripeCheckout.statePath()]: StripeCheckout.reducer(),
  [FirebaseConfig.statePath()]: FirebaseConfig.reducer(),
  [IntercomConfig.statePath()]: IntercomConfig.reducer(),
  [ZendeskConfig.statePath()]: ZendeskConfig.reducer(),
  [FeatureSwitches.statePath()]: FeatureSwitches.reducer(),
  [RedactionConfig.statePath()]: RedactionConfig.reducer(),
  [JWTSSOConfig.statePath()]: JWTSSOConfig.reducer(),
  [SalesforceConfig.statePath()]: SalesforceConfig.reducer(),
  [AuditEvent.statePath()]: AuditEvent.reducer(),
  [PushNotification.statePath()]: PushNotification.reducer(),
  [MagicLink.statePath()]: MagicLink.reducer(),
  [SessionConfig.statePath()]: SessionConfig.reducer(),
  [SessionAction.statePath()]: SessionAction.reducer(),
  [MemberLicense.statePath()]: MemberLicense.reducer(),
  [SessionRecording.statePath()]: SessionRecording.reducer(),
  [Config.statePath()]: Config.reducer(),
  [SAMLProviderConfig.statePath()]: SAMLProviderConfig.reducer(),
  [Presentation.statePath()]: Presentation.reducer(),
  [EnterpriseLicense.statePath()]: EnterpriseLicense.reducer(),
  [AllowedRegions.statePath()]: AllowedRegions.reducer(),
  [TalkdeskConfig.statePath()]: TalkdeskConfig.reducer(),
  [NiceConfig.statePath()]: NiceConfig.reducer(),
  [TrustedOrigins.statePath()]: TrustedOrigins.reducer(),
  sockets: Sockets,
  displays: Displays,
  screen: Screens,
  ui: UI,
  connectivity: Connectivity,
  presence: Presence,
  mouse: Mouse,
  error: Error
})
