import RESTObject from '../redux-rest'

export default class NiceConfig extends RESTObject {
  static namespace () {
    return 'NiceConfig'
  }

  static url () {
    return '/api/1/nice/configuration'
  }
}
