import React, { useEffect, useState } from 'react'
import DOMScreen from './DOMScreen'
import ImageScreen from './ImageScreen'
import VideoScreen from './VideoScreenJMuxer'
import mergeClassNames from '../../utils/mergeClassNames'
import { getTestId } from '../../utils/getTestId'
import { Icon } from '../../components/Icon'

const RemoteScreen = (props) => {
  const [animationCount, setAnimationCount] = useState(0)

  useEffect(() => {
    if (props.mouse?.state === 'mousedown') {
      setAnimationCount((prev) => prev + 1)
    }
  }, [props.mouse?.state])

  const renderDisplay = (display) => {
    const mimeType = display && display.mime_type
    if (!mimeType) {
      return null
    } else if (mimeType.startsWith('image/')) {
      return <ImageScreen {...props} />
    } else if (mimeType.startsWith('video/')) {
      return <VideoScreen {...props} />
    } else if (mimeType === 'application/x-cbio-vdom-patch') {
      return <DOMScreen {...props} />
    } else {
      return null
    }
  }

  const renderMouse = () => {
    const { mouse } = props

    if (!mouse) {
      return null
    }

    const style = {
      top: `${mouse.y * 100}%`,
      left: `${mouse.x * 100}%`
    }

    const mouseStateClassNames = {
      mouseout: 'hidden'
    }

    return (
      <div
        className={mergeClassNames('pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 text-[#3c4044]', mouseStateClassNames[mouse.state])}
        style={style}
        {...getTestId('remote-screen-mouse')}
      >
        <div key={animationCount} className={mergeClassNames('absolute -left-1 -top-1 size-0 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#3c4044]', animationCount > 0 && 'animate-mouseclick')} />
        <Icon type='mouse-pointer' size='large' className='absolute -left-1 z-50' />
      </div>
    )
  }

  return (
    <div className='relative size-full'>
      {renderDisplay(props.display)}
      {renderMouse()}
    </div>
  )
}

export default RemoteScreen
