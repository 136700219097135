import * as Sentry from '@sentry/react'

if (process.env.REACT_APP_INCLUDE_SENTRY === 'true') {
  const { version } = require('../../../package.json')
  const release = `${version}${process.env.NODE_ENV === 'development' ? '-dev' : ''}`

  Sentry.init({
    dsn: 'https://cc46f65d32c94f31af3d8b03d90beef9@o4504984568791040.ingest.sentry.io/4505133179600896',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.2, // capture 20% of transactions - we might want to tweak this based on the production data
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release,
    environment: window.location.hostname.includes('staging') ? 'staging' : 'production'
  })
}
