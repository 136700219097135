import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { usePageTitle } from '../hooks/usePageTitle'
import User from '../modules/users/User.state'
import Account from '../modules/accounts/Account.state'
import useActiveAccountSelector from '../hooks/useActiveAccountSelector'
import LoadingScreen from '../components/LoadingScreen'
import ErrorMessage from '../modules/errors/ErrorMessage'
import SettingsMenu from '../modules/accounts/SettingsMenu'
import useDocumentTitle from '../hooks/useDocumentTitle'

export function SettingsLayout () {
  const dispatch = useDispatch()
  const user = useSelector(state => User.fromState(state))
  const account = useActiveAccountSelector()
  const { t } = useTranslation()
  const location = useLocation()

  const menuItems = useMemo(() => [
    {
      title: t('General'),
      path: '/dashboard/settings'
    },
    {
      title: t('Team'),
      feature: 'manage_members',
      path: '/dashboard/settings/team'
    },
    {
      title: t('Redaction'),
      feature: 'manage_redaction',
      path: '/dashboard/settings/redaction'
    },
    {
      title: t('Push notifications'),
      feature: 'manage_push_settings',
      path: '/dashboard/settings/push'
    },
    {
      title: t('Integrations'),
      feature: 'manage_integrations',
      path: '/dashboard/settings/integrations'
    },
    {
      title: t('Billing'),
      feature: 'manage_billing',
      path: '/dashboard/settings/billing',
      targetIfIframe: '_blank'
    },
    {
      title: t('Audit trail'),
      feature: 'view_audit_trail',
      path: '/dashboard/settings/audit'
    }
  ], [t])

  const currentMenuItem = menuItems.find(item => location.pathname === item.path)
  usePageTitle(t('Settings'))
  useDocumentTitle(currentMenuItem?.title)

  useEffect(() => {
    dispatch(Account.actionCreators().getAccounts())
  }, [dispatch])

  if ((user.working && (!user.resource)) ||
    (account.working && (!account.resource))) {
    return <LoadingScreen />
  }

  if (!account.resource) {
    return <ErrorMessage error={account.error}>{t('Sorry, something went wrong loading your account.')}</ErrorMessage>
  }

  return (
    <>
      <SettingsMenu items={menuItems} currentItem={currentMenuItem} />
      <div className='flex w-full flex-col gap-y-4 lg:max-w-[1140px]'>
        <Outlet />
      </div>
    </>
  )
}
