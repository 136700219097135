import React from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'

const StripeSubscription = ({ account, user, updateStripeSubscription, handleKeyDown }) => {
  if (!user.resource.superuser) {
    return null
  }

  return (
    <div className='flex flex-col gap-y-2'>
      <div className='flex flex-col gap-y-1'>
        <Label htmlFor='customer-id'>Customer</Label>
        <Input
          id='customer-id'
          type='text'
          defaultValue={account.stripe.customer || ''}
          disabled
          readOnly
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label htmlFor='subscription'>Subscription</Label>
        <Input
          id='subscription'
          onBlur={(e) => updateStripeSubscription(account, e.target.value)}
          onKeyDown={handleKeyDown}
          type='text'
          defaultValue={account.stripe.subscription || ''}
        />
      </div>
    </div>
  )
}

export default StripeSubscription
