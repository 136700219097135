import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CodeEntry from '../sessions/CodeEntry'
import DeviceList from '../devices/DeviceList'
import Device from './Device.state'
import Account from '../accounts/Account.state'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import useGetDevicePresence from '../../hooks/useGetDevicePresence'
import { usePageTitle } from '../../hooks/usePageTitle'
import { useDeviceSelector } from '../../hooks/useDeviceSelector'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { buttonClassName } from '../../components/Button'
import { NavLink } from 'react-router-dom'
import { Icon } from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'

const DevicesDashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const account = useActiveAccountSelector().resource
  const devices = useDeviceSelector()

  const getPresence = useGetDevicePresence()

  usePageTitle(t('Connect'))
  useDocumentTitle(t('Connect'))

  const refresh = useCallback((params = {}, options = {}) => {
    return dispatch(Device.actionCreators().getDevices(undefined, params, options))
  }, [dispatch])

  if (!Account.hasFeature(account, 'device_listing', true)) {
    return (
      <>
        <div className='w-full border-gray-200 md:border-b' />
        <div className='grid h-[calc(100%-59px)] items-center justify-center text-center'>
          <div className='flex w-[280px] flex-col gap-9'>
            <div className='grid w-full gap-4'>
              <CodeEntry />
              <div className='px-6 text-sm'>{t('Enter a session code from the customer')}</div>
            </div>
            {Account.hasFeature(account, 'present_mode', true) && (
              <>
                <div className='relative w-full'>
                  <div className='border-b border-gray-200' />
                  <span className='absolute -top-2.5 -translate-x-1/2 bg-white px-3 text-gray-700'>{t('or')}</span>
                </div>
                <NavLink
                  className={buttonClassName({ variant: 'tertiary', isFullWidth: true })}
                  to='/dashboard/present'
                  {...getTestId('navigation-present')}
                >
                  <Icon type='slideshare' />
                  <span>{t('Start presenting')}</span>
                </NavLink>
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <DeviceList
      account={account}
      devices={devices}
      getPresence={getPresence}
      refresh={refresh}
    />
  )
}

export default DevicesDashboard
