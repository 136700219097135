import React, { forwardRef } from 'react'
import Button from './Button'

const ButtonLink = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <Button ref={ref} variant='plain' className={className} {...props}>
      {children}
    </Button>
  )
})

export default ButtonLink
