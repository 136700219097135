export const SET_GLOBAL_ERROR = 'global_error/SET'
export const RESET_GLOBAL_ERROR = 'global_error/RESET'
export const ADD_DOM_ERROR = 'dom_error/ADD'
export const RESET_DOM_ERROR = 'dom_error/RESET'

const initialState = { }

export default function error (state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return { ...state, global: action.state }
    case RESET_GLOBAL_ERROR:
      return { ...state, global: {} }
    case ADD_DOM_ERROR:
      // TODO: Build a UI for this
      console.warn('DOMError:', action.state.message)
      return { ...state, dom: [action.state, ...state.dom || []] }
    case RESET_DOM_ERROR:
      return { ...state, dom: [] }
    default:
      return state
  }
}

export const setGlobalError = (state) => {
  return {
    type: SET_GLOBAL_ERROR,
    state
  }
}

export const resetGlobalError = () => {
  return {
    type: RESET_GLOBAL_ERROR
  }
}

export const addDOMError = (state) => {
  return {
    type: ADD_DOM_ERROR,
    state
  }
}

export const resetDOMError = () => {
  return {
    type: RESET_DOM_ERROR
  }
}
