import React from 'react'
import Input from '../../components/Input'
import Select from '../../components/Select'
import SelectOption from '../../components/SelectOption'

const ListControls = ({ filter, onFilterUpdated, onSortUpdated }) => {
  return (
    <div className='flex gap-x-3 border-b border-gray-200 p-3 md:px-4'>
      <Input
        value={filter || ''}
        type='text'
        placeholder='Search Accounts...'
        onChange={e => onFilterUpdated(e.target.value)}
        className='max-w-[300px] flex-1'
      />
      <Select value='' onChange={e => onSortUpdated(e.target.value)} className='w-[94px] text-gray-700'>
        <SelectOption disabled value=''>Sort By</SelectOption>
        <SelectOption value='members' className='truncate'>Members</SelectOption>
        <SelectOption value='devices_active' className='truncate'>Active Devices</SelectOption>
        <SelectOption value='created' className='truncate'>Created</SelectOption>
      </Select>
    </div>
  )
}

export default ListControls
