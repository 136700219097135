import React from 'react'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import LoginButtons from './LoginButtons'
import { AuthContainer } from './AuthContainer'
import useDocumentTitle from '../../hooks/useDocumentTitle'

const RegisterScreen = () => {
  const { t } = useTranslation()
  const { redirectTo } = qs.parse(window.location.search.substring(1))

  useDocumentTitle(t('Create a new account'))

  return (
    <>
      <AuthContainer>
        {/* TODO: decide if we want to keep this */}
        {/* <a href='/'>
          <Panel className='absolute end-8 top-4 rounded-lg p-1 text-black md:end-12'>
            <Icon type='close' />
          </Panel>
        </a> */}
        <AuthContainer.Body>
          <div className='flex flex-col gap-y-4'>
            <h1 className='text-xl font-medium'>{t('Create a new account')}</h1>
            <LoginButtons register query={{ redirectTo }} />
          </div>
        </AuthContainer.Body>
      </AuthContainer>
    </>
  )
}

export default RegisterScreen
