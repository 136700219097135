import React from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'

const Value = ({ account, user, field, currentValueField, float = false, updateLimit, handleKeyDown }) => {
  if (!account.limits) {
    return <span className='text-slate'>Unlimited</span>
  }

  if (!user.resource.superuser) {
    return <span className='text-slate'>{account[currentValueField] || 'Unlimited'}</span>
  }

  return (
    <Input
      id={field}
      onBlur={(e) => updateLimit(account, field, float ? parseFloat(e.target.value) : parseInt(e.target.value, 10))}
      onKeyDown={handleKeyDown}
      type='number'
      defaultValue={account.limits[field]}
    />
  )
}

const LimitEditor = ({ account, user, label, field, currentValueField, float = false, updateLimit, handleKeyDown }) => {
  return (
    <div className='mt-3 flex flex-col'>
      <Label htmlFor={field} className='flex items-center gap-x-1'>
        <span>{label}</span>
        {currentValueField && (
          <>
            <span className='text-slate'>{account[currentValueField]}</span>
            <span>of</span>
          </>
        )}
      </Label>
      <Value
        account={account}
        user={user}
        field={field}
        currentValueField={currentValueField}
        float={float}
        updateLimit={updateLimit}
        handleKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default LimitEditor
