import React from 'react'
import { Disclosure } from '@headlessui/react'
import { Icon } from './Icon'
import mergeClassNames from '../utils/mergeClassNames'

const Collapsible = ({ title, openByDefault = true, className, children }) => {
  return (
    <Disclosure as='div' className={className} defaultOpen={openByDefault}>
      <Disclosure.Button className='flex items-center gap-x-1 p-0 text-sm'>
        {title}
        <Icon
          type='chevron-up'
          className={mergeClassNames(
            'rotate-90 transition-transform duration-200 ui-open:rotate-180'
          )}
          size='small'
        />
      </Disclosure.Button>
      <Disclosure.Panel>
        {children}
      </Disclosure.Panel>
    </Disclosure>
  )
}

export default Collapsible
