import React from 'react'
import LinkButton from '../../components/LinkButton'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import { useIsDeviceConnectable } from '../../hooks/useDevicePresence'

const ConnectButton = ({ device, className, ...props }) => {
  const { t } = useTranslation()
  const isConnectable = useIsDeviceConnectable(device)

  if (device == null) return null

  const deviceId = device.id

  return (
    <LinkButton
      to={`/connect/device/${deviceId}${window.location.search}`}
      variant='primary'
      className={mergeClassNames('h-11 w-full text-center sm:h-9', className)}
      disabled={!isConnectable}
      {...getTestId('connect-button')}
      {...props}
      isFullWidth
    >
      {t('Connect')}
    </LinkButton>
  )
}

export default ConnectButton
