import React, { useState } from 'react'
import Button from './Button'
import { Icon } from './Icon'
import Input from './Input'
import TextArea from './TextArea'
import mergeClassNames from '../utils/mergeClassNames'

const RemoveButton = ({ onClick }) => (
  <Button
    variant='plain'
    title='remove'
    onClick={onClick}
  >
    <Icon
      type='trash-empty'
      size='large'
      className='p-1 opacity-50 transition-all ease-in'
    />
  </Button>
)

const ArrayMapEditor = ({ arrayMap, onArrayMapChange, keyPlaceHolder = '', valuePlaceHolder = '', editOnlyKeys = [] }) => {
  const editOnly = editOnlyKeys.length > 0
  const arrayMapEntries = Object.entries(arrayMap || {})
  const stringMap = arrayMapEntries.map(([key, value]) => ({ key, value: value.join('\n') }))
  const [items, setItems] = useState(stringMap)

  const handleAdd = () => {
    setItems([...items, { key: '', value: '' }])
  }

  const handleRemove = (idx) => {
    if (!window.confirm('Are you sure you want to remove this content type?')) return
    setItems([...items.slice(0, idx), ...items.slice(idx + 1)])
    updateValue()
  }

  const handleKeyChange = (idx, e) => {
    const newItems = items.map((item, index) => index === idx ? { ...item, key: e.target.value } : item)
    setItems(newItems)
  }

  const handleValueChange = (idx, e) => {
    const newItems = items.map((item, index) => index === idx ? { ...item, value: e.target.value } : item)
    setItems(newItems)
  }

  const updateValue = () => {
    if (!onArrayMapChange) return
    const arrayMapEntries = items
      .map(({ key, value }) => [
        key.trim(),
        value.trim().split('\n').map(line => line.trim()).filter(Boolean)
      ])
      .filter(([key]) => key)
    const arrayMap = Object.fromEntries(arrayMapEntries)
    onArrayMapChange?.(arrayMap)
  }

  const shouldDisplay = (key) => {
    if (!editOnly) return true
    return editOnlyKeys.includes(key)
  }

  return (
    <div>
      {items.map((item, idx) => (
        <div key={item.key} className={mergeClassNames('mb-1.5 flex flex-col items-start gap-y-2', shouldDisplay(item.key) ? 'flex' : 'hidden')}>
          <div className='flex items-center'>
            <Input
              type='text'
              disabled={editOnly}
              placeholder={keyPlaceHolder}
              value={item.key}
              onChange={(e) => handleKeyChange(idx, e)}
              onBlur={() => updateValue()}
            />
            {!editOnly && <RemoveButton onClick={() => handleRemove(idx)} />}
          </div>
          <TextArea
            rows={3}
            placeholder={valuePlaceHolder}
            value={item.value}
            onChange={(e) => handleValueChange(idx, e)}
            onBlur={() => updateValue()}
          />
        </div>
      ))}
      {!editOnly && <Button onClick={handleAdd}>Add</Button>}
    </div>
  )
}

export default ArrayMapEditor
