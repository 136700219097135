import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { SettingsGroup } from '../../components/SettingsGroup'
import SessionConfig from '../sessions/SessionConfig.state'
import { Icon } from '../../components/Icon'
import Button from '../../components/Button'
import { baseColors, getAvailableColors } from '../sessions/colorPicker'
import { isEqual } from 'lodash'
import { Transition } from '@headlessui/react'
import { SettingsColorRow } from '../../components/SettingsColorRow'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const maxColors = 8

export function AnnotationSettings () {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const sessionConfig = useSelector(state => SessionConfig.fromState(state))

  const baseId = useId()
  const counter = useRef(0)

  const getColorsArray = useCallback((newColors) => {
    return getAvailableColors(newColors).map((color) => {
      const id = `${baseId}-${counter.current}`
      counter.current++

      return {
        id,
        color
      }
    })
  }, [baseId])

  const [colors, setColors] = useState(getColorsArray(sessionConfig.resource?.colours))

  const isDirty = useRef(false)
  const inProgressAction = useRef(null)

  useEffect(() => {
    inProgressAction.current = null
    if (!isDirty.current) {
      setColors(getColorsArray(sessionConfig.resource?.colours))
    }
  }, [sessionConfig.resource, getColorsArray])

  const isValidHex = (hex) => /^#[0-9A-F]{6}$/i.test(hex)

  const getErrorForColor = (newColor) => {
    if (!isValidHex(newColor)) {
      return t('Invalid hex color. Example: #FFFFFF')
    }

    const duplicates = colors.filter(({ color }) => color === newColor)
    if (duplicates.length > 1) {
      return t('Duplicate color')
    }
  }

  const updateColor = (index, color) => {
    setColors((prev) => {
      const newColors = [...prev]
      newColors[index].color = color
      newColors[index].error = getErrorForColor(color)

      newColors.forEach((colorObj) => {
        if (colorObj.error) {
          // if there was a duplicate colorObj and we change the original input
          // which did not contain an error, we need to clear it on the one that does
          colorObj.error = getErrorForColor(colorObj.color)
        }
      })

      return newColors
    })

    isDirty.current = true
  }

  const onRemove = (index) => {
    setColors((prev) => prev.filter((_, i) => i !== index))
    isDirty.current = true
  }

  const updateServer = (action) => {
    dispatch(SessionConfig.actionCreators().updateSessionConfig({
      colours: action === 'reset' ? null : colors.map(({ color }) => color)
    }))
    inProgressAction.current = action
    isDirty.current = false
  }

  const addRow = () => {
    setColors([...colors, { color: '', id: `${baseId}-${counter.current}`, animateOnMount: true }])

    counter.current++
    isDirty.current = true
  }

  const hasChanged = useMemo(() => {
    const colorsArr = colors.map(({ color }) => color)

    return isDirty.current &&
      colorsArr.find((color) => !isValidHex(color)) === undefined &&
      !(isEqual(sessionConfig.resource?.colours, colorsArr) || isEqual(baseColors, colorsArr))
  }, [sessionConfig.resource, colors])

  // if colors are undefined, the server version doesn't support it
  if (!sessionConfig.resource || sessionConfig.resource.colours === undefined) return null

  return (
    <SettingsGroup>
      <SettingsGroup.Title>{t('Annotation settings')}</SettingsGroup.Title>

      <SettingsGroup.HelpText>
        {t('Customize the colors available for the cobrowse annotations tools. You can specify between one and {{ maxColors }} colors for your agents to pick from.', { maxColors })}
        <Button
          variant='plain'
          size='small'
          className='mt-2 flex items-center gap-x-2 px-0 hover:text-slate-900'
          onClick={() => updateServer('reset')}
        >
          <Icon type='refresh' size='small' spin={inProgressAction.current === 'reset' && sessionConfig.working} />
          {t('Restore defaults')}
        </Button>
      </SettingsGroup.HelpText>

      <SettingsGroup.SaveButton
        thinking={inProgressAction.current === 'save' && sessionConfig.working}
        onClick={() => updateServer('save')}
        disabled={!hasChanged}
      />

      <SettingsGroup.Body className='flex flex-col'>
        {colors.map(({ id, color, error, ...props }, index) => (
          <SettingsColorRow
            key={id}
            color={color}
            onChange={(newColor) => updateColor(index, newColor)}
            onRemove={() => onRemove(index)}
            error={error}
            disableRemoving={colors.length <= 1}
            {...props}
          />
        ))}

        <Transition
          show={colors.length < maxColors}
          className='transition-opacity duration-200 motion-reduce:transition-none'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Button
            variant='text'
            size='medium'
            onClick={addRow}
            isFullWidth
          >
            <Icon type='plus' />
            {t('Add color')}
          </Button>
        </Transition>
      </SettingsGroup.Body>
    </SettingsGroup>
  )
}
