import React, { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import CopyText from '../../components/CopyText'
import SelectOption from '../../components/SelectOption'
import Select from '../../components/Select'
import Timestamp from '../../components/Timestamp'
import Alert from '../../components/Alert'
import mergeClassNames from '../../utils/mergeClassNames'

export function GenerateJoinLink ({ createJoinToken, defaultRole = 'agent', className, formClassName, buttonClassName }) {
  const [role, setRole] = useState(defaultRole)
  const [hasFailed, setHasFailed] = useState(false)
  const [joinTokenResponse, setJoinTokenResponse] = useState(null)
  const selectId = useId()
  const { t } = useTranslation()

  const getJoinToken = () => {
    setHasFailed(false)
    createJoinToken({ role })
      .then((res) => {
        if (res.statusCode != null && res.statusCode !== 200) {
          setHasFailed(true)
          return
        }

        setJoinTokenResponse(res)
      }).catch((err) => {
        console.debug('Failed to create join_token', err)
        // display an error to the user
        setHasFailed(true)
      })
  }

  return (
    <div className={mergeClassNames('flex flex-col gap-y-6', className)}>
      <div className={mergeClassNames('flex flex-row gap-6', formClassName)}>
        <label htmlFor={selectId} className='sr-only'>{t('Role')}</label>
        <Select
          id={selectId}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className='w-full'
        >
          <SelectOption value='agent'>{t('Support agent')}</SelectOption>
          <SelectOption value='administrator'>{t('Administrator')}</SelectOption>
        </Select>
        <Button onClick={getJoinToken} className={mergeClassNames('min-w-fit md:h-9', buttonClassName)}>{t('Generate join link')}</Button>
      </div>
      {hasFailed && <Alert type='alert'>{t('Failed to create join link. Please try again.')}</Alert>}
      {joinTokenResponse !== null && (
        <div className='flex flex-col gap-y-1'>
          <div className='flex items-center'>
            <div className='w-full rounded-s-none'>
              <CopyText
                label={joinTokenResponse.role === 'agent' ? t('Support agent') : t('Administrator')}
                aria-label={t('Role')}
              >
                {window.location.origin}/join/{joinTokenResponse.join_token}
              </CopyText>
            </div>
          </div>
          <Alert type='warning' icon='warning' variant='inline' className='text-gray-700'>
            {t('This join link will expire')}{' '}<Timestamp timestamp={joinTokenResponse.expires} />.
          </Alert>
        </div>
      )}
    </div>
  )
}
