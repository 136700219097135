import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const PopoverContainer = forwardRef(({ children, className, arrowClassName, show, arrowPosition = 'right', hideArrow = false, disableTransition = false, ...props }, ref) => {
  return (
    <div className={mergeClassNames('absolute z-20 shadow-menus', !disableTransition && 'opacity-100 transition-all duration-150 ease-in-out motion-reduce:transition-none', !show && 'invisible opacity-0', className)} {...props} ref={ref}>
      {!hideArrow && (
        <div className={mergeClassNames('absolute -top-1 end-3.5 size-3 rotate-45 rounded-sm bg-white sm:max-md:end-2.5', arrowPosition === 'left' && 'start-3.5', arrowClassName)} />
      )}
      {children}
    </div>
  )
})

export default PopoverContainer
