import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Dialog from '../../components/Dialog'
import CopyText from '../../components/CopyText'
import Input from '../../components/Input'
import Switch from '../../components/Switch'
import TextArea from '../../components/TextArea'
import JWTSSOConfig from './JWTSSOConfig.state'
import IntercomConfig from '../intercom/IntercomConfig.state'
import ZendeskConfig from '../zendesk/ZendeskConfig.state'
import SalesforceConfig from '../salesforce/SalesforceConfig.state'
import SAMLProviderConfig from '../saml/SAMLProviderConfig.state'
import TalkdeskConfig from '../talkdesk/TalkdeskConfig.state'
import NiceConfig from '../nice/NiceConfig.state'
import createRSAKeyPair from '../../utils/createRSAKeyPair'
import { SettingsGroup } from '../../components/SettingsGroup'
import { SettingsContainer } from '../../components/SettingsContainer'
import Label from '../../components/Label'
import Button from '../../components/Button'
import mergeClassNames from '../../utils/mergeClassNames'
import { Icon } from '../../components/Icon'
import Alert from '../../components/Alert'
import TrustedOrigins from '../post-message/TrustedOrigins.state'

const IntegrationSettings = () => {
  const [jwtPublicKey, setJwtPublicKey] = useState(false)
  const [salesforceRecordMapping, setSalesforceRecordMapping] = useState(false)
  const [trustedOrigins, setTrustedOrigins] = useState(false)
  const [samlCertificate, setSamlCertificate] = useState(false)
  const [samlEntrypoint, setSamlEntrypoint] = useState(false)
  const [samlIssuer, setSamlIssuer] = useState(false)
  const [showKeyDownload, setShowKeyDownload] = useState(false)
  const jwtssoConfig = useSelector(state => JWTSSOConfig.fromState(state))
  const intercomConfig = useSelector(state => IntercomConfig.fromState(state))
  const zendeskConfig = useSelector(state => ZendeskConfig.fromState(state))
  const salesforceConfig = useSelector(state => SalesforceConfig.fromState(state))
  const samlProviderConfig = useSelector(state => SAMLProviderConfig.fromState(state))
  const talkdeskConfig = useSelector(state => TalkdeskConfig.fromState(state))
  const niceConfig = useSelector(state => NiceConfig.fromState(state))
  const trustedOriginsConfig = useSelector(state => TrustedOrigins.fromState(state))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const updateSamlProviderConfig = (config) => {
    return dispatch(SAMLProviderConfig.actionCreators().updateSAMLProviderConfig(config))
  }

  useEffect(() => {
    dispatch(JWTSSOConfig.actionCreators().getJWTSSOConfig())
    dispatch(IntercomConfig.actionCreators().getIntercomConfig())
    dispatch(TrustedOrigins.actionCreators().getTrustedOrigins())
    dispatch(ZendeskConfig.actionCreators().getZendeskConfig())
    dispatch(SalesforceConfig.actionCreators().getSalesforceConfig())
    dispatch(SAMLProviderConfig.actionCreators().getSAMLProviderConfig())
    dispatch(TalkdeskConfig.actionCreators().getTalkdeskConfig())
    dispatch(NiceConfig.actionCreators().getNiceConfig())
  }, [dispatch])

  const publicKey = () => {
    if (jwtPublicKey !== false) {
      return jwtPublicKey
    }

    return jwtssoConfig.resource?.public_key || ''
  }

  const updatePublicKey = () => {
    return dispatch(JWTSSOConfig.actionCreators().updateJWTSSOConfig({
      public_key: publicKey()
    }))
  }

  const updateSalesforceConfig = () => {
    return dispatch(SalesforceConfig.actionCreators().updateSalesforceConfig({
      record_mapping: JSON.parse(salesforceRecordMapping)
    }))
  }

  const updateSAMLConfig = () => {
    return updateSamlProviderConfig({
      certificate: samlCertificate,
      entrypoint: samlEntrypoint,
      issuer: samlIssuer
    })
  }

  const updateTrustedOriginsConfig = () => {
    if (!trustedOrigins) return
    return dispatch(TrustedOrigins.actionCreators().updateTrustedOrigins(trustedOrigins.split('\n')))
  }

  const generateKeyPair = async () => {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
    const { publicKey, privateKeyUrl } = await createRSAKeyPair()
    const download = document.createElement('a')
    download.href = privateKeyUrl
    download.download = 'private-key.pem'
    download.rel = 'noopener'
    download.dispatchEvent(event)

    return {
      privateKeyUrl,
      publicKey
    }
  }

  const acceptKeyPair = (keypair) => {
    if (typeof keypair.privateKeyUrl === 'string') {
      URL.revokeObjectURL(keypair.privateKeyUrl)
    }

    if (typeof keypair.publicKey === 'string') {
      setShowKeyDownload(false)
      setJwtPublicKey(false)

      return dispatch(JWTSSOConfig.actionCreators().updateJWTSSOConfig({
        public_key: keypair.publicKey
      }))
    }
  }

  const renderJWTSSOConfig = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='virtual-column-key'>{t('JWT SSO')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t("Enter an RSA public key, or generate an RSA key pair, so we can verify JWTs have been signed by you. Don't ever give us or anyone else the corresponding private key.")}
        </SettingsGroup.HelpText>
        <SettingsGroup.SaveButton thinking={jwtssoConfig.working} onClick={updatePublicKey}>{t('Save')}</SettingsGroup.SaveButton>
        <SettingsGroup.Body>
          <TextArea
            className={mergeClassNames('h-36 font-mono', jwtssoConfig.error && 'border border-red-500')}
            type='text'
            id='jwtsso_public_key'
            value={publicKey()}
            placeholder={t('Enter your RSA public key, or generate a new key')}
            onChange={(e) => setJwtPublicKey(e.target.value)}
          />
          {!publicKey() && (
            <Button variant='tertiary' onClick={() => setShowKeyDownload(true)} className='mt-2 flex items-center gap-x-2 md:h-9'>
              <Icon type='plus' />
              {t('Generate a new key pair')}
            </Button>
          )}
          <Dialog
            isOpen={showKeyDownload}
            titleText={t('Confirm private key download')}
            acceptText={t('Download key')}
            onAccept={async () => acceptKeyPair(await generateKeyPair())}
            onCancel={() => setShowKeyDownload(false)}
            onClose={() => setShowKeyDownload(false)}
          >
            <p>{t('For your security, this private key is generated in your browser, never transmitted to our servers, and will be disposed of when you confirm.')}</p>
            <p>{t('After you download the private key, the public key will be automatically saved to your Cobrowse account settings.')}</p>
          </Dialog>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderTrustedOriginsConfig = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='virtual-column-key'>{t('Trusted embedding domains')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('Enter a list of trusted domains that are granted elevated permissions when using the Agent SDK to build custom integrations. These should be the domains that will be embedding Cobrowse as an iframe, for example the domain of your CRM. One per line.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.SaveButton thinking={trustedOriginsConfig.working} onClick={updateTrustedOriginsConfig}>{t('Save')}</SettingsGroup.SaveButton>
        <SettingsGroup.Body>
          <TextArea
            className={mergeClassNames('h-36 font-mono', trustedOriginsConfig.error && 'border border-red-500')}
            type='text'
            id='trusted_origins'
            value={trustedOrigins || trustedOriginsConfig.collection.join('\n')}
            placeholder='https://example.com'
            onChange={(e) => setTrustedOrigins(e.target.value)}
          />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderIntercomLinks = ({ ids }) => {
    if ((!ids) || ids.length === 0) {
      return <div className='text-gray-700'>{t('There are no linked Intercom accounts')}</div>
    }

    return ids.map((id) => <div key={id}>{id}</div>)
  }

  const renderIntercomConfig = () => {
    if (!intercomConfig.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='intercom'>{t('Intercom linking')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('These are the Intercom accounts your Cobrowse account is linked to. Linking an Intercom account allows your Intercom support agents to access your Cobrowse account automatically.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body>
          {renderIntercomLinks(intercomConfig.resource)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderZendeskLinks = ({ ids }) => {
    if ((!ids) || ids.length === 0) {
      return <div className='text-gray-700'>{t('There are no linked Zendesk subdomains')}</div>
    }

    return ids.map((id) => <div key={id}>{id}</div>)
  }

  const renderZendeskConfig = () => {
    if (!zendeskConfig.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='zendesk'>{t('Zendesk linking')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('These are the Zendesk subdomains your Cobrowse account is linked to. Linking a Zendesk subdomain allows your Zendesk support agents to access your Cobrowse account automatically.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body>
          {renderZendeskLinks(zendeskConfig.resource)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderSalesforceLinks = ({ ids }) => {
    if ((!ids) || ids.length === 0) {
      return <div className='text-gray-700'>{t('There are no linked Salesforce accounts')}</div>
    }

    return ids.map((id) => <div key={id}>{id}</div>)
  }

  const renderSalesforceRecordMapping = () => {
    if (!salesforceConfig.resource?.ids?.length) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='salesforce'>{t('Salesforce record mapping')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('This controls how your Salesforce record fields are mapped onto Cobrowse custom data.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.SaveButton thinking={salesforceConfig.working} onClick={updateSalesforceConfig}>{t('Save')}</SettingsGroup.SaveButton>
        <SettingsGroup.Body>
          <TextArea
            className='h-36 font-mono'
            onChange={(e) => setSalesforceRecordMapping(e.target.value)}
            defaultValue={JSON.stringify(salesforceConfig.resource.record_mapping, null, '\t')}
          />
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderSalesforceConfig = () => {
    if (!salesforceConfig.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='salesforce'>{t('Salesforce linking')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('These are the Salesforce accounts your Cobrowse account is linked to. Linking an Salesforce account allows your Salesforce support agents to access your Cobrowse account automatically.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body>
          {renderSalesforceLinks(salesforceConfig.resource)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderSAMLConfig = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='shield'>{t('SAML configuration')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('Here you can configure SAML login for your account.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.SaveButton thinking={samlProviderConfig.working} onClick={updateSAMLConfig}>{t('Save')}</SettingsGroup.SaveButton>
        <SettingsGroup.Body className='flex flex-col gap-y-4'>
          <div>
            <Label htmlFor='saml-certificate'>{t('Certificate')}</Label>
            <TextArea
              className='h-36 font-mono'
              type='text'
              id='saml-certificate'
              defaultValue={samlProviderConfig.resource?.certificate}
              placeholder={t('Enter your SAML certificate')}
              onChange={(e) => setSamlCertificate(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor='saml-entrypoint'>{t('Entrypoint')}</Label>
            <Input
              id='saml-entrypoint'
              className='font-mono'
              type='text'
              defaultValue={samlProviderConfig.resource?.entrypoint}
              placeholder={t('Enter your SAML entrypoint')}
              onChange={(e) => setSamlEntrypoint(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor='saml-issuer'>{t('Issuer')}</Label>
            <Input
              id='saml-issuer'
              className='font-mono'
              type='text'
              defaultValue={samlProviderConfig.resource?.issuer}
              placeholder='cobrowseio-saml'
              onChange={(e) => setSamlIssuer(e.target.value)}
            />
          </div>
          <div>
            <Switch
              name='authn_contexts'
              label={t('Enable authentication context request')}
              checked={samlProviderConfig?.resource?.authn_contexts?.length !== 0}
              onChange={(feature, state) => {
                updateSamlProviderConfig({
                  // this does actually support setting any authn context, but we haven't exposed this yet
                  // empty array will disable authn contexts being sent in the request
                  // null will reset to use the defaults
                  authn_contexts: state ? null : []
                })
              }}
            />
          </div>
          {samlProviderConfig.resource?.id && (
            <div className='flex items-center'>
              <CopyText label={t('Login page')}>
                {window.location.origin}/login/saml/{samlProviderConfig.resource.id}
              </CopyText>
            </div>
          )}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderTalkdeskConfig = () => {
    if (!talkdeskConfig.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='talkdesk'>{t('Talkdesk linking')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>{t('These are the Talkdesk Installation Ids your Cobrowse account is linked to. These can be used to setup the Cobrowse.io sidebar within Talkdesk.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body>
          {renderTalkdeskLinks(talkdeskConfig.resource)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  const renderTalkdeskLinks = ({ ids }) => {
    if ((!ids) || ids.length === 0) {
      return <div className='text-gray-700'>{t('There are no linked Talkdesk subdomains')}</div>
    }

    return ids.map((id) => <div key={id}>{id}</div>)
  }

  const renderNiceLinks = ({ ids }) => {
    if ((!ids) || ids.length === 0) {
      return <div className='text-gray-700'>{t('There are no linked Nice CX accounts')}</div>
    }

    return ids.map((id) => <div key={id}>{id}</div>)
  }

  const renderNiceConfig = () => {
    if (!niceConfig?.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title icon='nice'>{t('Nice CX linking')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('These are the Nice CX accounts your Cobrowse account is linked to. Linking a Nice CX account allows your Nice CX support agents to access your Cobrowse account automatically.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body>
          {renderNiceLinks(niceConfig.resource)}
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  return (
    <SettingsContainer>
      <SettingsContainer.Header>
        <h2>{t('Integrations')}</h2>
        <p>{t('Cobrowse supports integration with your CRM or helpdesk. Either use one of our pre-built integrations or set up your own using our powerful JWT single sign on mechanism.')}</p>
        <Alert>
          <Trans>
            Find out more about what you can do with JWT SSO <a className='text-slate underline' target='_blank' rel='noopener noreferrer' href='https://docs.cobrowse.io/agent-side-integrations/custom-iframe-integrations'>here</a>.
          </Trans>
        </Alert>
      </SettingsContainer.Header>
      <SettingsContainer.Body>
        {renderJWTSSOConfig()}
        {renderSAMLConfig()}
        {renderTrustedOriginsConfig()}
        {renderIntercomConfig()}
        {renderZendeskConfig()}
        {renderSalesforceConfig()}
        {renderSalesforceRecordMapping()}
        {renderTalkdeskConfig()}
        {renderNiceConfig()}
      </SettingsContainer.Body>
    </SettingsContainer>
  )
}

export default IntegrationSettings
