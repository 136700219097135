import React, { useCallback } from 'react'
import mergeClassNames from '../../utils/mergeClassNames'
import ResourceInfo from '../../components/ResourceInfo'
import { SessionAction } from './SessionAction'
import { useSidebarState } from '../../components/Sidebar'
import { DeviceInfoPills } from '../devices/DeviceInfoPills'
import { SessionHeader } from './SessionHeader'

const Session = ({ session, addFilter, setExpandedSession }) => {
  const [, toggle] = useSidebarState()

  const openSidebar = useCallback(() => {
    toggle(true)
    setExpandedSession(session)
  }, [setExpandedSession, session, toggle])

  return (
    <ResourceInfo
      resource={session}
      addFilter={addFilter}
      actionButton={<SessionAction session={session} viewRecordingClassName='group-hover/list-item:mix-blend-multiply' />}
      openSidebar={openSidebar}
    >
      <SessionHeader session={session} showChevron>
        <DeviceInfoPills
          device={session.device}
          customData={session.custom_data}
          className='hidden md:flex'
          addFilter={addFilter}
        />
      </SessionHeader>
      <div className={mergeClassNames('min-w-0 empty:hidden md:hidden')}>
        <DeviceInfoPills
          device={session.device}
          customData={session.custom_data}
          addFilter={addFilter}
        />
      </div>
    </ResourceInfo>
  )
}

export default Session
