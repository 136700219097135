import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const IconContainer = ({ className, children, ...props }) => {
  return (
    <div
      className={mergeClassNames('relative inline-flex size-8 items-center justify-center whitespace-nowrap rounded-full bg-gray-50 bg-cover text-center text-sm text-slate-900 outline outline-1.5 outline-offset-0 outline-gray-200', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default IconContainer
