import React from 'react'
import StandardFields from './StandardFields'
import { useDeviceInfoValue } from '../../hooks/useDeviceInfoValue'
import { useDeviceInfoData } from '../../hooks/useDeviceInfoData'
import mergeClassNames from '../../utils/mergeClassNames'
import { Icon } from '../../components/Icon'
import { useTranslation } from 'react-i18next'

function Label ({ iconType, label, code }) {
  if (iconType) return <Icon size='small' type={iconType} />

  return label || code
}

const KeyValue = ({
  code,
  value,
  type,
  fieldInfo,
  addFilter,
  limitWidth
}) => {
  const info = fieldInfo[code] ?? {}
  const val = useDeviceInfoValue(value, code, info)

  if (val === null || Array.isArray(val)) return null

  return (
    <div
      className={mergeClassNames(
        'flex min-w-0 cursor-default items-center',
        type === 'custom_data' && addFilter && 'cursor-pointer'
      )}
      title={info.label}
      onClick={(evt) => {
        evt.stopPropagation()
        if (type === 'custom_data' && addFilter != null) {
          addFilter(code, value, type)
        }
      }}
    >
      <span className='me-1 text-gray-700/60'>
        <Label {...info} code={code} />
      </span>
      <span className={mergeClassNames('min-w-0 truncate', limitWidth && 'lg:max-w-lg', type === 'custom_data' && addFilter && 'underline decoration-dashed underline-offset-2')} title={val}>{val}</span>

    </div>
  )
}

function PrioritizedFields ({ fields, onClick, addFilter }) {
  const standardCustomData = StandardFields.customData()
  const standardDeviceProperties = StandardFields.deviceData()

  return (
    <>
      {Object.keys(fields).map((key) => {
        let type = 'custom_data'
        let fieldInfo = standardCustomData

        if (standardDeviceProperties[key]) {
          type = 'device'
          fieldInfo = standardDeviceProperties
        }

        return (
          <KeyValue
            key={key}
            code={key}
            value={fields[key]}
            type={type}
            fieldInfo={fieldInfo}
            addFilter={addFilter}
            limitWidth
            onClick={onClick}
          />
        )
      })}
    </>
  )
}

export function DeviceInfoPills ({ device, customData, onClick, addFilter, className }) {
  const deviceInfoData = useDeviceInfoData(device, customData, true)
  const { t } = useTranslation()

  return (
    <div className={mergeClassNames('flex w-full min-w-0 flex-wrap gap-3 text-xs text-gray-700 group-hover/list-item:text-gray-800', className)}>
      <PrioritizedFields fields={deviceInfoData.prioritized} onClick={onClick} addFilter={addFilter} />
      <div className='flex gap-1 transition-all group-hover/list-item:gap-3 group-hover/list-item:text-slate-900 motion-reduce:transition-none'>
        <span>{t('+{{total}} more', { total: Object.keys(deviceInfoData.customData).length + Object.keys(deviceInfoData.deviceInfo).length })}</span>
        <Icon size='small' type='chevron-up' className='hidden rotate-90 md:inline' />
      </div>
    </div>
  )
}
