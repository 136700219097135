import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Button from './Button'
import { Icon } from './Icon'
import isInIframe from '../utils/isInIframe'

const PopoutButton = ({ className, ...props }) => {
  const { t } = useTranslation()

  if (!isInIframe()) return null

  return (
    <Button
      env='dark'
      size='extra-large'
      variant='tertiary'
      isIconButton
      title='Popout'
      className={className}
      to={window.location}
      target='_blank'
      rel='noreferrer'
      Component={NavLink}
      {...props}
    >
      <span className='sr-only'>{t('Open in a new tab')}</span>
      <Icon type='resize-full' size='large' />
    </Button>
  )
}

export default PopoutButton
