import React from 'react'
import BrowserIcon from './BrowserIcon'
import mergeClassNames from '../utils/mergeClassNames'
import { Icon } from './Icon'

const platformIconMapping = {
  ios: 'apple',
  macos: 'macos',
  windows: 'windows',
  android: 'android',
  web: 'globe'
}

const PlatformIcon = ({ device, className, wrapperClassName }) => {
  const { platform } = device
  const isWeb = platform === 'web'

  return (
    <div className={mergeClassNames('flex size-8 items-center justify-center', wrapperClassName || 'contents')}>
      {isWeb
        ? (
          <BrowserIcon
            userAgent={device.device}
            className={className}
          />
          )
        : (
          <Icon
            className={className}
            type={platformIconMapping[platform] ?? 'globe'}
          />
          )}
    </div>
  )
}

export default PlatformIcon
