import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SessionList from './SessionList'
import Session from './Session.state'
import { useTranslation } from 'react-i18next'
import { usePageTitle } from '../../hooks/usePageTitle'
import useDocumentTitle from '../../hooks/useDocumentTitle'

const SessionHistoryDashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sessions = useSelector(state => Session.fromState(state))

  const getSessions = useCallback((params, options) => dispatch(Session.actionCreators().getSessions({}, params, options)), [dispatch])
  const resetSessions = useCallback(() => dispatch(Session.actionCreators().resetSession()), [dispatch])

  usePageTitle(t('Sessions'))
  useDocumentTitle(t('Sessions'))

  const refresh = useCallback((params = {}, options = {}) => {
    const filter = { agent: 'all', state: ['active', 'ended'] }
    return getSessions({
      ...params,
      ...filter
    }, options)
  }, [getSessions])

  return (
    <SessionList
      sessions={sessions}
      refresh={refresh}
      reset={resetSessions}
    />
  )
}

export default SessionHistoryDashboard
