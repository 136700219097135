import EventEmitter from 'events'

export class ClientTracker extends EventEmitter {
  #id

  #interval

  #pingInterval

  #clients = {}

  constructor (id, { interval } = { interval: 3000 }) {
    super()
    this.#id = id
    this.#interval = interval

    // whenever a new client is detected, trigger a resend
    // of our client state
    this.on('join', this.ping)
    this.ping()

    this.#pingInterval = setInterval(this.ping, interval)
  }

  get id () { return this.#id }

  ping = () => {
    this.emit('ping', this.#id, this.#interval * 2)
  }

  track = (id, timeout) => {
    const existing = this.#clients[id]
    const timer = setTimeout(() => this.#expired(id), timeout || (this.#interval * 2))
    if (existing) clearTimeout(existing.timeout)
    this.#clients[id] = { id, timestamp: new Date(), timeout: timer }
    if (!existing) this.emit('join', id)
  }

  #expired = (id) => {
    delete this.#clients[id]
    this.emit('leave', id)
  }

  all = () => {
    return Object.keys(this.#clients)
  }

  destroy = () => {
    for (const id of Object.keys(this.#clients)) {
      const record = this.#clients[id]
      clearTimeout(record.timeout)
    }
    clearInterval(this.#pingInterval)
    this.removeAllListeners()
  }
}
