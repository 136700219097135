import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import TextArea from '../../components/TextArea'
import RedactionConfig from './RedactionConfig.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import Alert from '../../components/Alert'
import { SettingsContainer } from '../../components/SettingsContainer'
import RedactionConfigBlock from './RedactionConfigBlock'
const RedactionSettings = () => {
  const [platformRedactionSettings, setPlatformRedactionSettings] = useState({})
  const dispatch = useDispatch()
  const redactionConfig = useSelector(state => RedactionConfig.fromState(state))
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(RedactionConfig.actionCreators().getRedactionConfig())
  }, [dispatch])

  const configFor = (type, platform) => {
    return platformRedactionSettings?.[type]?.[platform]?.split('\n') ||
      redactionConfig.resource?.[type]?.[platform] || []
  }

  const updateRedactionConfig = (platform) => {
    return dispatch(RedactionConfig.actionCreators().updateRedactionConfig({
      redaction: { [platform]: configFor('redaction', platform) },
      unredaction: { [platform]: configFor('unredaction', platform) }
    }))
  }

  const handleRedactionConfigChange = (e, type, platform) => {
    setPlatformRedactionSettings({
      ...platformRedactionSettings,
      [type]: {
        ...platformRedactionSettings[type],
        [platform]: e.target.value
      }
    })
  }

  const renderTextArea = (id, label, placeholder, type, platform) => {
    return (
      <>
        <label htmlFor={id} className='sr-only fixed'>{label}</label>
        <TextArea
          className='h-32 font-mono'
          type='text'
          id={id}
          placeholder={placeholder}
          value={configFor(type, platform).join('\n')}
          onChange={(e) => handleRedactionConfigChange(e, type, platform)}
        />
      </>
    )
  }

  const renderRedactionConfig = () => {
    if (!redactionConfig.resource) {
      return null
    }

    return (
      <>
        <SettingsGroup>
          <SettingsGroup.Title>{t('Web redaction configuration')}</SettingsGroup.Title>
          <SettingsGroup.HelpText>
            {t('Enter views to redact on web pages, one per line.')}
          </SettingsGroup.HelpText>
          <SettingsGroup.SaveButton thinking={redactionConfig.working} onClick={() => updateRedactionConfig('web')}>{t('Save')}</SettingsGroup.SaveButton>
          <SettingsGroup.Body className='flex flex-col gap-y-4'>
            <RedactionConfigBlock
              platform='web'
              sdkVersion='2.40.0'
              renderTextArea={renderTextArea}
            />
          </SettingsGroup.Body>
        </SettingsGroup>
        <SettingsGroup>
          <SettingsGroup.Title>{t('Android redaction configuration')}</SettingsGroup.Title>
          <SettingsGroup.HelpText>
            {t('Enter views to redact on Android devices, one per line.')}
          </SettingsGroup.HelpText>
          <SettingsGroup.SaveButton thinking={redactionConfig.working} onClick={() => updateRedactionConfig('android')}>{t('Save')}</SettingsGroup.SaveButton>
          <SettingsGroup.Body className='flex flex-col gap-y-4'>
            <RedactionConfigBlock
              platform='android'
              sdkVersion='2.35.0'
              limitations={t('Unredaction selectors are not yet supported for {{platform}}.', { platform: 'Jetpack Compose' })}
              renderTextArea={renderTextArea}
            />
          </SettingsGroup.Body>
        </SettingsGroup>
        <SettingsGroup>
          <SettingsGroup.Title>{t('iOS redaction configuration')}</SettingsGroup.Title>
          <SettingsGroup.HelpText>
            {t('Enter views to redact on iOS devices, one per line.')}
          </SettingsGroup.HelpText>
          <SettingsGroup.SaveButton thinking={redactionConfig.working} onClick={() => updateRedactionConfig('ios')}>{t('Save')}</SettingsGroup.SaveButton>
          <SettingsGroup.Body className='flex flex-col gap-y-4'>
            <RedactionConfigBlock
              platform='ios'
              sdkVersion='2.32.0'
              limitations={t('Unredaction selectors are not yet supported for {{platform}}.', { platform: 'SwiftUI' })}
              renderTextArea={renderTextArea}
            />
          </SettingsGroup.Body>
        </SettingsGroup>
      </>
    )
  }

  return (
    <SettingsContainer>
      <SettingsContainer.Header>
        <h2>{t('Redaction')}</h2>
        <div>
          <p>
            {t('Redaction allows you to remove elements from the view that your agents will see. This means you can keep user data private when it should not be available to your support agents.')}
            &nbsp;
            <Trans>Find out more about redaction in our <a target='_blank' rel='noopener noreferrer' href='https://docs.cobrowse.io/sdk-features/redact-sensitive-data'>documentation</a>.</Trans>
          </p>
        </div>
        <Alert type='info'>
          <Trans>
            We recommend using the SDK API for redaction. See the <a target='_blank' className='text-violet underline' rel='noopener noreferrer' href='https://docs.cobrowse.io/sdk-features/redact-sensitive-data'>SDK docs</a>.
          </Trans>
        </Alert>
      </SettingsContainer.Header>
      <SettingsContainer.Body>
        {renderRedactionConfig()}
      </SettingsContainer.Body>
    </SettingsContainer>
  )
}

export default RedactionSettings
