import React, { useCallback } from 'react'
import { getTestId } from '../../utils/getTestId'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import mergeClassNames from '../../utils/mergeClassNames'
import { ExpandableVerticalMenu } from '../../components/ExpandableVerticalMenu'
import { Icon } from '../../components/Icon'

export function DisconnectButton ({ account, session, user, performAction }) {
  const { t } = useTranslation()
  const isAgentOfRecord = session.agent.id === user.resource.id
  const isAdmin = account.resource.features.manage_account === true

  const Btn = useCallback(({ isExpanded }) => {
    return (
      <Button
        env='dark'
        size='extra-large'
        isIconButton
        variant={isExpanded ? 'tertiary' : 'error'}
        className={mergeClassNames('min-w-16 rounded-full ', isExpanded && 'bg-slate-800 hover:bg-slate-800')}
        onClick={() => isAgentOfRecord ? performAction('end') : null}
        title={t('End session')}
        {...getTestId('toolbar-button-end-session')}
      >
        <span className='sr-only'>{t('End session')}</span>
        <Icon type='phone' size='large' />
      </Button>
    )
  }, [isAgentOfRecord, performAction, t])

  if (isAgentOfRecord) {
    return <Btn />
  }

  return (
    <ExpandableVerticalMenu
      Button={Btn}
      className='w-auto'
      menuClassname='p-1 gap-1 w-[352px] right-[-60px] md:w-auto md:right-auto'
      isHorizontal
    >
      <Button
        Component={Link}
        to={`/dashboard${window.location.search}`}
        variant='secondary'
        className='flex h-9 flex-1 whitespace-nowrap rounded-3xl bg-slate-900 px-4 py-3 text-white hover:bg-slate-950 disabled:hover:bg-gray-50'
      >
        {t('Leave session')}
      </Button>
      {isAdmin && <Button onClick={() => performAction('end')} variant='error' className='flex h-9 flex-1 whitespace-nowrap rounded-3xl bg-red-500 px-4 py-3 text-white hover:bg-red-600 disabled:hover:bg-gray-50'>{t('End session')}</Button>}
    </ExpandableVerticalMenu>
  )
}
