import React, { forwardRef } from 'react'
import { getTestId } from '../utils/getTestId'
import mergeClassNames from '../utils/mergeClassNames'

const Checkbox = forwardRef(({ checked, onChange, className, testId, ...props }, ref) => {
  return (
    <input
      ref={ref}
      checked={checked}
      type='checkbox'
      onChange={onChange}
      className={mergeClassNames('relative inline-block h-6 w-10 cursor-pointer appearance-none rounded-2xl border border-gray-50 bg-white p-0 align-middle outline-none after:absolute after:start-px after:top-px after:size-5 after:rounded-full after:bg-black-400 after:transition-all after:delay-200 after:ease-linear checked:after:start-[calc(100%_-_21px)] checked:after:bg-black', className)}
      {...getTestId(testId)}
      {...props}
    />
  )
})

export default Checkbox
