import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from './Sidebar'
import DeviceInfo from '../modules/devices/DeviceInfo'
import { updateUiState } from '../modules/ui-state/UI.state'
import { useClickOutside } from '../hooks/useClickOutside'
import { useIsAboveBreakpoint } from '../hooks/useIsAboveBreakpoint'

export function DeviceInfoSidebar ({ device, addFilter, Header, HeaderAction, sidebarTriggerContainer }) {
  const dispatch = useDispatch()
  const isOpen = useSelector((state) => state.ui.sidebarOpen)
  const isDesktop = useIsAboveBreakpoint(740)

  const close = useCallback(() => {
    dispatch(updateUiState({ sidebarOpen: false }))
  }, [dispatch])

  const addFilterAndCloseSidebar = useCallback((...args) => {
    close()
    addFilter(...args)
  }, [addFilter, close])

  useClickOutside(sidebarTriggerContainer, close, isOpen && isDesktop)

  return (
    <Sidebar
      className='fixed inset-y-4 right-0 z-40 max-h-[calc(100vh-32px)]'
      Header={Header}
      HeaderAction={HeaderAction}
    >
      <DeviceInfo
        device={device?.device}
        customData={device?.custom_data}
        isTableView
        addFilter={addFilterAndCloseSidebar}
      />
    </Sidebar>
  )
}
