import React, { memo } from 'react'
import StandardFields from './StandardFields'
import mergeClassNames from '../../utils/mergeClassNames'
import { useDeviceInfoData } from '../../hooks/useDeviceInfoData'
import { useDeviceInfoValue } from '../../hooks/useDeviceInfoValue'

function PrioritizedFields ({ fields, isTableView }) {
  if (isTableView) return null

  const standardCustomData = StandardFields.customData()
  const standardDeviceProperties = StandardFields.deviceData()

  return (
    <div className='flex w-full flex-wrap gap-2 lg:flex-nowrap'>
      {Object.keys(fields).map((key) => {
        let type = 'device'
        let fieldInfo = standardDeviceProperties

        if (standardCustomData[key]) {
          type = 'custom_data'
          fieldInfo = standardCustomData
        }

        return (
          <KeyValue
            key={key}
            code={key}
            value={fields[key]}
            type={type}
            fieldInfo={fieldInfo}
            limitWidth
          />
        )
      })}
    </div>
  )
}

const KeyValue = ({
  code,
  value,
  type,
  fieldInfo,
  addFilter,
  limitWidth,
  showIcons,
  isTableView = false
}) => {
  const info = fieldInfo[code] ?? {}
  const val = useDeviceInfoValue(value, code, info, isTableView)

  if (val === null || (Array.isArray(value) && !isTableView)) return null

  if (isTableView) {
    return (
      <div
        className={mergeClassNames(
          'flex w-full cursor-default items-start border-b border-gray-200 py-1 text-xs last:border-0',
          type === 'customData' && addFilter && 'cursor-pointer'
        )}
        title={info.label}
        onClick={(evt) => {
          evt.stopPropagation()
          if (addFilter != null && type === 'customData') {
            addFilter(code, value, type)
          }
        }}
      >
        <span className='flex w-1/2 gap-x-1 break-all text-gray-700'>
          {showIcons && info.icon}
          {info.label || code}
        </span>
        <div className='flex w-1/2 flex-wrap gap-1'>

          {Array.isArray(val)
            ? val.map(item => (
              <span key={item} className='rounded-[10px] bg-gray-100 px-2 py-[2px] capitalize text-slate-900'>{item.replace(/_/g, ' ')}</span>
            ))
            : <span className={mergeClassNames('min-w-0 break-all text-slate-900', limitWidth && 'lg:max-w-lg', type === 'customData' && addFilter && 'underline decoration-dashed underline-offset-2')} title={val}>{val}</span>}
        </div>
      </div>
    )
  }

  return (
    <div
      className={mergeClassNames(
        'flex min-w-0 flex-wrap items-center gap-x-1 rounded-lg bg-gray-50 px-2 py-1 text-xs text-gray-700',
        type === 'customData' && addFilter && 'cursor-pointer'
      )}
      title={info.label}
    >
      <span className='text-slate-900'>{info.icon || info.label || code}</span>
      <span className={mergeClassNames('min-w-0 truncate', limitWidth && 'lg:max-w-lg')} title={value}>{val}</span>
    </div>
  )
}

const CategorizedInfo = ({ items, category, type, fieldInfo, addFilter, onClick, isTableView, showIcons = false }) => {
  if (isTableView) {
    return (
      <div className='flex flex-col gap-2'>
        <div className='capitalize text-slate-900'>{category}</div>
        <div className='flex flex-wrap gap-2'>
          {Object.keys(items).map((key) => (
            <KeyValue
              key={key}
              code={key}
              value={items[key]}
              type={type}
              fieldInfo={fieldInfo}
              addFilter={addFilter}
              showIcons={showIcons}
              isTableView={isTableView}
            />
          ))}
        </div>
      </div>
    )
  }

  if (Object.keys(items).length === 0) return null

  return (
    <div>
      <div className='mb-2 text-xs capitalize text-slate-900'>{category}</div>
      <div className='flex flex-wrap gap-2'>
        {Object.keys(items).map((key) => (
          <KeyValue
            key={key}
            code={key}
            value={items[key]}
            type={type}
            fieldInfo={fieldInfo}
            addFilter={addFilter}
          />
        ))}
      </div>
    </div>
  )
}

function DeviceInfo ({
  device,
  customData = {},
  addFilter,
  className,
  isTableView
}) {
  const deviceInfoData = useDeviceInfoData(device, customData, !isTableView)

  return (
    <div className={mergeClassNames('flex w-full min-w-0 flex-wrap text-sm text-black', !isTableView && 'mt-1 gap-6 lg:mt-0', isTableView && 'rounded-lg', className)}>
      <PrioritizedFields fields={deviceInfoData.prioritized} isTableView={isTableView} addFilter={addFilter} />
      <div className='flex w-full flex-col gap-y-4 md:gap-y-6'>
        {deviceInfoData.sections.map(section => (
          <CategorizedInfo
            key={section.type}
            addFilter={addFilter}
            isTableView={isTableView}
            items={deviceInfoData[section.type]}
            showIcons={section.type === 'deviceInfo'}
            {...section}
          />
        ))}
      </div>
    </div>
  )
}

export default memo(DeviceInfo)
