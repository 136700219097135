import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle, useMemo } from 'react'
import _ from 'lodash'
import './AnnotationEditor.css'
import { getTestId } from '../../utils/getTestId'
import useDebounce from '../../hooks/useDebounce'
import { generateUUID } from '../../utils/generateUUID'
import { SESSION_TOOL_TYPE_DRAWING, SESSION_TOOL_TYPE_DISAPPEARING_INK, SESSION_TOOL_TYPE_LASER } from './SessionToolTypes'
import { isDrawingEnabled } from '../../utils/isDrawingEnabled'
import { hexToRgb } from '../../utils/hexToRgb'
import { useDispatch } from 'react-redux'
import { updateUiState } from '../ui-state/UI.state'
import mergeClassNames from '../../utils/mergeClassNames'

const drawingWidth = 8
const disappearIn = 3000
const seenFabricIds = new Set()

function getDrawingCursor (color) {
  const svgDiameter = drawingWidth + 2
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${svgDiameter}" width="${svgDiameter}"><circle cx="${svgDiameter / 2}" cy="${svgDiameter / 2}" r="${drawingWidth / 2}" stroke="white" stroke-width="1" fill="${hexToRgb(color)}"/></svg>') ${svgDiameter / 2} ${svgDiameter / 2}, auto`
}

function suppressEvent (e) {
  e.preventDefault()
}

function setFabricId (opt) {
  opt.path.id = generateUUID()
}

const AnnotationEditor = forwardRef(({
  disable,
  touch,
  tool,
  height,
  width,
  rotation,
  scale,
  toolHandler,
  selectedColor,
  allowMultipleDrawings
}, ref) => {
  const [mouseDown, setMouseDown] = useState(false)
  const [fabric, setFabric] = useState(false)
  const size = useRef({ width: 0, height: 0, clientWidth: 0, clientHeight: 0 })
  const fabricCanvas = useRef(null)
  const container = useRef(null)
  const canvas = useRef(null)

  const dispatch = useDispatch()

  const toolColor = selectedColor

  useImperativeHandle(ref, () => ({
    clear
  }))

  const shouldGenerateTouch = useCallback(() => {
    if (tool === SESSION_TOOL_TYPE_LASER) {
      return true
    }

    if (touch) {
      return true
    }

    return false
  }, [tool, touch])

  const transformEventToLaserEvent = useCallback((e) => {
    return {
      type: SESSION_TOOL_TYPE_LASER,
      state: e.type,
      changedTouches: e.changedTouches,
      colour: toolColor
    }
  }, [toolColor])

  const onDownEvent = useCallback((e) => {
    if (isDrawingEnabled(tool)) {
      return
    }

    setMouseDown(true)

    if (e.type !== 'touchstart' && shouldGenerateTouch()) {
      e = {
        type: 'touchstart',
        changedTouches: [{ clientX: e.clientX, clientY: e.clientY }]
      }
    }

    if (tool === SESSION_TOOL_TYPE_LASER) {
      e = transformEventToLaserEvent(e)
    }

    toolHandler(tool, e, containerPosition())
  }, [tool, toolHandler, shouldGenerateTouch, transformEventToLaserEvent])

  const onMoveEvent = useCallback((e) => {
    if (!container.current) {
      return
    }

    if (!container.current.contains(e.target)) {
      return
    }

    if (isDrawingEnabled(tool)) {
      return
    }

    if (tool === SESSION_TOOL_TYPE_LASER && !mouseDown) {
      return
    }

    if (e.type !== 'touchmove' && shouldGenerateTouch()) {
      e = {
        type: 'touchmove',
        changedTouches: [{ clientX: e.clientX, clientY: e.clientY }]
      }
    }

    if (tool === SESSION_TOOL_TYPE_LASER) {
      e = transformEventToLaserEvent(e)
    }

    toolHandler(tool, e, containerPosition())
  }, [tool, toolHandler, mouseDown, shouldGenerateTouch, transformEventToLaserEvent])

  const throttledOnMoveEvent = useMemo(() => _.throttle(onMoveEvent, 80), [onMoveEvent])

  const onUpEvent = useCallback((e) => {
    if (isDrawingEnabled(tool)) {
      return
    }

    if (mouseDown) {
      setMouseDown(false)
      throttledOnMoveEvent.cancel()

      if (e.type !== 'touchend' && shouldGenerateTouch()) {
        e = {
          type: 'touchend',
          changedTouches: [{ clientX: e.clientX, clientY: e.clientY }]
        }
      }

      if (tool === SESSION_TOOL_TYPE_LASER) {
        e = transformEventToLaserEvent(e)
      }

      toolHandler(tool, e, containerPosition())
    }
  }, [tool, mouseDown, toolHandler, throttledOnMoveEvent, shouldGenerateTouch, transformEventToLaserEvent])

  useEffect(() => {
    const runEffect = async () => {
      const Fabric = await import('fabric')
      setFabric(Fabric)
    }

    runEffect()

    container.current.addEventListener('contextmenu', suppressEvent)
  }, [])

  const emitCanvasUpdate = useDebounce((a) => {
    if (!a?.target) return

    const o = a.target
    const id = o.id
    let timeoutId

    if (!seenFabricIds.has(id)) {
      // if we the sdk doesn't support the disappearing tool then we need to keep
      // the fabric objects so that we can capture the entire image when a new
      // drawing is done for legacy sdks to work properly
      if (allowMultipleDrawings) {
        timeoutId = setTimeout(() => {
          fabricCanvas.current.remove(o)
          seenFabricIds.delete(id)
        }, disappearIn)
      }
      seenFabricIds.add(id, { timeoutId, id })
    }

    const coordinates = getCoordinates(allowMultipleDrawings ? [o] : fabricCanvas.current.getObjects())
    const { x1, x2, y1, y2 } = coordinates

    if (x2 > x1 && y2 > y1) {
      const mimeType = 'image/png'
      const dataURI = serializeImage(
        allowMultipleDrawings ? o : fabricCanvas.current,
        coordinates
      )

      if (tool === SESSION_TOOL_TYPE_DRAWING) {
        dispatch(updateUiState({ hasActiveDrawings: true }))
      }

      toolHandler(SESSION_TOOL_TYPE_DRAWING, {
        id,
        type: 'drawing',
        image: dataURItoBlob(dataURI),
        mime_type: mimeType,
        x: x1 / size.current.width,
        y: y1 / size.current.height,
        width: (x2 - x1) / size.current.width,
        height: (y2 - y1) / size.current.height,
        disappears_in: tool === SESSION_TOOL_TYPE_DISAPPEARING_INK ? disappearIn : null,
        fade_duration: tool === SESSION_TOOL_TYPE_DISAPPEARING_INK ? 1000 : null
      }, containerPosition())
    }
  }, 50)

  useEffect(() => {
    if (!fabric) {
      return
    }

    fabricCanvas.current = new fabric.Canvas(canvas.current)
    fabricCanvas.current.selection = false

    fabricCanvas.current.on('path:created', setFabricId)
    fabricCanvas.current.on('object:added', emitCanvasUpdate)

    return () => {
      fabricCanvas.current.off('object:added', emitCanvasUpdate)
      fabricCanvas.current.off('path:created', setFabricId)
    }
  }, [fabric, emitCanvasUpdate])

  useEffect(() => {
    const elem = container.current
    elem.addEventListener('mousedown', onDownEvent)
    elem.addEventListener('mousemove', throttledOnMoveEvent)
    document.addEventListener('mouseup', onUpEvent)

    elem.addEventListener('touchstart', onDownEvent)
    elem.addEventListener('touchmove', throttledOnMoveEvent)
    document.addEventListener('touchend', onUpEvent)

    return () => {
      elem?.removeEventListener('mousedown', onDownEvent)
      elem?.removeEventListener('mousemove', throttledOnMoveEvent)
      elem?.removeEventListener('touchstart', onDownEvent)
      elem?.removeEventListener('touchmove', throttledOnMoveEvent)
      document.removeEventListener('mouseup', onUpEvent)
      document.removeEventListener('touchend', onUpEvent)
    }
  }, [tool, onDownEvent, onUpEvent, throttledOnMoveEvent])

  const clear = useCallback(() => {
    dispatch(updateUiState({ hasActiveDrawings: false }))
    fabricCanvas.current?.clear()
  }, [dispatch])

  useEffect(() => {
    const updateTool = () => {
      if (!fabricCanvas.current) {
        return
      }

      if (isDrawingEnabled(tool) && !fabricCanvas.current.isDrawingMode) {
        fabricCanvas.current.isDrawingMode = true
        fabricCanvas.current.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas.current)
        fabricCanvas.current.freeDrawingBrush.color = toolColor
        fabricCanvas.current.freeDrawingBrush.width = drawingWidth * (scale ?? 1)
        fabricCanvas.current.freeDrawingCursor = getDrawingCursor(toolColor)
      }

      if (fabricCanvas.current.isDrawingMode && !isDrawingEnabled(tool)) {
        fabricCanvas.current.isDrawingMode = false
        fabricCanvas.current.forEachObject(o => { o.selectable = false })
      }
    }

    const orientationChange = (shouldNotify) => {
      clear()
      if (shouldNotify) {
        toolHandler(SESSION_TOOL_TYPE_DRAWING, { id: null })
      }
    }

    const updateSize = () => {
      if (!fabricCanvas.current) {
        return
      }

      // apply size changes
      const oldSize = size.current
      size.current = {
        width: width * scale,
        height: height * scale,
        clientWidth: width,
        clientHeight: height
      }

      let scaleX = 1
      let scaleY = 1

      // trigger orientation change to clear old annotations
      const tolerance = 10
      const heightChanged = Math.abs(size.current.clientHeight - oldSize.clientHeight) > tolerance
      const widthChanged = Math.abs(size.current.clientWidth - oldSize.clientWidth) > tolerance

      if (heightChanged || widthChanged) {
        orientationChange(oldSize.clientWidth !== 0)
      }

      // finally update the fabric js canvas size (only when
      // it actually chnages to avoid flicker during drawing)
      if (fabricCanvas.current.getWidth() !== size.current.width) {
        scaleX = size.current.width / fabricCanvas.current.getWidth()
        fabricCanvas.current.setWidth(size.current.width)
      }

      if (fabricCanvas.current.getHeight() !== size.current.height) {
        scaleY = size.current.height / fabricCanvas.current.getHeight()
        fabricCanvas.current.setHeight(size.current.height)
      }

      if (scaleY !== 1 || scaleX !== 1) {
        fabricCanvas.current.getObjects().forEach((obj) => {
          obj.scaleX *= scaleX
          obj.scaleY *= scaleY
          obj.left *= scaleX
          obj.top *= scaleY
          obj.setCoords()
        })

        fabricCanvas.current.requestRenderAll()
      }
    }

    updateSize()
    updateTool()
  }, [height, width, rotation, scale, tool, fabric, toolHandler, clear, toolColor])

  useEffect(() => {
    if (isDrawingEnabled(tool) && fabricCanvas.current != null) {
      fabricCanvas.current.freeDrawingBrush.width = drawingWidth * (scale ?? 1)
      fabricCanvas.current.freeDrawingCursor = getDrawingCursor(toolColor)
      fabricCanvas.current.freeDrawingBrush.color = toolColor
    }
  }, [tool, scale, toolColor])

  const containerPosition = () => {
    return {
      width: canvas.current?.clientWidth,
      height: canvas.current?.clientHeight,
      x: canvas.current?.getBoundingClientRect().x,
      y: canvas.current?.getBoundingClientRect().y
    }
  }

  const getCoordinates = (drawings) => {
    let x1 = size.current.width
    let y1 = size.current.height
    let x2 = 0
    let y2 = 0

    drawings.forEach(o => {
      const bounds = o.getBoundingRect()
      x1 = Math.min(x1, bounds.left)
      y1 = Math.min(y1, bounds.top)
      x2 = Math.min(size.current.width, Math.max(x2, bounds.left + bounds.width))
      y2 = Math.min(size.current.height, Math.max(y2, bounds.top + bounds.height))
    })

    return {
      // Older versions of the Android SDK don't deal with Integer values correctly
      // so we can't send 0
      x1: Math.max(0.0000001, x1),
      y1: Math.max(0.0000001, y1),
      x2,
      y2,
      originalX1: x1,
      originalY1: y1
    }
  }

  function serializeImage (target, { x1, y1, x2, y2, originalX1, originalY1 }) {
    const multiplier = Math.max(2, 2 / scale)

    let left = x1
    let top = y1
    const width = x2 - x1
    const height = y2 - y1

    if (allowMultipleDrawings) {
      // if the individual image starts at a negative x or y position then we need to
      // serialise the image from the corresponding absolute number and reduce the height/width
      // by the same amount
      left = 0 + (originalX1 < 0 ? Math.abs(originalX1) : 0)
      top = 0 + (originalY1 < 0 ? Math.abs(originalY1) : 0)
    }

    return target.toDataURL({
      format: 'png',
      multiplier,
      left,
      top,
      width,
      height,
      enableRetinaScaling: true
    })
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i)
    }

    return ia
  }

  return (
    <div
      style={{ pointerEvents: disable ? 'none' : undefined, width: '100%', height: '100%' }}
      className={
        // eslint-disable-next-line
        mergeClassNames(
          'AnnotationEditor absolute left-0 top-0 size-full origin-top-left',
          `tool-${tool}`,
          `tool-${tool}-${mouseDown ? 'active' : 'inactive'}`
        )
      }
      ref={container}
      {...getTestId('annotation-editor')}
      data-remote-control={!!disable}
    >
      <canvas className='size-full opacity-50' ref={canvas} />
    </div>
  )
})

export default AnnotationEditor
