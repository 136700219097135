import React from 'react'
import LinkButton from '../../components/LinkButton'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import Button from '../../components/Button'
import { Icon } from '../../components/Icon'

const ViewRecordingButton = ({ session, disabled = false, className, ...props }) => {
  const { t } = useTranslation()
  const buttonProps = {
    variant: 'tertiary',
    className: mergeClassNames('flex gap-x-2 text-center', className),
    isFullWidth: true,
    ...props
  }

  if (disabled) {
    return (
      <Button
        {...buttonProps}
        {...props}
        disabled
      >
        {t('Expired')}
      </Button>
    )
  }

  return (
    <LinkButton
      target='_blank'
      to={`/session/${session.id}/recording${window.location.search}`}
      {...getTestId('session-recording-button')}
      {...buttonProps}
      onClick={(evt) => evt.stopPropagation()}
    >
      <Icon type='player-play' />
      {t('Recording')}
    </LinkButton>
  )
}

export default ViewRecordingButton
