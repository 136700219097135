import React from 'react'
import DOMNode from './DOMNode'
import { hexToRgb } from '../../utils/hexToRgb'

// standardjs doesn't like custom imports as we need this as webpack
// is configured to load it as react components
// eslint-disable-next-line
import handPointerSVG from '!!raw-loader!../../icons/hand-pointer-cursor.svg'
import { SelectedColorContext } from './SelectedColorContext'

const styleOverrides = `
::selection {
  background: rgb(234, 91, 80);
  color: white;
}
textarea { resize: none !important; }
`

export default class DOMHead extends DOMNode {
  static contextType = SelectedColorContext

  static nodeFilter (node) {
    return node
  }

  onDOMHeadRef = (el) => {
    this.el = el
  }

  buildStyleOverrides = () => {
    const cursorSvg = handPointerSVG
      .replace(/fill="none"/g, `fill="${hexToRgb(this.context)}"`)
      .replace(/fill="currentColor"/g, `fill="${hexToRgb('#f8f9fa')}"`)
      .replace(/\n/g, '')

    return `html { cursor: url('data:image/svg+xml;utf8,${cursorSvg}') 16 0, pointer !important; }
${styleOverrides}`
  }

  render () {
    const { node: { childNodes } } = this.props
    return (
      <head ref={this.onDOMHeadRef}>
        <base href={this.props.base} />
        <style key='cb-style'>{this.buildStyleOverrides()}</style>
        {this.renderChildren(childNodes.filter(n => n.nodeType === 1))}
      </head>
    )
  }
}
