import React from 'react'

function StepContainerRoot ({ children }) {
  return <div className='flex flex-col gap-y-4'>{children}</div>
}

function Header ({ children }) {
  return <div className='flex flex-col gap-y-2'>{children}</div>
}

function Title ({ children }) {
  return <h2 className='text-lg text-slate-900'>{children}</h2>
}

function Description ({ children }) {
  return <p className='text-sm font-medium text-gray-700'>{children}</p>
}

function Body ({ children }) {
  return <div className='flex flex-col gap-y-4 rounded-lg border border-gray-200 bg-white p-4 md:p-6'>{children}</div>
}

export const StepContainer = Object.assign(StepContainerRoot, { Header, Title, Description, Body })
