/* eslint-disable react/jsx-handler-names */
import React, { Component, createRef } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { connect } from 'react-redux'
import AnnotationEditor from './AnnotationEditor'
import ButtonLink from '../../components/ButtonLink'
import DeviceInfo from '../devices/DeviceInfo'
import Loading from '../../components/Loading'
import RemoteScreen from '../screens/RemoteScreen'
import Sidebar from '../../components/Sidebar'
import EmbedParams from '../../utils/EmbedParams'
import Session from './Session.state'
import mergeClassNames from '../../utils/mergeClassNames'
import { getTestId } from '../../utils/getTestId'
import SessionCapability from './SessionCapability'
import DeviceStatus from '../devices/DeviceStatus'
import URLView from '../../components/URLView'
import { SESSION_TOOL_TYPE_CONTROL } from './SessionToolTypes'
import { SESSION_CAPABILITY_TYPE_DISAPPEARING_INK } from './SessionCapabilityTypes'
import { resetDOMError } from '../errors/Error.state'
import { SessionOverlayMessage } from '../../components/SessionOverlayMessage'
import { DisplayPicker } from './DisplayPicker'
import { AndroidControls } from './AndroidControls'
import SessionToolbar from './SessionToolbar'
import { tw } from '../../utils/tw'

function hasDisappearingInkToolEnabled (session) {
  return SessionCapability.hasDeviceCapability(session, SESSION_CAPABILITY_TYPE_DISAPPEARING_INK, false)
}

class ActiveSession extends Component {
  constructor () {
    super()
    this.state = {
      height: 0,
      width: 0,
      url: false,
      display: false,
      scale: -1
    }

    this.resizeObserver = null
    this.screenRef = createRef()
  }

  componentDidMount () {
    // Keep sending deduplicated syncs until we get a sync response
    this.syncInterval = setInterval(() => this.props.sync({ id: `${Date.now() - (Date.now() % 5000)}` }), 100)

    // periodically force a screen render to display error messages
    // that are triggered after a time since the last alive message
    this.renderInterval = setInterval(() => this.forceUpdate(), 1000)

    document.addEventListener('keydown', this.onKeyPress)
    document.addEventListener('keyup', this.onKeyPress)
    window.addEventListener('resize', this.onResize)

    // see if we need to keep the session alive periodically
    this.activityInterval = setInterval(this.keepSessionAlive, 20 * 1000)

    this.setSupportedVideoCodecs()

    this.props.dispatch(resetDOMError())

    this.trackScreenScale()
  }

  componentDidUpdate (prevProps) {
    const prevTs = prevProps.screen && prevProps.screen.timestamp
    const newTs = this.props.screen && this.props.screen.timestamp
    if (this.props.drawing === null || prevTs !== newTs) this.clear()
  }

  componentWillUnmount () {
    clearInterval(this.syncInterval)
    clearInterval(this.renderInterval)
    clearInterval(this.activityInterval)

    document.removeEventListener('keydown', this.onKeyPress)
    document.removeEventListener('keyup', this.onKeyPress)
    window.removeEventListener('resize', this.onResize)

    this.untrackScreenScale()
  }

  trackScreenScale = () => {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect

        const scale = this.computeScreenScale(width, height)
        // Update state only if the size has changed to avoid unnecessary rerenders
        if (scale !== this.state.scale) {
          this.setState({ scale })
        }
      }
    })

    if (this.screenRef.current) {
      this.resizeObserver.observe(this.screenRef.current)
    }
  }

  untrackScreenScale = () => {
    if (this.resizeObserver && this.screenRef.current) {
      this.resizeObserver.unobserve(this.screenRef.current)
      this.resizeObserver.disconnect()
    }
  }

  setSupportedVideoCodecs = () => {
    const supportedCodecs = []

    if (this.isH264Supported()) supportedCodecs.push('video/avc')

    supportedCodecs.push('image/jpeg')

    const currentCodecs = this.props.session.video_codecs || []
    const newCodecs = currentCodecs.length ? currentCodecs.filter(c => supportedCodecs.includes(c)) : supportedCodecs

    this.props.updateSession({ id: this.props.session.id, video_codecs: newCodecs })
  }

  isH264Supported = () => !!(window.MediaSource && window.MediaSource.isTypeSupported('video/mp4; codecs="avc1.4d002a"'))

  keepSessionAlive = () => {
    // free sessions don't extend
    if (this.showUpgrade()) return
    // backgrounded pages don't get extended
    if (document.hidden) return
    // if the device is not responding, don't extend the session
    if (!this.deviceIsOnline()) return
    // when we're nearly at the end of the session, trigger an extension
    const remaining = Session.remainingTime(this.props.session)
    if (remaining && remaining < 60 * 1000) {
      console.log('Marking session as still active')
      this.props.updateSession({ id: this.props.session.id })
    }
  }

  onToolUsed = (tool, event, bounds) => {
    this.props.onToolUsed(tool, event, bounds, this.display())
  }

  onActiveSessionRef = (el) => {
    this.el = el
  }

  onEditorRef = (el) => {
    this.editor = el
  }

  onResize = () => {
    this.forceUpdate()
  }

  onKeyPress = (e) => {
    if (this.props.tool !== SESSION_TOOL_TYPE_CONTROL) return
    this.onToolUsed('control', e)
    e.preventDefault()
  }

  onRemoteScreenSize = (size) => {
    if (size && ((this.state.height !== size.height) ||
      (this.state.width !== size.width))) {
      this.setState(size)
      this.props.onScreenResize(size)
      // once a size has been recieved, streaming has started
      // so we can kill the initial sync requesting
      clearInterval(this.syncInterval)
    }
  }

  onDOMChange = (dom) => {
    this.setState({ url: dom.document.url })
  }

  handleFullDevice = (checked) => {
    this.props.updateSession({ id: this.props.session.id, full_device: checked ? 'requested' : 'off' })
  }

  streamingHasStarted = () => {
    return !!this.state.width
  }

  showUpgrade = () => {
    return this.props.session.expiry_behaviour === 'upgrade'
  }

  sessionExpired = () => {
    if (!this.props.session.expires) return false
    return Session.remainingTime(this.props.session) <= 0
  }

  display = () => {
    const displays = Object.keys(this.props.displays)
    return this.state.display || displays[0]
  }

  changeTool = (tool) => {
    this.props.onToolSelected(tool)
    if (tool !== SESSION_TOOL_TYPE_CONTROL) this.props.onToolUsed('control', { type: 'mousemove' }, { x: -1000, y: -1000 })
  }

  clear = () => {
    if (this.editor) {
      this.editor.clear()
      this.onToolUsed('drawing', { id: null })
    }
  }

  onDOMError = (nodeId, error) => {
    this.props.onDOMError(nodeId, error, this.display())
  }

  deviceIsOnline = () => {
    // if a device goes AWOL for more than X seconds, display a warning
    const { metrics } = this.props.connectivity
    return ((!metrics.last_alive) || ((Date.now() - metrics.last_alive) < 10 * 1000))
  }

  performAction = (action) => {
    if (action === 'clear') this.clear()
    if (action === 'end') this.props.endSession(this.props.session)
  }

  computeScreenScale = (width, height) => {
    if (!this.el) return 1
    if (!(this.state.height && this.state.width)) return 1

    const containerWidthRatio = (width + 1) / this.state.width
    const containerHeightRatio = (height + 1) / this.state.height
    const scale = Math.min(containerWidthRatio, containerHeightRatio)

    return scale
  }

  changeDisplay = (display) => {
    this.setState({ display })
    this.props.sync()
  }

  renderDisplayOption = (display) => {
    const selected = display === this.display()
    return (
      <div key={display} style={{ border: selected ? '3px solid red' : '3px solid black', margin: 10 }} onClick={() => this.changeDisplay(display)}>
        <RemoteScreen height={60} scale={1} display={this.props.displays[display]} selectedColor={this.props.selectedColor} />
      </div>
    )
  }

  renderDisplayPicker = () => {
    const displays = Object.keys(this.props.displays)
    if (displays.length <= 1) return null
    return (
      <div className='flex justify-center'>
        {displays.map(this.renderDisplayOption)}
      </div>
    )
  }

  renderOverlayMessageComponent = (children) => {
    return (
      <SessionOverlayMessage>
        {children}
      </SessionOverlayMessage>
    )
  }

  renderOverlayMessage = () => {
    if (!EmbedParams.messages()) return null

    // development sessions are time-limited
    if (this.sessionExpired() && this.showUpgrade()) {
      return this.renderOverlayMessageComponent(<Trans>Development session expired<p className='text-base'>This limit only exists in development and testing.</p></Trans>)
    }

    if (!this.deviceIsOnline()) {
      return this.renderOverlayMessageComponent(this.props.t('Device is not responding'))
    }

    // show a message if the user has backgrounded the app
    if ((!Session.isFullDevice(this.props.session)) && this.props.screen?.state === 'background') {
      return this.renderOverlayMessageComponent(this.props.t('The app is running in the background on the users device'))
    }

    return null
  }

  renderAcceptanceMessages = () => {
    if (!EmbedParams.messages()) return null

    if (Session.isFullDevice(this.props.session)) {
      if (this.props.session.full_device === 'rejected') {
        return this.renderOverlayMessageComponent(
          <Trans>Full device was not allowed by the user. <ButtonLink className='px-4 py-1 text-slate-50 underline hover:text-slate-300' onClick={() => this.handleFullDevice(true)}>Request again.</ButtonLink></Trans>
        )
      }

      if (this.props.session.full_device !== 'on') {
        return this.renderOverlayMessageComponent(
          this.props.t('Waiting for the user to allow full device')
        )
      }
    }

    if (this.props.tool === SESSION_TOOL_TYPE_CONTROL) {
      if (this.props.session.remote_control === 'rejected') {
        return this.renderOverlayMessageComponent(
          <Trans>Remote control was not allowed by the user. <ButtonLink className='px-4 py-1 text-slate-50 underline hover:text-slate-300' onClick={this.props.requestRemoteControl}>Request again.</ButtonLink></Trans>
        )
      }

      if (this.props.session.remote_control !== 'on') {
        return this.renderOverlayMessageComponent(
          this.props.t('Waiting for the user to allow remote control')
        )
      }
    }

    return null
  }

  hasDeviceControlsVisible () {
    return EmbedParams.deviceControls() && this.streamingHasStarted() && this.props.tool === SESSION_TOOL_TYPE_CONTROL && this.props.session.device.platform === 'android'
  }

  renderRemoteScreen = () => {
    const style = {}
    const aspectStyle = {}

    const width = this.state.width * this.state.scale
    const height = this.state.height * this.state.scale
    const aspect = width / height

    if (!isNaN(aspect)) {
      aspectStyle.aspectRatio = aspect
    }

    if (!this.streamingHasStarted()) style.display = 'none'

    let toolsGutter = 0
    if (this.hasDeviceControlsVisible()) {
      toolsGutter += 32
    }

    if (Object.keys(this.props.displays).length > 1) {
      toolsGutter += 78
    }

    style.maxHeight = toolsGutter === 0 ? '100%' : `calc(100% - ${toolsGutter}px)`

    return (
      <div style={{ ...aspectStyle, ...style }} className='relative flex h-full max-w-full items-center justify-center p-px'>
        <div style={aspectStyle} className='relative flex max-h-full w-full items-center justify-center'>
          <div className={mergeClassNames('relative inline-block size-full select-none overflow-hidden rounded-lg shadow-[0_0_0_1px_rgba(133,132,145,0.25)]')} {...getTestId('screen-container')}>
            <div className={mergeClassNames('relative size-full bg-white', (this.renderOverlayMessage() || this.renderAcceptanceMessages()) && 'blur')} ref={this.screenRef}>
              <RemoteScreen
                key={this.display()}
                onSize={this.onRemoteScreenSize}
                scale={this.state.scale}
                toolHandler={this.onToolUsed}
                display={this.props.displays[this.display()]}
                mouse={this.props.mouse}
                onDOMChange={this.onDOMChange}
                tool={this.props.tool}
                capabilities={SessionCapability.all(this.props.session)}
                proxy={this.props.proxy}
                selectedColor={this.props.selectedColor}
                onDOMError={this.onDOMError}
                width={this.state.width}
                height={this.state.height}
              />
              <AnnotationEditor
                ref={this.onEditorRef}
                disable={(Session.isFullDevice(this.props.session) || this.props.tool === SESSION_TOOL_TYPE_CONTROL) && this.props.session.device.platform === 'web'}
                touch={['android', 'ios'].includes(this.props.session.device.platform)}
                tool={this.props.tool}
                height={this.state.height}
                width={this.state.width}
                rotation={this.state.rotation}
                scale={this.state.scale}
                toolHandler={this.onToolUsed}
                selectedColor={this.props.selectedColor}
                allowMultipleDrawings={hasDisappearingInkToolEnabled(this.props.session)}
              />
            </div>
            {this.renderOverlayMessage() || this.renderAcceptanceMessages()}
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { metrics } = this.props.connectivity

    return (
      <div className={mergeClassNames('relative flex h-full max-h-full flex-1 flex-col items-center overflow-hidden bg-slate-900 p-4 pr-0 md:max-h-screen', !EmbedParams.hasSessionUI() && 'bg-transparent p-0 md:p-0')}>
        <div className={mergeClassNames('relative flex size-full max-h-screen flex-1 flex-col items-center gap-4')}>
          <div className={mergeClassNames('relative flex size-full min-w-0 flex-1 flex-col md:flex-auto')}>
            <div className='flex h-full'>
              <div className={mergeClassNames('relative flex flex-1 grow flex-col items-center justify-center overflow-hidden pr-4 md:flex-auto md:shrink', EmbedParams.hasSessionUI() && 'max-h-[calc(100vh-96px)]', !EmbedParams.hasSessionUI() && 'pr-0 md:pr-0')} ref={this.onActiveSessionRef}>
                {this.renderRemoteScreen()}
                {this.hasDeviceControlsVisible() && (
                  <AndroidControls
                    onToolUsed={this.onToolUsed}
                    width={this.state.width}
                    scale={this.state.scale}
                  />
                )}
                <DisplayPicker
                  displays={this.props.displays}
                  activeDisplayKey={this.display()}
                  changeDisplay={this.changeDisplay}
                />
                {this.streamingHasStarted() ? null : <Loading />}
              </div>
              {EmbedParams.sessionDetails() && (
                <Sidebar
                  Header={<DeviceStatus
                    device={{ device: this.props.session.device, custom_data: this.props.session.custom_data }}
                    isOnline
                    hideRealtimeInfo
                    identifierClassName={tw`min-w-0 max-md:text-lg`}
                          />}
                >
                  {this.state.url && <URLView url={this.state.url} />}
                  <DeviceInfo
                    device={{ ...this.props.session.device, rtt: `${metrics.rtt || Infinity} ms` }}
                    customData={this.props.session.custom_data}
                    isTableView
                  />
                </Sidebar>
              )}
            </div>

          </div>
          <div className={mergeClassNames('flex w-full pr-4', !EmbedParams.hasSessionUI() && 'hidden')}>
            <SessionToolbar
              selectedTool={this.props.tool}
              session={this.props.session}
              control={this.props.allowControl}
              allowFullDevice={this.props.allowFullDevice}
              onToolSelected={this.changeTool}
              onFullDeviceChanged={this.handleFullDevice}
              performAction={this.performAction}
              user={this.props.user}
              account={this.props.account}
              selectedColor={this.props.selectedColor}
              onColorPicked={this.props.onColorPicked}
              showUpgrade={this.showUpgrade() && EmbedParams.deviceControls()}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(withTranslation()(ActiveSession))
