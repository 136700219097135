import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { buttonClassName } from './Button'

const LinkButton = forwardRef(({ className, variant, env, isFullWidth, size, children, disabled, to, ...props }, ref) => (
  <RouterLink
    ref={ref}
    className={buttonClassName({ variant, className, env, isFullWidth, size })}
    {...(disabled ? { 'data-disabled': true } : {})}
    to={!disabled ? to : ''}
    {...props}
  >
    {children}
  </RouterLink>
))

export default LinkButton
