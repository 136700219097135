import React from 'react'
import DeviceStatus from './DeviceStatus'
import { Icon } from '../../components/Icon'

export function DeviceHeader ({ device, showChevron = false, identifierClassName, isLoading, isOnline, children }) {
  if (!device) return null

  return (
    <div className='flex w-full items-center justify-between text-slate'>
      <DeviceStatus device={device} isLoading={isLoading} isOnline={isOnline} identifierClassName={identifierClassName}>
        {children}
      </DeviceStatus>
      {showChevron && <Icon type='chevron-up' className='rotate-90 md:hidden' size='small' />}

    </div>
  )
}
