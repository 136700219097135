import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import LinkButton from './LinkButton'
import { useTranslation } from 'react-i18next'
import { Popover } from '@headlessui/react'
import PopoverContainer from './PopoverContainer'
import UserIcon from '../modules/users/UserIcon'
import { Icon } from './Icon'

const Divider = () => (
  <div className='border-t border-gray-200' />
)

function useOrganizationName (account) {
  const { t } = useTranslation()

  return account.organisation_name || t('Unnamed account')
}

function AccountInfo ({ computedName, email }) {
  return (
    <div className='flex flex-col gap-y-1 pt-2'>
      <p className='text-gray-950'>{computedName}</p>
      <p className='text-xs text-gray-700'>{email}</p>
    </div>
  )
}

function AccountSwitcher ({ accounts, setActiveAccount, currentAccount }) {
  const sortedAccounts = accounts.sort((a, b) => {
    if (!a.organisation_name) {
      return a.id.localeCompare(b.id) + 1
    }

    return a.organisation_name.localeCompare(b.organisation_name)
  })

  return (
    <>
      <Divider />
      <div className='grid gap-y-2 overflow-y-auto p-2'>
        {sortedAccounts.map((account) => <Account account={account} key={account.id} setActiveAccount={setActiveAccount} currentAccount={currentAccount} />)}
      </div>
    </>
  )
}

const Account = ({ account, setActiveAccount, currentAccount }) => {
  const orgName = useOrganizationName(account)
  const isSelected = account.id === currentAccount.id

  return (
    <LinkButton
      to={window.location}
      key={account.id}
      variant={isSelected ? 'primary' : 'text'}
      className={mergeClassNames('h-auto cursor-pointer py-3 font-normal', isSelected && 'justify-between bg-slate-100 text-slate-900 hover:bg-gray-100')}
      onClick={() => { setActiveAccount(account); return false }}
    >
      {orgName}
      {isSelected && <Icon type='circle-check' size='small' className='text-violet' />}
    </LinkButton>
  )
}

const AccountMenu = ({ user, organisationName, currentAccount, accounts, showContact, setActiveAccount, logout, buttonClassName, containerClassName, children, ...props }) => {
  const { t } = useTranslation()
  const currentOrgName = useOrganizationName(currentAccount)

  const { name, username } = user

  // If the user's name is their email or the local part of it, we will just
  // show the email address.
  const emailSegments = username.split('@')
  const customNameSet = name !== username && emailSegments[0] !== name
  const computedName = customNameSet ? name : username
  const hasMultipleAccounts = accounts.length > 1

  return (
    <div className='relative'>
      <Popover>
        <Popover.Button as='button' className={mergeClassNames('outline-none focus-visible:rounded-lg focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-violet', buttonClassName)}>
          <div className='flex items-center justify-center gap-x-2 overflow-hidden'>
            <div className='max-w-24 text-right text-xs md:max-w-44'>
              <p className='overflow-hidden text-ellipsis text-nowrap text-slate-900'>{computedName}</p>
              <p className='overflow-hidden text-ellipsis text-nowrap text-gray-700'>{currentOrgName}</p>
            </div>
            <UserIcon user={user} className='size-9 outline-0 lg:size-10' />
          </div>
        </Popover.Button>
        <Popover.Panel>
          <PopoverContainer
            className={mergeClassNames('absolute z-50 w-auto rounded-lg bg-white text-left', containerClassName)}
            show
            hideArrow
            {...props}
          >
            <div className='flex min-w-72 flex-1 flex-col rounded-lg bg-white'>
              <div className='px-6 py-2'>
                <AccountInfo computedName={computedName} email={user?.email} />
              </div>
              <div className='empty:hidden'>
                {hasMultipleAccounts && <AccountSwitcher accounts={accounts} setActiveAccount={setActiveAccount} currentAccount={currentAccount} />}
              </div>
              <Divider />
              <div className='p-2'>
                <LinkButton variant='text' to='/' onClick={logout} className='justify-between font-normal text-slate-950'>
                  {t('Logout')}
                  <Icon type='logout' size='small' />
                </LinkButton>
              </div>
              {showContact && (
                <div className='p-2 empty:hidden'>
                  <LinkButton variant='text' to='mailto:hello@cobrowse.io' className='justify-between font-normal text-slate-950'>
                    {t('Contact us')}
                    <Icon type='comment-1' size='small' />
                  </LinkButton>
                </div>
              )}
            </div>
          </PopoverContainer>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default AccountMenu
