import React from 'react'
import mergeClassNames from '../../utils/mergeClassNames'
import { useHasPaidSubscription } from '../../hooks/useHasPaidSubscription'

const AccountIdentifiers = ({ account }) => {
  const hasPaidSubscription = useHasPaidSubscription(account)

  return (
    <div className='flex flex-wrap gap-2 text-xs'>
      <span className={mergeClassNames(hasPaidSubscription && 'text-green-500')}>{account?.license_key}</span>
      <span>{account?.id}</span>
    </div>
  )
}

export default AccountIdentifiers
