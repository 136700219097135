import React, { useCallback, useContext, useRef, createContext, useState } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../utils/getTestId'
import Account from '../modules/accounts/Account.state'
import useActiveAccountSelector from '../hooks/useActiveAccountSelector'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import Button, { buttonClassName } from '../components/Button'
import { Popover } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import AccountMenu from '../components/AccountMenu'
import User from '../modules/users/User.state'
import PopoverContainer from '../components/PopoverContainer'
import GlobalError from '../components/GlobalError'
import UpgradeBanner, { showUpgradeBanner } from '../modules/billing/UpgradeBanner'
import SkipToMainContent from '../components/SkipToMainContent'
import EmbedParams from '../utils/EmbedParams'
import { Icon } from '../components/Icon'
import { useHideOnScroll } from '../hooks/useHideOnScroll'

const ScrollContext = createContext()

function NavBarButton ({ id, iconType, text, route, className, renderText = false, iconClassName, textClassName, size, ...props }) {
  const location = useLocation()

  return (
    <NavLink
      aria-labelledby={id}
      title={text}
      className={({ isActive }) => buttonClassName({ variant: isActive ? 'primary' : 'text', size, className, overrides: !isActive ? 'text-gray-600 hover:text-gray-800' : null })}
      to={`${route}${location.search}`}
      {...getTestId(id)}
      {...props}
    >
      {({ isActive }) => (
        <>
          <Icon type={iconType} />
          <span id={id} className={!renderText ? 'sr-only' : mergeClassNames('text-gray-800 hover:text-gray-800', isActive && 'text-slate-50 hover:text-slate-50', textClassName)}>{text}</span>
        </>
      )}
    </NavLink>
  )
}

function NavItems ({ renderText = false, ...props }) {
  const { t } = useTranslation()

  return (
    <>
      <NavBarButton
        id='navigation-devices'
        iconType='mobile'
        text={t('Connect')}
        route='/dashboard'
        end
        renderText={renderText}
        {...props}
      />
      <NavBarButton
        id='navigation-sessions'
        iconType='history'
        text={t('Sessions')}
        route='/dashboard/history'
        renderText={renderText}
        {...props}
      />
      <NavBarButton
        id='navigation-settings'
        iconType='cog'
        text={t('Settings')}
        route='/dashboard/settings'
        renderText={renderText}
        {...props}
      />
    </>
  )
}

function MobileNav ({ buttonClassName: buttonClassNameProp }) {
  const { t } = useTranslation()
  return (
    <Popover className='relative md:hidden'>
      {({ close }) => (
        <>
          <Popover.Button as={Button} variant='text' size='small' isIconButton className={buttonClassNameProp}>
            <div className='relative'>
              <span className='sr-only'>{t('Open main menu')}</span>
              <div className='absolute left-1/2 top-1/2 block w-4 -translate-x-1/2 -translate-y-1/2'>
                <span aria-hidden='true' className='absolute block h-0.5 w-4 bg-gray-600 transition duration-300 ease-in-out ui-open:rotate-45 ui-not-open:-translate-y-1.5 motion-reduce:transition-none' />
                <span aria-hidden='true' className='absolute block h-0.5 w-4 bg-gray-600 transition duration-300 ease-in-out ui-open:opacity-0 motion-reduce:transition-none' />
                <span aria-hidden='true' className='absolute block h-0.5 w-4 bg-gray-600 transition duration-300 ease-in-out ui-open:-rotate-45 ui-not-open:translate-y-1.5 motion-reduce:transition-none' />
              </div>
            </div>
          </Popover.Button>

          <Popover.Panel>
            <PopoverContainer show hideArrow className='top-9 grid min-w-[200px] gap-y-4 rounded-xl bg-white p-4'>
              <NavItems renderText iconClassName='size-4' size='big' onClick={close} />
              <UpgradeBanner onCloseClick={close} />
            </PopoverContainer>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

function DesktopNav () {
  const { t } = useTranslation()
  const [isUpgradeVisible, setIsUpgradeVisible] = useState(true)
  const account = useActiveAccountSelector()

  const toggleUpgradePopover = () => {
    setIsUpgradeVisible(state => !state)
  }

  const showUpgrade = showUpgradeBanner(account)

  return (
    <nav className='grid w-0 place-content-between overflow-hidden border-gray-200 bg-gray-50 p-0 opacity-50 transition-all duration-150 motion-reduce:transition-none md:visible md:w-auto md:overflow-visible md:border-r md:p-3 md:opacity-100'>
      <div className='grid gap-y-2.5'>
        <NavItems className='hidden max-lg:px-2 md:flex' iconClassName='hidden md:block' renderText textClassName='hidden lg:inline' size='medium' />
      </div>

      {showUpgrade && (
        <Popover className='max-md:hidden'>
          <Popover.Button
            as={Button}
            variant='secondary'
            isIconButton
            size='small'
            onClick={toggleUpgradePopover}
            className='lg:hidden'
          >
            <Icon type='upgrade' />
            <span className='sr-only'>{t('Upgrade')}</span>
          </Popover.Button>
          <Popover.Panel
            className='absolute bottom-3 left-[72px] z-30 lg:relative lg:bottom-0 lg:left-0'
            static
          >
            <UpgradeBanner className={mergeClassNames('w-[238px] lg:w-[200px]', !isUpgradeVisible && 'hidden')} showCloseButton onCloseClick={toggleUpgradePopover} buttonClassName='lg:hidden' />
          </Popover.Panel>
        </Popover>
      )}
    </nav>
  )
}

export function DashboardLayout () {
  const ui = useSelector(state => state.ui)
  const user = useSelector(state => User.fromState(state))
  const account = useActiveAccountSelector()
  const accounts = useSelector(state => Account.fromState(state))
  const dispatch = useDispatch()
  const location = useLocation()

  const headerRef = useRef()
  const scrollableRef = useRef()

  const logout = () => {
    dispatch(User.logout())
      .then(() => {
        window.location = '/'
      })
  }

  const setActiveAccount = (account) => {
    dispatch(Account.setActiveAccount(account))
  }

  const showNavigation = EmbedParams.navigation()
  const isSettingsPage = location.pathname.includes('/settings')

  const hasHiddenMenuByScroll = useHideOnScroll(scrollableRef)

  const scrollToTop = useCallback(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  const opacityAnimationClassNames = mergeClassNames('opacity-100 transition-opacity duration-300 motion-reduce:transition-none', hasHiddenMenuByScroll && 'opacity-0')

  return (
    <div className='flex h-full overflow-hidden'>
      <SkipToMainContent />
      {showNavigation && <DesktopNav />}
      <div className='min-w-0 flex-1'>
        {showNavigation && (
          <header
            className={mergeClassNames('relative z-20 flex max-h-[67px] w-full origin-bottom scale-y-100 items-center justify-between border-b border-gray-200 bg-white p-3 opacity-100 transition-all duration-150 ease-in-out motion-reduce:transition-none md:border-none md:px-4 md:pb-1 md:pt-3', hasHiddenMenuByScroll && 'max-h-0 scale-y-0 overflow-hidden border-0 py-0 opacity-0 md:py-0', isSettingsPage && 'border-none')}
            ref={headerRef}
          >
            <div className='flex items-center gap-x-1'>
              <MobileNav buttonClassName={opacityAnimationClassNames} />
              {ui.pageTitle !== '' && <h1 className={mergeClassNames('text-2xl font-medium', opacityAnimationClassNames)}>{ui.pageTitle}</h1>}
              {isSettingsPage && <div className='self-end pb-1 ps-1 text-xs font-medium text-gray-700'>v{process.env.REACT_APP_VERSION}</div>}
            </div>
            {ui.user_info && (
              <AccountMenu
                buttonClassName={mergeClassNames('outline-none', opacityAnimationClassNames)}
                containerClassName='end-0 top-12'
                user={user.resource}
                organisationName={account.resource.organisation_name}
                currentAccount={account.resource}
                accounts={accounts.collection}
                setActiveAccount={setActiveAccount}
                showContact={ui.show_contact}
                logout={logout}
              />

            )}
          </header>
        )}
        <main id='main-content' className='size-full overflow-y-auto bg-white' ref={scrollableRef}>
          <GlobalError fixed={false} hideChildrenIfBlocking>
            <ScrollContext.Provider value={scrollToTop}>
              <Outlet />
            </ScrollContext.Provider>
          </GlobalError>
        </main>
      </div>
    </div>
  )
}

export const useScrollToTop = () => useContext(ScrollContext)
