import React from 'react'
import { Icon } from '../../components/Icon'

const DeviceCount = ({ account }) => {
  return (
    <div className='flex items-center gap-x-1 text-xs'>
      <Icon type='device-mobile' size='small' className='text-gray-700' />
      <span>{account.devices_active}</span>
    </div>
  )
}

export default DeviceCount
