import moment from 'moment'
import { throttle } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AuditEvent from '../audit/AuditEvent'
import ErrorMessage from '../errors/ErrorMessage'
import Loading from '../../components/Loading'
import LoadingScreen from '../../components/LoadingScreen'
import SessionActionView from './SessionAction'
import Session from '../sessions/Session.state'
import SessionRecording from './SessionRecording.state'
import SessionAction from './SessionAction.state'
import SessionVideo from './SessionVideo'
import { getTestId } from '../../utils/getTestId'
import { useParams } from 'react-router-dom'
import mergeClassNames from '../../utils/mergeClassNames'
import SessionOverview from '../sessions/SessionOverview'
import useDocumentTitle from '../../hooks/useDocumentTitle'

const SessionRecordingScreen = () => {
  const [playable, setPlayable] = useState(false)
  const [videoError, setVideoError] = useState(null)
  const recording = useSelector(state => SessionRecording.fromState(state))
  const actions = useSelector(state => SessionAction.fromState(state))
  const session = useSelector(state => Session.fromState(state))
  const dispatch = useDispatch()
  const { id } = useParams()
  const { t } = useTranslation()
  const containerRef = useRef()
  const eventsPanelRef = useRef()
  const previousYPositionRef = useRef(0)
  const [eventsPanelExpanded, setEventsPanelExpanded] = useState(false)

  useDocumentTitle(t('Session recording'))

  useEffect(() => {
    dispatch(Session.actionCreators().getSession({ id }))
    dispatch(SessionRecording.actionCreators().getSessionRecording({ id }))
      .then(r => dispatch(SessionAction.actionCreators().getSessionActions(r)))
  }, [dispatch, id])

  const toggleEventsPanel = useCallback((target, direction) => {
    // If we are at the top of the events panel and we're scrolling up, shrink the panel
    if (eventsPanelRef.current.contains(target) && direction === 'down' && eventsPanelExpanded && eventsPanelRef.current.scrollTop === 0) {
      setEventsPanelExpanded(false)

      return
    }

    if (!eventsPanelRef.current.contains(target)) {
      if (direction === 'up' && !eventsPanelExpanded) {
        setEventsPanelExpanded(true)
      } else if (direction === 'down' && eventsPanelExpanded) {
        setEventsPanelExpanded(false)
      }
    }
  }, [eventsPanelExpanded])

  const onScroll = useCallback((e) => {
    const target = e.target
    const direction = e.deltaY > 0 ? 'up' : 'down'

    toggleEventsPanel(target, direction)
  }, [toggleEventsPanel])

  const onTouchMove = useCallback((e) => {
    const target = e.target
    const currentYPosition = e.touches[0].clientY
    const direction = currentYPosition > previousYPositionRef.current ? 'down' : 'up'

    toggleEventsPanel(target, direction)
  }, [toggleEventsPanel])

  const throttledOnScroll = throttle(onScroll, 400)
  const throttledOnTouchMove = throttle(onTouchMove, 400)

  useEffect(() => {
    const onTouchStart = (e) => {
      previousYPositionRef.current = e.touches[0].clientY
    }

    // Disable mobile scroll bounce effect
    document.body.style.position = 'fixed'
    document.body.style.overflow = 'hidden'
    document.body.style.inset = '0px'

    const container = containerRef?.current

    window.addEventListener('wheel', throttledOnScroll, { passive: true })
    container?.addEventListener('touchstart', onTouchStart, { passive: true })
    container?.addEventListener('touchmove', throttledOnTouchMove, { passive: true })

    return () => {
      window.removeEventListener('wheel', throttledOnScroll)
      container?.removeEventListener('touchstart', onTouchStart)
      container?.removeEventListener('touchmove', throttledOnTouchMove)
    }
  }, [throttledOnScroll, throttledOnTouchMove])

  const onVideoCanPlay = () => {
    setPlayable(true)
  }

  const onVideoError = (error) => {
    if (error) {
      console.log('Error during playback', error.nativeEvent)
    }

    setVideoError(error)
  }

  const isRecordingReady = () => {
    return 'error' in session &&
      !session.working &&
      'error' in recording &&
      !recording.working &&
      'error' in actions &&
      !actions.working
  }

  const renderError = (error) => {
    return (
      <ErrorMessage error={error}>
        {t('Sorry, something went wrong loading this recording.')}
      </ErrorMessage>
    )
  }

  const renderEvent = (event, id) => {
    if (event.type === 'AuditEvent') {
      return <AuditEvent event={event} key={id} variant='recording' />
    } else {
      return (
        <SessionActionView
          key={id}
          agent={session.resource.agent}
          event={event}
          variant='recording'
        />
      )
    }
  }

  const renderEventContainer = (event, id) => {
    const ms = Math.max(0, moment(event.timestamp).diff(session.resource.activated))

    // Omit events that are not meant to be displayed. Otherwise, we would end up with a bunch of
    // empty divs, affecting the continuous line of the timeline.
    if (event.type === 'SessionAction' && event.action === 'keypress' && event.target) {
      return false
    }

    return (
      <div key={id} className='group/list-item flex min-w-0 justify-between gap-x-1 py-2' data-event-id={id}>
        {renderEvent(event, id)}
        <div className='text-right text-xs first:hidden'>{moment.utc(ms).format('m:ss')}</div>
      </div>
    )
  }

  const renderEvents = () => {
    if (actions.working) {
      return <Loading />
    }

    if (recording.working) {
      return <Loading />
    }

    const merged = [...recording.resource?.events || [], ...actions.collection]

    merged.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())

    if (merged.length === 0) {
      return t('No events found')
    }

    return (
      <>
        <div className='text-xs uppercase text-gray-700'>{t('Audit trail')}</div>
        <div className='grid gap-2'>{merged.map(renderEventContainer)}</div>
      </>
    )
  }

  const renderVideoOverlay = () => {
    if ((!playable) && videoError) {
      return renderVideoOverlayComponent(t('A video is not available for this session.'))
    }

    if (!playable) {
      return renderVideoOverlayComponent(<div><Loading spinnerClassName='before:border-t-white before:border-r-white'>{t('Generating a video for this session')}</Loading></div>)
    }

    return null
  }

  const renderVideoOverlayComponent = (children) => {
    return <div className='absolute inset-0 z-10 flex items-center justify-center bg-[#000000] px-4 text-base text-white'>{children}</div>
  }

  const renderVideo = () => {
    return (
      <SessionVideo
        className='h-full bg-inherit outline-none'
        controls
        autoPlay
        src={recording.resource?.video}
        onError={onVideoError}
        onCanPlay={onVideoCanPlay}
      />
    )
  }

  const renderSessionInfo = () => {
    const info = session.resource
    const ip = recording.resource?.events?.find((event) => !!event.ip)?.ip

    return (
      <div className='flex flex-wrap items-center gap-1 text-sm'>
        <SessionOverview session={info} ip={ip} variant='recording' />
      </div>
    )
  }

  if (!isRecordingReady()) {
    return <LoadingScreen message={t('Loading recording')} />
  }

  if (recording.error) {
    return renderError(recording.error)
  }

  if (session.error) {
    return renderError(session.error)
  }

  return (
    <div className='h-full bg-slate-900 p-4' ref={containerRef}>
      <h1 className='sr-only'>{t('Session recording')}</h1>
      <div className='relative flex h-full flex-col gap-4 md:flex-row lg:justify-between'>
        <div
          className={mergeClassNames('relative flex min-h-[80vh] flex-1 items-center justify-center overflow-hidden rounded-lg border-2 border-black/15 bg-[#000000] shadow transition-all duration-300 ease-in-out retina:border-1.5 motion-reduce:transition-none', eventsPanelExpanded && 'min-h-[20vh]', !playable && 'w-full')}
          data-video-available={playable}
        >
          {renderVideoOverlay()}
          {renderVideo()}
        </div>
        <div
          className={mergeClassNames('scrollbar-hidden pointer-events-none sticky mt-4 flex flex-col gap-y-6 overflow-auto rounded-t-lg bg-white p-3 shadow md:pointer-events-auto md:mt-0 md:w-[335px] md:rounded-lg', eventsPanelExpanded && 'pointer-events-auto')}
          {...getTestId('session-recording-events')}
          ref={eventsPanelRef}
        >
          {renderSessionInfo()}
          <div className='grid gap-1'>{renderEvents()}</div>
        </div>
      </div>
    </div>
  )
}

export default SessionRecordingScreen
