import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { RadioGroup } from '@headlessui/react'

const SegmentedInputRoot = ({ className, value, onChange, children }) => (
  <RadioGroup
    className={mergeClassNames('flex max-w-fit items-center whitespace-nowrap rounded-full bg-gray-50 p-1', className)}
    value={value}
    onChange={onChange}
  >
    {children}
  </RadioGroup>
)

const Option = ({ value, children, ...props }) => {
  return (
    <RadioGroup.Option value={value} {...props} className='group'>
      <div className='relative flex h-full'>
        <RadioGroup.Label
          className='flex w-full cursor-pointer items-center gap-x-1 rounded-full bg-gray-50 px-3 py-1.5 text-sm text-slate ui-checked:bg-slate ui-checked:text-slate-50'
        >
          {children}
        </RadioGroup.Label>
      </div>
    </RadioGroup.Option>
  )
}

export const SegmentedInput = Object.assign(SegmentedInputRoot, { Option })
