/* eslint-disable react/jsx-handler-names */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import Session from './Session.state'
import SessionListDownloadFilters from './SessionListDownloadFilters'
import { Popover } from '@headlessui/react'
import Button from '../../components/Button'
import { Icon } from '../../components/Icon'

const SessionListDownload = ({ filter, className }) => {
  const { t } = useTranslation()
  const [working, setWorking] = useState(false)
  const [error, setError] = useState({
    show: false,
    severity: 'warning',
    message: ''
  })

  const loadFilteredSessions = async (dates, accumulator = []) => {
    const last = accumulator[accumulator.length - 1]
    const res = await Session.fetch(Session.types.GET, {}, {
      agent: 'all',
      state: ['ended'],
      ...filter,
      activated_after: dates[0],
      activated_before: last?.activated ?? dates[1],
      limit: 1000
    })
    const sessions = await res.json()

    accumulator = [...accumulator, ...sessions]

    return sessions.length === 0
      ? accumulator
      : loadFilteredSessions(dates, accumulator)
  }

  const formatSession = (session) => {
    if (!session.agent) return null
    if (!session.activated) return null
    const filtered = {
      ...session.custom_data,
      device_locale: session.device.device_locale || '',
      os_version: session.device.os_version || '',
      os_api_level: session.device.os_api_level || '',
      app_id: session.device.app_id || '',
      app_name: session.device.app_name || '',
      app_version: session.device.app_version || '',
      app_build: session.device.app_build || '',
      sdk_version: session.device.sdk_version || '',
      platform: session.device.platform || '',
      agent_name: session.agent.name || '',
      agent_email: session.agent.email || '',
      session_id: session.id,
      session_created: moment(session.created).format('YYYY-MM-DD HH:mm:ss'),
      session_ended: moment(session.ended).format('YYYY-MM-DD HH:mm:ss')
    }
    delete filtered.cobrowseio_device_id
    delete filtered.cobrowseio_deviceid
    return filtered
  }

  const generateBlanks = (sessions) => {
    let allKeys = {}
    for (let i = 0; i < sessions.length; i += 1) {
      allKeys = { ...allKeys, ...sessions[i] }
    }
    Object.keys(allKeys).forEach(key => {
      allKeys[key] = ''
    })
    return allKeys
  }

  const applyDefaults = (defaults, sessions) => {
    return sessions.map(session => {
      return { ...defaults, ...session }
    })
  }

  const downloadHandler = async (sessions) => {
    setWorking(true)
    setError({
      ...error,
      show: false
    })

    try {
      const formattedSessions = (await sessions).map(formatSession).filter(v => v)

      if (formattedSessions.length === 0) {
        setError({
          show: true,
          severity: 'warning',
          message: t('No sessions found for the provided dates.')
        })

        return
      }

      const csvConfig = mkConfig({
        filename: 'sessions',
        useKeysAsHeaders: true,
        showLabels: true
      })

      const csv = generateCsv(csvConfig)(applyDefaults(
        generateBlanks(formattedSessions),
        formattedSessions
      ))
      download(csvConfig)(csv)
    } catch (e) {
      console.error(e)

      setError({
        show: true,
        severity: 'error',
        message: t('Error downloading sessions.')
      })
    } finally {
      setWorking(false)
    }
  }

  return (
    <Popover className={className}>
      {({ open }) => (
        <>
          <Popover.Button
            as={Button}
            variant={open ? 'tertiary' : 'text'}
            size='small'
            thinking={working}
          >
            <Icon type='download' size='large' />
            <span className='sr-only'>{t('Download')}</span>
          </Popover.Button>
          <Popover.Panel
            className='absolute end-0 right-4 z-10 mt-2 rounded-md bg-white p-4 shadow-menus focus:outline-none'
          >
            <SessionListDownloadFilters
              onDownload={(dates) => downloadHandler(loadFilteredSessions(dates))}
              thinking={working}
              error={error}
            />
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default SessionListDownload
