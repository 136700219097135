import React from 'react'
import { AuthContainer } from './AuthContainer'
import { Icon } from '../../components/Icon'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const MagicLinkSuccessScreen = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const process = location.state?.process

  return (
    <AuthContainer>
      <div className='flex h-screen flex-col items-center justify-center gap-y-2 text-sm'>
        <Icon type='mail-forward' size='large' className='text-violet' />
        <h1 className='text-slate'>{t('We have sent you a login link!')}</h1>
        <p className='max-w-[300px] text-center text-gray-700'>
          {t('Please check your email to complete your {{process}}', { process: process === 'register' ? t('registration') : t('login') })}
        </p>
      </div>
    </AuthContainer>
  )
}

export default MagicLinkSuccessScreen
