import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonLink from '../../components/ButtonLink'
import FilterParamsEditor from '../filters/FilterParamsEditor'
import SessionListDownload from './SessionListDownload'
import StandardFields from '../devices/StandardFields'
import StandardList from '../../components/StandardList'
import FilterParams from '../filters/FilterParams'
import Session from './Session'
import { DeviceInfoSidebar } from '../../components/DeviceInfoSidebar'
import { tw } from '../../utils/tw'
import { uniq } from 'lodash'
import Alert from '../../components/Alert'
import { SessionAction } from './SessionAction'
import moment from 'moment/moment'
import { SessionHeader } from './SessionHeader'
import { useSetFilters } from '../filters/useSetFilters'
import { useLoadMore } from '../../hooks/useLoadMore'

const SessionList = ({ refresh, sessions, reset }) => {
  const { t } = useTranslation()

  const filterEditor = useRef()
  const sidebarTriggerContainer = useRef(null)

  const { setParams } = useSetFilters()

  const [expandedSession, setExpandedSession] = useState(null)

  const filterSessions = useCallback(() => {
    return {
      filter: FilterParams.search,
      ...FilterParams.parse()
    }
  }, [])

  const refreshSessions = useCallback(() => {
    refresh(filterSessions())
  }, [refresh, filterSessions])

  useEffect(() => {
    refreshSessions()

    return () => {
      // Clear sessions from store to improve route change speed perception when
      // coming back to this route
      reset()
    }
  }, [refreshSessions, reset])

  const suggestionsFor = useCallback((field) => {
    if (!sessions) return []

    const suggestions = sessions.collection.map(d => d.custom_data && d.custom_data[field]).filter(v => v)

    return uniq(suggestions.map(v => `${v}`))
  }, [sessions])

  const fetchData = useCallback((last) => {
    return refresh({
      ...filterSessions(),
      activated_before: last.activated
    }, { merge: true })
  }, [filterSessions, refresh])

  const { hasMore, hasError, loadMore } = useLoadMore(sessions, fetchData)

  const clearFilters = () => {
    filterEditor.current.clearFilters()
  }

  const addFilter = useCallback((key, value) => {
    setParams({ ...FilterParams.params, [key]: value })
    filterEditor.current.setParams(FilterParams.search, FilterParams.params)
    refreshSessions()
  }, [refreshSessions, setParams])

  const renderSession = (session) => {
    return <Session key={session.id} session={session} addFilter={addFilter} setExpandedSession={setExpandedSession} />
  }

  const renderEmptyList = () => {
    if (sessions.working) {
      return null
    }

    if (FilterParams.search || !FilterParams.empty()) {
      return (
        <div className='mt-5 text-center'>
          <span>{t("There aren't any sessions that match your filters.")}</span>{' '}
          <ButtonLink className='underline' onClick={clearFilters}>{t('Clear filters')}</ButtonLink>
        </div>
      )
    }

    return (
      <div className='mt-5 text-center'>
        <span>{t("There aren't any sessions associated with your account.")}</span>
      </div>
    )
  }

  const sortedSessions = sessions.collection.sort((a, b) => {
    if (a.state === 'active' && b.state !== 'active') return -1
    if (b.state === 'active' && a.state !== 'active') return 1
    return (new Date(b.created)).getTime() - (new Date(a.created)).getTime()
  })

  return (
    <>
      <div className='sticky top-0 z-10 grid gap-2 border-b border-gray-200 bg-white p-3 md:flex md:justify-between md:gap-4 md:px-4 md:py-3'>
        <FilterParamsEditor
          ref={filterEditor}
          loading={sessions.working}
          placeholder={t('Filter sessions...')}
          autocompleteFields={StandardFields.customData()}
          onUpdated={refreshSessions}
          MobileAction={<SessionListDownload filter={filterSessions()} className='md:hidden' />}
          mobileRightShadowClassName='right-16'
          getSuggestions={suggestionsFor}
        />
        <SessionListDownload filter={filterSessions()} className='max-md:hidden' />
      </div>
      <div ref={sidebarTriggerContainer}>
        <StandardList
          loading={sessions.working}
          items={sortedSessions}
          renderItem={renderSession}
          renderEmptyList={renderEmptyList}
          onBottomReached={loadMore}
          testId='session-list'
          listContainerClassName='md:pb-24'
          hasMore={hasMore}
          hasError={hasError}
        />

        <DeviceInfoSidebar
          device={expandedSession}
          addFilter={addFilter}
          Header={<SessionHeader session={expandedSession} identifierClassName={tw`md:grid`} />}
          HeaderAction={
            <>
              <SessionAction session={expandedSession} className='mt-3' />
              {expandedSession?.recording?.expires && !expandedSession?.recording?.deleted && (
                <Alert
                  type='warning'
                  icon='warning'
                  className='mt-3'
                >
                  {t('The recording of this session expires {{time}}', { time: moment(expandedSession.recording.expires).fromNow() })}
                </Alert>)}
            </>
          }
          sidebarTriggerContainer={sidebarTriggerContainer}
        />
      </div>
    </>
  )
}

export default SessionList
