import React from 'react'
import UserIcon from '../users/UserIcon'
import mergeClassNames from '../../utils/mergeClassNames'
import SessionOverview from './SessionOverview'

export function SessionStatus ({ session, identifierClassName, children }) {
  return (
    <div className='flex'>
      <div className='me-2 flex items-center lg:me-4'>
        <UserIcon className='size-8' user={session.agent} />
      </div>
      <div className='grid gap-y-2'>
        <div className={mergeClassNames('grid flex-1 items-center gap-x-2 gap-y-1 text-sm text-gray-700 md:flex', identifierClassName)}>
          <SessionOverview session={session} />
        </div>
        {children}
      </div>
    </div>
  )
}
