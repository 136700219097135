import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { isEqual } from 'lodash'
import FilterParams from './FilterParams'
import useDebounce from '../../hooks/useDebounce'
import { FilterTag } from './FilterTag'
import { FocusableInput } from '../../components/FocusableInput'
import { ScrollableContainerShadows } from '../../components/ScrollableContainerShadows'
import mergeClassNames from '../../utils/mergeClassNames'
import { useSetFilters } from './useSetFilters'
import { getTestId } from '../../utils/getTestId'

function tagsToFilter (tags) {
  return tags
    .filter(tag => tag.value !== '' && tag.value != null)
    .reduce((acc, tag) => ({ ...acc, [tag.field]: tag.value }), {})
}

const FilterParamsEditor = forwardRef(({
  loading,
  autocompleteFields,
  getSuggestions,
  onUpdated,
  MobileAction,
  mobileRightShadowClassName,
  placeholder
}, ref) => {
  const { setSearch, setParams } = useSetFilters()

  const [text, setText] = useState(FilterParams.search)
  const [tags, setTags] = useState(Object.values(autocompleteFields))

  const nextTags = useRef()
  const nextText = useRef(FilterParams.search)

  const filterContainerRef = useRef(null)
  const freeTextSearchInputRef = useRef(null)
  const desktopFilterScrollableContainer = useRef(null)
  const mobileFilterScrollableContainer = useRef(null)

  const setStateParams = useCallback(() => {
    nextText.current = FilterParams.search
    nextTags.current = FilterParams.params

    const customTags = []
    const fixedTags = []
    for (const [key, value] of Object.entries(FilterParams.params)) {
      if (!autocompleteFields[key]) {
        customTags.push({ field: key, label: key, value })
      }
    }

    for (const tag of Object.values(autocompleteFields)) {
      fixedTags.push({ ...tag, value: FilterParams.params[tag.field] ?? '' })
    }

    setText(FilterParams.search)
    setTags([...customTags, ...fixedTags])
  }, [autocompleteFields])

  useEffect(() => {
    setStateParams()
  }, [setStateParams])

  useEffect(() => {
    if (!loading) {
      // updating suggestions
      setTags(tags => {
        return tags.map(tag => ({
          ...tag,
          suggestions: getSuggestions?.(tag.field) ?? []
        }))
      })
    }
  }, [loading, getSuggestions])

  const commitTags = useCallback(() => {
    const changed = (!isEqual(nextTags.current, FilterParams.params))

    if (changed) {
      setParams(nextTags.current)
    }

    return changed
  }, [setParams])

  const commitText = useCallback(() => {
    const changed = (nextText.current !== FilterParams.search)

    if (changed) {
      setSearch(nextText.current)
    }

    return changed
  }, [setSearch])

  const commitFilters = useCallback(() => {
    if (commitTags() || commitText()) {
      onUpdated()
    }
  }, [commitTags, commitText, onUpdated])

  const throttledUpdate = useDebounce(commitFilters, 300)

  const updateTextFilter = (text) => {
    nextText.current = text
    setText(text)
    throttledUpdate()
  }

  const removeTag = useCallback((field) => {
    setTags(oldTags => {
      if (autocompleteFields[field] == null) {
        // remove tag if custom
        const newTags = oldTags.filter(tag => tag.field !== field)

        nextTags.current = tagsToFilter(newTags)
        commitFilters()
        return newTags
      }

      return oldTags
    })
  }, [commitFilters, autocompleteFields])

  const updateTagValue = (field, value) => {
    let hasChanges = false

    const newTags = tags.map(tag => {
      if (tag.field === field && tag.value !== value) {
        hasChanges = true
        return { ...tag, value }
      }

      return tag
    })

    if (hasChanges) {
      nextTags.current = tagsToFilter(newTags)
      setTags(newTags)
    }
  }

  const clearFilters = useCallback(() => {
    setParams({})
    setSearch(undefined)

    setStateParams()
    onUpdated()
  }, [onUpdated, setStateParams, setParams, setSearch])

  useImperativeHandle(ref, () => ({
    setParams: setStateParams,
    clearFilters
  }), [setStateParams, clearFilters])

  const onFreeTextSearchChange = (evt) => {
    updateTextFilter(evt.target.value)
  }

  return (
    <div className='overflow-x-hidden md:h-8 md:overflow-y-hidden'>
      <div className='grid w-full min-w-full flex-1 grow gap-3 overflow-hidden scrollbar-none md:flex md:gap-2 md:overflow-x-auto' ref={desktopFilterScrollableContainer}>
        <FocusableInput
          icon='search'
          iconPosition='start'
          placeholder={placeholder}
          className='h-8 min-w-[300px] py-0 text-gray-700 md:w-[300px]'
          iconSize='small'
          value={text}
          focusableElementRef={freeTextSearchInputRef}
          scrollableContainerRef={filterContainerRef}
          ref={freeTextSearchInputRef}
          onChange={onFreeTextSearchChange}
          onEnter={commitFilters}
          {...getTestId('tagging-text-entry-input')}
        />
        <div className='flex justify-between gap-2 max-md:h-8 max-md:overflow-hidden'>
          <div className='scrollbar-none max-md:overflow-x-auto' ref={mobileFilterScrollableContainer}>
            <div className='flex min-w-0 flex-1 grow gap-2' ref={filterContainerRef}>
              {tags.map(tag => {
                return (
                  <FilterTag
                    key={tag.field}
                    filterContainerRef={filterContainerRef}
                    onUpdate={updateTagValue}
                    commitFilter={commitFilters}
                    removeTag={removeTag}
                    {...tag}
                  />
                )
              })}
              <ScrollableContainerShadows className={mergeClassNames('h-8 md:hidden', mobileRightShadowClassName)} scrollableContainerRef={mobileFilterScrollableContainer} />
            </div>
          </div>
          {MobileAction}
        </div>
        <ScrollableContainerShadows className='h-8 max-md:hidden' rightClassName='right-16' scrollableContainerRef={desktopFilterScrollableContainer} />
      </div>
    </div>
  )
})

export default FilterParamsEditor
