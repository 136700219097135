import React from 'react'
import { Icon } from '../../components/Icon'
import Account from '../accounts/Account.state'
import DetailItem from './DetailItem'
import OrganisationName from './OrganisationName'
import AccountIdentifiers from './AccountIdentifiers'
import DeviceCount from './DeviceCount'
import AccountCreatedAt from './AccountCreatedAt'

const AccountDetails = ({ account, openSidebar }) => {
  return (
    <div
      key={account.id}
      role='button'
      tabIndex={0}
      className='relative flex cursor-pointer flex-col px-3 py-4 hover:bg-violet-50 focus:outline-none focus-visible:bg-violet-50 md:px-4 lg:grid lg:grid-cols-[330px_auto] lg:items-center lg:gap-x-9 lg:gap-y-4 lg:pe-12'
      onClick={() => openSidebar(account)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          openSidebar(account)
        }
      }}
    >
      <div className='flex flex-col gap-2 border-gray-200 pb-4 text-xs text-gray-700 md:flex-row md:items-center md:pe-4 lg:flex-col lg:items-start lg:border-b-0 lg:border-r lg:pb-0'>
        <OrganisationName account={account} />
        <AccountIdentifiers account={account} />
        <div className='flex shrink-0 gap-x-2 text-slate'>
          <DeviceCount account={account} />
          <AccountCreatedAt account={account} />
        </div>
        <DetailItem
          label='Banners:'
          value={Account.hasFeature(account, 'upgrade_banners') ? 'Active' : 'Hidden'}
          className='flex-row'
        />
        <div className='absolute bottom-2 right-0 flex h-full items-center justify-center md:bottom-0 md:right-4'>
          <Icon type='chevron-up' className='rotate-90' size='small' />
        </div>
      </div>
      <div className='grid grid-cols-2 gap-4 pt-4 md:grid-cols-3 lg:pt-0'>
        <DetailItem
          label='Period Sessions'
          value={account.sessions_period}
          limit={account.limits.sessions}
        />
        <DetailItem
          label='Active Sessions'
          value={account.sessions_active}
          limit={account.limits.session_concurrency}
        />
        <DetailItem
          label='Timeout'
          value={account.limits.session_duration}
        />
        <DetailItem
          label='Period Members'
          value={account.members_period}
          limit={account.limits.members}
        />
        <DetailItem
          label='Daily Members'
          value={account.members_daily}
          limit={account.limits.members_daily}
        />
        <DetailItem
          label='Members Concurrency'
          value={account.limits.members_concurrency}
        />
      </div>
    </div>
  )
}

export default AccountDetails
