import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useHideOnScroll (ref, threshold = 50) {
  const [hidden, setHidden] = useState(false)
  const lastScrollY = useRef(0)
  const ticking = useRef(null)
  const location = useLocation()

  useLayoutEffect(() => {
    lastScrollY.current = 0
    setHidden(false)
  }, [location])

  useEffect(() => {
    const element = ref.current
    const rafId = ticking.current
    if (!element) return

    const handleScroll = () => {
      const currentScrollY = element.scrollTop

      if (ticking.current == null) {
        ticking.current = window.requestAnimationFrame(() => {
          const delta = currentScrollY - lastScrollY.current

          if (delta > threshold) {
            setHidden(true)
            lastScrollY.current = currentScrollY
          } else if (delta < -threshold) {
            setHidden(false)
            lastScrollY.current = currentScrollY
          }

          ticking.current = null
        })
      }
    }

    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
      cancelAnimationFrame(rafId)
    }
  }, [ref, threshold, hidden])

  return hidden
}
