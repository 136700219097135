import React from 'react'
import DeviceModule from './Device.state'
import mergeClassNames from '../../utils/mergeClassNames'
import PlatformIcon from '../../components/PlatformIcon'
import { getTestId } from '../../utils/getTestId'
import Timestamp from '../../components/Timestamp'
import { useTranslation } from 'react-i18next'
import { tw } from '../../utils/tw'
import { useIsDeviceLoading, useIsDeviceOnline } from '../../hooks/useDevicePresence'
import { Icon } from '../../components/Icon'

function Status ({ isOnline, isLoading, lastSeen }) {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className='flex items-center gap-x-1 text-xs'>
        <Icon type='spin2' className='size-[7px] h-[7px]' spin />{t('Loading')}
      </div>
    )
  }

  let textColor = ''
  let bgColor = ''

  if (isOnline) {
    textColor = tw`text-green-700`
    bgColor = tw`bg-green-700 shadow-[0_0_0_1.5px_rgba(34,197,94,0.25)]`
  } else {
    textColor = tw`text-red-700`
    bgColor = tw`bg-red-700 shadow-[0_0_0_1.5px_rgba(239,68,68,0.25)]`
  }

  return (
    <div className={mergeClassNames('flex items-center gap-x-1 text-xs', textColor)}>
      <div
        className={mergeClassNames('ms-0.5 size-1 rounded-full', bgColor)}
        title={isOnline ? t('New devices found.') : t('Last seen')}
      />
      {isOnline ? t('Online') : <Timestamp timestamp={lastSeen} />}
    </div>
  )
}

const DeviceStatus = ({ device, hideRealtimeInfo = false, identifierClassName, children }) => {
  const { t } = useTranslation()
  const isLoading = useIsDeviceLoading(device)
  const isOnline = useIsDeviceOnline(device)

  const meetsVersion = (versions) => {
    return DeviceModule.meetsVersion(device.device, versions)
  }

  const customData = () => {
    return device.custom_data || {}
  }

  const deviceIdentifier = () => {
    return customData().device_name || userIdentifier()
  }

  const userIdentifier = () => {
    return customData().user_email || customData().user_id || `${t('Unnamed device')} ${device.id?.substring(0, 6) ?? ''}`
  }

  const sdkVersion = () => {
    return device.device?.sdk_version
  }

  const renderLastSeen = () => {
    return (
      <div className='flex flex-wrap gap-x-1 text-sm leading-4 tracking-wider text-slate-900'>
        {renderSDKStatus()}
        <Status isLoading={isLoading} isOnline={isOnline} lastSeen={device.last_seen} />
      </div>
    )
  }

  const renderSDKStatus = () => {
    let title = t('Deprecated: The SDK is very old, support will be removed very soon.')
    let message = t('Deprecated: SDK {{version}}', { version: sdkVersion() })

    try {
      if (meetsVersion({ ios: '2.0.0', android: '2.0.0', web: '2.0.0' })) return false
    } catch (e) {
      title = t('The SDK version is invalid.')
      message = t('Invalid SDK version')
    }

    return (
      <span className='inline-block whitespace-nowrap rounded bg-red-500 px-1 py-0.5 align-middle text-xs text-white' title={title}>{message}</span>
    )
  }

  const renderPlatformIcon = () => {
    const { device: deviceInfo } = device

    return (
      <PlatformIcon
        device={deviceInfo}
        wrapperClassName={mergeClassNames('relative rounded-full p-0 outline outline-1 outline-gray-200')}
      />
    )
  }

  return (
    <div className='flex'>
      <div className='me-2 flex items-center lg:me-4'>
        {renderPlatformIcon()}
      </div>
      <div className='grid gap-y-2'>
        <div className={mergeClassNames('grid flex-1 items-center gap-x-2 gap-y-1 text-sm md:flex', identifierClassName)}>
          <div
            className='truncate'
            {...getTestId('device-identifier')}
          >
            {deviceIdentifier()}
          </div>
          {!hideRealtimeInfo && renderLastSeen()}
        </div>
        {children}
      </div>
    </div>
  )
}

export default DeviceStatus
