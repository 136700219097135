import React, { forwardRef, useId } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { getTestId } from '../utils/getTestId'
import { Icon } from './Icon'
import { tw } from '../utils/tw'

export const inputClassName = tw`relative flex w-full flex-wrap items-center justify-between gap-y-1 overflow-hidden rounded-lg bg-gray-50 px-3 py-2.5 text-slate outline-1
  has-[:focus]:!shadow-[inset_0_0_0_1px_theme(colors.violet.500)] hover:shadow-[inset_0_0_0_1px_theme(colors.violet.200)] focus:shadow-[inset_0_0_0_1px_theme(colors.violet.500)] disabled:text-gray-400 disabled:outline-0
  md:flex-nowrap md:py-2 `
const gap = tw`gap-x-2 gap-y-1`

const Input = forwardRef(({
  variant = 'primary',
  id,
  type = 'text',
  icon,
  iconPosition = 'end',
  iconClassName,
  inputClassName: overwriteInputClassName,
  disabled,
  action,
  label,
  labelClassName,
  inputWrapperClassName,
  hideLabel = false,
  className,
  testId,
  iconOnClick,
  iconSize,
  ...props
}, ref) => {
  const variants = {
    primary: inputClassName,
    plain: ''
  }

  const inputId = useId()
  const computedId = id ?? inputId

  return (
    <div className={mergeClassNames(variants[variant], gap, className)}>
      {label && (
        <label htmlFor={computedId} className={mergeClassNames('min-w-max select-none text-gray-700', labelClassName, hideLabel && 'sr-only')}>
          {label}
        </label>
      )}
      <div className={mergeClassNames('flex w-full items-center justify-between', gap, inputWrapperClassName)}>
        <input
          ref={ref}
          id={computedId}
          type={type}
          className={mergeClassNames('w-full bg-transparent placeholder:text-gray-700 focus:outline-none disabled:cursor-not-allowed', overwriteInputClassName)}
          disabled={disabled}
          {...getTestId(testId)}
          {...props}
        />
        {icon && <Icon type={icon} size={iconSize} className={mergeClassNames({ 'order-first': iconPosition === 'start', 'order-last': iconPosition === 'end' }, iconClassName)} onClick={iconOnClick} {...getTestId('input-icon')} />}
        {action}
      </div>
    </div>
  )
})

export default Input
