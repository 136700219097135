import React, { forwardRef } from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { inputClassName } from './Input'

const TextArea = forwardRef(({ variant = 'primary', className, children, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      className={mergeClassNames(variant !== 'plain' && inputClassName, 'overflow-auto focus:outline-none', className)}
      {...props}
    >{children}
    </textarea>
  )
})

export default TextArea
