export const baseColors = ['#3c4044', '#34a853', '#e94435', '#10b5cb', '#f9ab02', '#f8f9fa']

const defaultColor = '#e94435'

const storageKey = 'cbio_selected_color'

export function getAvailableColors (colors) {
  return colors?.length > 0 ? colors : baseColors
}

export function readColor (colors) {
  try {
    const storedColor = window.localStorage.getItem(storageKey)

    if (colors.findIndex((color) => color.toLowerCase() === storedColor.toLowerCase()) !== -1) {
      return storedColor
    }
  } catch (err) {
    console.warn('Failed to retrieve color. Defaulting', err)
  }

  return null
}

export function getAnnotationColor (colors) {
  const availableColors = getAvailableColors(colors)
  const color = readColor(availableColors)

  if (color != null) return color

  return availableColors.find((color) => color.toLowerCase() === defaultColor.toLowerCase()) ?? availableColors[0]
}

export function storeColor (color) {
  try {
    window.localStorage.setItem(storageKey, color)
  } catch (err) {
    console.warn('Failed to store color on local storage', err)
  }
}
