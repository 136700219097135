import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from './Icon'

const URLView = ({ url }) => {
  const { t } = useTranslation()

  return (
    <div className='flex max-w-fit flex-col gap-y-2 text-xs text-slate-900'>
      <div className='text-sm'>{t('Browser URL')}</div>
      <div className='flex items-center gap-x-2'>
        <Icon type='globe' size='extra-small' className='text-violet-500' />
        <div className='overflow-hidden'>
          <span>{url}</span>
        </div>
      </div>
    </div>
  )
}

export default URLView
