import React, { memo, useCallback, useEffect, useRef } from 'react'
import { pick, isEqual } from 'lodash'
import ConnectButton from './ConnectButton'
import ResourceInfo from '../../components/ResourceInfo'
import { useIsDeviceConnectable } from '../../hooks/useDevicePresence'

const Device = ({ device, getPresence, className, openSidebar, children }) => {
  const presenceFetchInterval = useRef()
  const isConnectable = useIsDeviceConnectable(device)

  const fetchPresence = useCallback(() => {
    if (device.presence) {
      clearInterval(presenceFetchInterval.current)
    } else if (getPresence) {
      getPresence(device)
    }
  }, [getPresence, device])

  useEffect(() => {
    if (!device.presence) {
      presenceFetchInterval.current = setInterval(fetchPresence, 1500 + (Math.random() * 200))
      setTimeout(fetchPresence, 300)
    }

    return () => {
      clearInterval(presenceFetchInterval.current)
    }
  }, [device.presence, fetchPresence])

  return (
    <ResourceInfo
      className={className}
      resource={device}
      openSidebar={openSidebar}
      actionButton={
        isConnectable ? <ConnectButton device={device} /> : null
      }
    >
      {children}
    </ResourceInfo>
  )
}

const arePropsEqual = (prevProps, nextProps) => {
  const deviceProps = ['id', 'presence', 'last_seen', 'notification_token', 'connectable', 'device', 'colour']
  const devicePropsEqual = isEqual(pick(prevProps.device, deviceProps), pick(nextProps.device, deviceProps))
  const addFilterEqual = prevProps.addFilter === nextProps.addFilter
  const getPresenceEqual = prevProps.getPresence === nextProps.getPresence

  return devicePropsEqual && addFilterEqual && getPresenceEqual
}

export default memo(Device, arePropsEqual)
