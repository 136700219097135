import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Session from '../sessions/Session.state'
import { IFrameBridge, IFrameRPC, closestXdocParent } from 'cocom'
import { updateUiState } from '../ui-state/UI.state'
import EventSerialization from '../sessions/EventSerialization'
import TrustedOrigins from './TrustedOrigins.state'

const parent = window.opener || closestXdocParent()
const bridge = new IFrameBridge(parent)
const rpc = new IFrameRPC(bridge, parent)

export const listenForToken = () => new Promise((resolve) => {
  bridge.start()
  const logging = setInterval(() => console.log('Waiting for token via postMessage'), 1000)
  bridge.on('token', (token) => {
    clearInterval(logging)
    resolve(token)
  })
})

export const PostMessageAPIv2 = (props) => {
  const dispatch = useDispatch()

  const session = useSelector(state => Session.fromState(state))
  const trustedOrigins = useSelector(state => TrustedOrigins.fromState(state))
  const ui = useSelector(state => state.ui)
  const connectivity = useSelector(state => state.connectivity)

  function throwErrors (obj) {
    if (obj?.type === 'Error') throw new Error(obj.message)
    return obj
  }

  useEffect(() => {
    if (!ui.context_loaded) return
    dispatch(TrustedOrigins.actionCreators().getTrustedOrigins())
  }, [dispatch, ui.context_loaded])

  useEffect(() => {
    const onToolSet = ({ tool }) => {
      dispatch(updateUiState({ tool }))
      return true
    }
    rpc.on('update:tool', onToolSet)
    return () => rpc.off('update:tool', onToolSet)
  }, [dispatch])

  useEffect(() => {
    const updateDrawing = ({ drawing }) => {
      if (drawing === null) dispatch(updateUiState({ drawing: null }))
      return true
    }
    rpc.on('update:drawing', updateDrawing)
    return () => rpc.off('update:drawing', updateDrawing)
  }, [dispatch])

  useEffect(() => {
    const updateSession = async (data, { trusted }) => {
      if (!session?.resource) return false
      const update = {}
      if (data?.state === 'ended') update.state = 'ended'
      if (data?.full_device !== session.resource.full_device) update.full_device = data.full_device
      if (data?.remote_control !== session.resource.remote_control) update.remote_control = data.remote_control
      const updated = throwErrors(await dispatch(Session.actionCreators().updateSession({ ...update, id: session.resource.id })))
      return trusted ? updated : Session.sanitize(updated)
    }
    rpc.on('update:session', updateSession)
    return () => rpc.off('update:session', updateSession)
  }, [session.resource, dispatch])

  useEffect(() => {
    const createSession = async (_, { trusted }) => {
      if (!trusted) throw new Error('Only trusted origins can create sessions via attached context.')
      return throwErrors(await dispatch(Session.actionCreators().createSession()))
    }
    rpc.on('create:session', createSession)
    return () => rpc.off('create:session', createSession)
  }, [dispatch])

  useEffect(() => {
    const sendControlEvent = async (message) => {
      if (message.state === 'keydown' && ['GoBack', 'GoHome'].includes(message.key)) {
        const event = EventSerialization.serialize({
          type: message.state,
          key: message.key,
          code: message.key
        })
        await dispatch(Session.actionCreators().sendControlEvent(event, '0'))
        return true
      }
      return false
    }
    rpc.on('create:control', sendControlEvent)
    return () => rpc.off('create:control', sendControlEvent)
  }, [dispatch])

  useEffect(() => {
    if (!session.resource) return
    if (session.working) return
    if (bridge.isTrusted(parent)) {
      bridge.sendToParent('session', session.resource, { trustedOnly: true })
    } else {
      bridge.sendToParent('session', Session.sanitize(session.resource), { trustedOnly: false })
    }
  }, [session])

  useEffect(() => {
    if (session.error) {
      bridge.sendToParent('error', {
        id: session.error.id,
        message: session.error.message
      }, { trustedOnly: false })
    }
  }, [session?.error])

  useEffect(() => {
    if (!ui.screenSize) return
    bridge.sendToParent('screen', {
      ...ui.screenSize,
      updated: new Date(connectivity.metrics.last_alive)
    }, { trustedOnly: false })
  }, [ui.screenSize, connectivity.metrics?.last_alive])

  useEffect(() => {
    bridge.start()
    bridge.trustedOrigins = trustedOrigins.collection || []
    return () => bridge.stop()
  }, [trustedOrigins.collection])

  return props.children || null
}
