import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Alert from '../../components/Alert'
import Account from './Account.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import { SettingsContainer } from '../../components/SettingsContainer'

const DeleteAccountSettings = () => {
  const [deleted, setDeleted] = useState(false)
  const orgName = useRef(null)
  const account = useActiveAccountSelector()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const deleteAccount = async () => {
    const err = await dispatch(Account.actionCreators().removeAccount({
      id: account.resource.id,
      delete: true,
      organisation_name: orgName.current.value
    }))

    if (!err) {
      setDeleted(true)
      window.location = '/dashboard'
    }
  }

  const renderError = () => {
    if (!account.error) {
      return <Alert type='warning'>{t('This action cannot be undone!')}</Alert>
    } else {
      return <Alert type='alert'>{account.error.message}</Alert>
    }
  }

  const renderDelete = () => {
    return (
      <SettingsGroup>
        <SettingsGroup.Title>{t('Delete account settings')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('Enter your account name and press delete to remove your account.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body className='flex gap-x-2'>
          <Input
            type='text'
            className='md:h-9'
            id='account_name'
            placeholder={t('Enter account name to confirm deletion')}
            ref={orgName}
          />
          <Button
            variant='error'
            className='md:h-9'
            thinking={account.working}
            onClick={deleteAccount}
          >
            {t('Delete')}
          </Button>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  if (deleted) {
    return <p className='text-center'>{t('Account deleted.')}</p>
  }

  return (
    <SettingsContainer className='pb-12'>
      <SettingsContainer.Header>
        <h2>
          {t('Delete account ({{organisation_name}})', { organisation_name: account.resource.organisation_name })}
        </h2>
        <p><Trans>This will <b>permanently and irreversibly</b> delete your account, devices, session history, and all associated data.</Trans></p>
        {renderError()}
      </SettingsContainer.Header>
      <SettingsContainer.Body>
        {renderDelete()}
      </SettingsContainer.Body>
    </SettingsContainer>
  )
}

export default DeleteAccountSettings
