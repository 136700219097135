/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StepContainer } from './StepContainer'

const DoneStep = () => {
  const { t } = useTranslation()

  return (
    <StepContainer>
      <StepContainer.Header>
        <StepContainer.Title>{t("You're all set")}</StepContainer.Title>
        <div>
          <StepContainer.Description>
            <Trans>Once you have added the Cobrowse SDKs to your code, your devices will show on your <Link target='_blank' className='text-slate underline' to='/dashboard'>dashboard</Link>.</Trans>
          </StepContainer.Description>
          <StepContainer.Description>
            <Trans>Looking for more documentation? See our <a className='text-slate underline' target='_blank' href='https://docs.cobrowse.io'>full docs</a>.</Trans>
          </StepContainer.Description>
        </div>
      </StepContainer.Header>
      <StepContainer.Body>
        <p className='text-sm text-slate'><Trans>If you need help at any point, email our team at <a className='underline' href='mailto:hello@cobrowse.io' target='_blank'>hello@cobrowse.io</a>.</Trans></p>
      </StepContainer.Body>
    </StepContainer>
  )
}

export default DoneStep
