import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ButtonLink from '../../components/ButtonLink'
import CopyText from '../../components/CopyText'
import Account from '../accounts/Account.state'
import Session from '../sessions/Session.state'
import Presentation from './Presentation.state'
import mergeClassNames from '../../utils/mergeClassNames'
import Loading from '../../components/Loading'
import ErrorMessage from '../errors/ErrorMessage'
import isInIframe from '../../utils/isInIframe'
import { usePageTitle } from '../../hooks/usePageTitle'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { Icon } from '../../components/Icon'
import { getTestId } from '../../utils/getTestId'
import { useNavigate } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'

function isLoading (session, presentation) {
  return !session.resource || !presentation.resource?.token
}

function hasError (session) {
  return Boolean(session?.error)
}

function isInProgress (session) {
  return ['authorizing', 'active'].includes(session?.resource?.state)
}

function isStopped (session) {
  return ['pending', 'ended'].includes(session?.resource?.state)
}

function Container ({ children }) {
  return (
    <div className='flex size-full flex-col gap-6 bg-slate-900 p-6 text-slate-50 lg:mx-auto'>
      {children}
    </div>
  )
}

const PresentMode = () => {
  const [localSharing, setLocalSharing] = useState(false)
  const [sessionEnding, setSessionEnding] = useState(false)
  const [media, setMedia] = useState(null)
  const [sessionCode, setSessionCode] = useState(null)
  const video = useRef(null)
  const CobrowseIO = useRef(null)
  const { t } = useTranslation()
  const session = useSelector(state => Session.fromState(state))
  const account = useActiveAccountSelector().resource
  const presentation = useSelector(state => Presentation.fromState(state))
  const dispatch = useDispatch()

  const resetSession = useCallback(() => dispatch(Session.actionCreators().resetSession()), [dispatch])
  const resetPresentation = useCallback(() => dispatch(Presentation.actionCreators().resetPresentation()), [dispatch])
  const createSession = useCallback((data, options = {}) => dispatch(Session.actionCreators().createSession(data, {}, options)), [dispatch])
  const subscribeSession = useCallback(() => dispatch(Session.actionCreators().subscribeSession()), [dispatch])
  const getPresentation = useCallback((params) => dispatch(Presentation.actionCreators().getPresentation(params)), [dispatch])
  const updateSession = (params) => dispatch(Session.actionCreators().updateSession(params))
  const endSession = useCallback((params) => dispatch(Session.actionCreators().endSession(params)), [dispatch])

  const navigate = useNavigate()

  usePageTitle(t('Present'))
  useDocumentTitle(t('Present'))

  // create a session and then fetch the present mode tokens
  const reset = useCallback(async (abortController) => {
    resetSession()
    resetPresentation()

    // lookup our closest region
    const region = await Session.findClosestRegion()
    if (!region) return

    // create a session for that region
    const session = await createSession({ agent: 'me', region: region.id }, { signal: abortController?.signal })
    if (!session) return

    subscribeSession()
    await getPresentation({ id: session.id })
  }, [createSession, getPresentation, resetSession, resetPresentation, subscribeSession])

  // trigger a reset on load which will generate the session / presentation tokens
  useEffect(() => {
    const abortController = new AbortController()
    reset(abortController)
    return () => {
      abortController.abort()
    }
  }, [reset])

  // Lazy load and configure the Cobrowse JS SDK
  useEffect(() => {
    const handleSessionUpdated = (session) => {
      if (session.isActive() && !session.fullDevice()) {
        session.end()
      }
    }
    const runEffect = async () => {
      CobrowseIO.current = (await import('cobrowse-sdk-js')).default

      // Configure some SDK properties in case we start a session
      // using this built in SDK.
      // It's only useful in full device mode (otherwise they'd jsut be
      // sharing the agent dashboard)
      CobrowseIO.current.capabilities = ['full_device']
      // Session controls and consent will be provided by the agent frontend, the SDK
      // doesn't need to do anything here.
      CobrowseIO.current.showSessionControls = function () { }
      CobrowseIO.current.hideSessionControls = function () { }
      CobrowseIO.current.confirmSession = async () => true
      CobrowseIO.current.confirmRemoteControl = async () => false

      CobrowseIO.current.api = window.location.origin
      CobrowseIO.current.license = account.license_key
      CobrowseIO.current.on('session.updated', handleSessionUpdated)

      // try to clean up any bad state that is potentially hanging about
      try {
        await CobrowseIO.current.stop()
      } catch (e) {
        console.error(e)
      }
    }
    runEffect()

    return () => {
      CobrowseIO.current?.off('session.updated', handleSessionUpdated)
      try {
        CobrowseIO.current?.stop()
      } catch (e) {
        console.error(e)
      }
    }
  }, [account])

  const navigateToDashboard = useCallback(() => {
    navigate('/dashboard')
  }, [navigate])

  const endScreenShare = useCallback(async () => {
    setSessionEnding(true)
    await endSession(session.resource)

    navigateToDashboard()
  }, [endSession, navigateToDashboard, session.resource])

  const startWebBasedDesktopSharing = async () => {
    setLocalSharing(true)

    const media = navigator.mediaDevices.getDisplayMedia({
      video: {
        cursor: 'always',
        width: { ideal: 1400 },
        height: { ideal: 1000 },
        frameRate: { max: 10 }
      },
      audio: false
    })

    await updateSession({
      id: session.resource.id,
      full_device: 'requested'
    })

    CobrowseIO.current.confirmFullDevice = async () => await media
    CobrowseIO.current.start({ allowIFrameStart: true, register: false })

    const s = await CobrowseIO.current.getSession(session.resource.id)
    s.on('media', (media) => {
      if (video.current) {
        setMedia(media)
        video.current.srcObject = media
        video.current.play()
      }
    })
  }

  const createSessionCode = async () => {
    const code = session.resource.code

    setSessionCode(code)
  }

  const [canShareDesktop, setCanShareDesktop] = useState(true)

  useEffect(() => {
    const checkDesktopSharingAvailability = async () => {
      const hasDisplayMedia = 'getDisplayMedia' in navigator.mediaDevices
      const canAccessDisplayMedia = hasDisplayMedia && (!isInIframe() || await hasDisplayCapturePermission())

      setCanShareDesktop(canAccessDisplayMedia)
    }

    const hasDisplayCapturePermission = async () => {
      try {
        const status = await navigator.permissions.query({ name: 'display-capture' })

        return status.state !== 'denied'
      } catch (error) {
        console.warn('Error checking display-capture permission:', error)
      }

      return false
    }

    checkDesktopSharingAvailability()
  }, [])

  const renderStartSharing = () => {
    if (sessionCode) {
      return
    }

    return (
      <>
        <Button
          onClick={startWebBasedDesktopSharing}
          className='flex items-center gap-x-2'
          disabled={!canShareDesktop}
          env='dark'
        >
          {t('Share my desktop')}
        </Button>
        <ButtonLink onClick={createSessionCode} env='dark'>
          {t('Share a different device')}
        </ButtonLink>
      </>
    )
  }

  const renderShareCode = () => {
    if (!sessionCode) return null
    return (
      <>
        <p className='text-sm font-medium text-gray-700'>
          {t('Enter this code on your device:')}
        </p>
        <p className='text-4xl'>
          {sessionCode}
        </p>
        <ButtonLink onClick={() => setSessionCode(null)}>
          {t('Share your desktop')}
        </ButtonLink>
      </>
    )
  }

  const renderMedia = () => {
    if (!localSharing) {
      return (
        <iframe className='size-full' src={`${window.location.origin}/session/${session.resource.id}?token=${presentation.resource.token}&agent_tools=none&device_controls=none&end_action=none&popout=none&session_details=none`} />
      )
    }

    return (
      <video className={mergeClassNames('hidden', media && 'block w-full')} ref={video} />
    )
  }

  const renderScreenshare = () => {
    return (
      <div
        className={mergeClassNames('w-full overflow-hidden rounded-lg border-2 border-black/15 shadow retina:border-1.5', !localSharing && 'flex-1')}
      >
        {renderMedia()}
      </div>
    )
  }

  const renderContent = () => {
    if (isStopped(session)) {
      return (
        <div className='flex min-h-0 flex-1 flex-col items-center justify-center gap-4'>
          {renderStartSharing()}
          {renderShareCode()}
        </div>
      )
    } else if (isInProgress(session)) {
      return (
        <div className='flex min-h-0 flex-1 flex-col items-center justify-center gap-4'>
          {renderScreenshare()}
        </div>
      )
    }
  }

  if (!Account.hasFeature(account, 'present_mode', true)) {
    return (
      <Container>
        <div className='flex h-full flex-col items-center justify-center'>{t('Presentation mode has been disabled for your account')}</div>
      </Container>
    )
  }

  if (hasError(session)) {
    return (
      <Container>
        <ErrorMessage error={session.error} env='dark' />
      </Container>
    )
  }

  if (sessionEnding || isLoading(session, presentation)) {
    return (
      <Container>
        <Loading className='h-full' />
      </Container>
    )
  }

  if (session.resource.state === 'ended') {
    return (
      <Container>
        <div className='flex size-full flex-col  items-center justify-center gap-y-6'>
          <h2 className='text-lg font-medium'>{t('This presentation has ended.')}</h2>
          <LinkButton env='dark' to='/dashboard'>{t('Continue')}</LinkButton>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      {renderContent()}
      <div className='flex items-end gap-6 sm:gap-12'>
        <div className='grid grow gap-1 sm:flex sm:items-center sm:gap-3'>
          <p className='w-[155px] min-w-[155px] text-xs text-gray-400'>{t('Share this link to let others see your screen')}</p>
          <CopyText id='session-url' className='min-w-0 max-w-[500px] shrink bg-gray-50/15 text-xs text-slate-50 md:h-10'>
            {window.location.origin}/present/{session.resource.id}?token={presentation.resource.token}
          </CopyText>
        </div>
        <Button
          env='dark'
          variant='error'
          className='rounded-full'
          onClick={endScreenShare}
          {...getTestId('present-button-end-session')}
        >
          <Icon type='phone' size='large' />
          <span className='sr-only'>{t('End session')}</span>
        </Button>
      </div>
    </Container>
  )
}

export default PresentMode
