import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'
import { tw } from '../utils/tw'
import * as icons from '../icons/icons'

/**
 * @param {Object} props
 * @param {'extra-small'|'small'|'medium'|'large'} props.size - the size of the icon
 * @returns
 */
export function Icon ({ type, size = 'medium', className, spin, reverse, ...props }) {
  const sizes = {
    'extra-small': tw`size-3`,
    small: tw`size-4`,
    medium: tw`size-5`,
    large: tw`size-6`
  }

  const iconName = type
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
  const IconComponent = icons[iconName]

  return (
    <div aria-hidden='true' className={mergeClassNames(sizes[size], spin && 'inline-block animate-spin', reverse && ' [animation-direction:reverse]', className)} {...props}>
      <IconComponent width='0px' height='100%' />
    </div>
  )
}
