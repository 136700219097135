import React, { cloneElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Toolbar from '../../components/Toolbar'
import SessionCapability from './SessionCapability'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import {
  SESSION_CAPABILITY_TYPE_DRAWING,
  SESSION_CAPABILITY_TYPE_LASER,
  SESSION_CAPABILITY_GROUP_REMOTE_CONTROL,
  SESSION_CAPABILITY_TYPE_DISAPPEARING_INK
} from './SessionCapabilityTypes'
import { DisconnectButton } from './DisconnectButton'
import { SESSION_TOOL_TYPE_CONTROL, SESSION_TOOL_TYPE_DRAWING, SESSION_TOOL_TYPE_DISAPPEARING_INK, SESSION_TOOL_TYPE_LASER } from './SessionToolTypes'
import { ColorPicker } from '../../components/ColorPicker'
import Session from './Session.state'
import { ExpandableVerticalMenu } from '../../components/ExpandableVerticalMenu'
import { DesktopToggleButton, MobileToggleButton } from '../../components/Sidebar'
import EmbedParams from '../../utils/EmbedParams'
import { useSelector } from 'react-redux'
import { Transition } from '@headlessui/react'
import Stopwatch from '../../components/Stopwatch'
import SessionTimeoutCountdown from './SessionTimeoutCountdown'
import ServerTime from '../../utils/ServerTime'
import PopoutButton from '../../components/PopoutButton'
import isInIframe from '../../utils/isInIframe'
import { Icon } from '../../components/Icon'
import Button from '../../components/Button'
import { getAvailableColors } from './colorPicker'

function getClassName (isDesktopOnly, className) {
  return mergeClassNames('rounded-full', className, isDesktopOnly && 'hidden xs:hidden md:flex')
}

function ToolRenderer ({
  disabled,
  capability,
  tool,
  title,
  selected,
  onClick,
  testId,
  className,
  icon,
  index,
  Component,
  shouldHideBtnsFromIndex,
  isDisabled,
  onToolSelected,
  selectedTool,
  children,
  ...props
}) {
  const isDesktopOnly = typeof shouldHideBtnsFromIndex === 'number' && index >= shouldHideBtnsFromIndex

  if (Component != null) {
    return cloneElement(Component, { isDesktopOnly, className })
  }

  const computedDisabledState = disabled ?? isDisabled(capability)
  const isSelected = selected ?? selectedTool === tool

  return (
    <Button
      disabled={computedDisabledState}
      title={title}
      data-selected={isSelected}
      onClick={() => onClick != null ? onClick() : onToolSelected(tool)}
      env='dark'
      size='extra-large'
      variant={isSelected ? 'secondary' : 'tertiary'}
      isIconButton
      className={getClassName(isDesktopOnly, className)}
      {...getTestId(testId)}
      {...props}
    >
      {children || (
        <>
          <span className='sr-only'>{title}</span>
          <Icon type={icon} size='large' />
        </>
      )}
    </Button>
  )
}

const commonDrawingTransitionProps = {
  enter: 'transition-opacity duration-300 ease-in motion-reduce:transition-none',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity duration-150 ease-out motion-reduce:transition-none',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
  className: 'absolute top-0 left-0 flex items-center justify-center w-full h-full'
}

function DrawingToolBtn ({ shouldRenderClearBtn, selectedTool, onToolSelected, performAction, isDesktopOnly, className, ...props }) {
  const { t } = useTranslation()

  const isSelected = selectedTool === SESSION_TOOL_TYPE_DRAWING
  const title = shouldRenderClearBtn ? t('Clear annotations') : t('Drawing tool')

  return (
    <ToolRenderer
      selected={isSelected}
      onClick={() => shouldRenderClearBtn ? performAction('clear') : onToolSelected(SESSION_TOOL_TYPE_DRAWING)}
      testId={shouldRenderClearBtn ? 'toolbar-button-clear' : 'toolbar-button-drawing'}
      className={getClassName(isDesktopOnly, className)}
      title={title}
      {...props}
    >
      <Transition show={shouldRenderClearBtn} {...commonDrawingTransitionProps}>
        <Icon type='trash-empty' size='large' />
        <span className='sr-only'>{title}</span>
      </Transition>
      <Transition show={!shouldRenderClearBtn} {...commonDrawingTransitionProps}>
        <Icon type='ink-pen' size='large' />
        <span className='sr-only'>{title}</span>
      </Transition>
    </ToolRenderer>
  )
}

function MobileSubMenu () {
  const { t } = useTranslation()
  return (
    <ToolRenderer
      disabled={false}
      variant='tertiary'
      onToolSelected={() => { }}
      title={t('Expand toolbar')}
      {...getTestId('toolbar-button-expand')}
      className={getClassName()}
      icon='ellipsis-h'
    />
  )
}

const SessionToolbar = ({
  session,
  selectedTool,
  control,
  allowFullDevice,
  onToolSelected,
  onFullDeviceChanged,
  performAction,
  user,
  account,
  className,
  selectedColor,
  onColorPicked,
  showUpgrade
}) => {
  const { t } = useTranslation()
  const hasActiveDrawings = useSelector(state => state.ui.hasActiveDrawings)

  const isDisabled = useCallback((capability) => {
    return capability && !SessionCapability.hasCapability(session, capability)
  }, [session])

  const isSupportedByDevice = useCallback((capability, defaultValue = true) => {
    return capability == null || SessionCapability.hasDeviceCapability(session, capability, defaultValue)
  }, [session])

  const tools = useMemo(() => {
    return [
      {
        capability: SESSION_CAPABILITY_TYPE_LASER,
        tool: SESSION_TOOL_TYPE_LASER,
        title: t('Laser pointer'),
        testId: 'toolbar-button-laser',
        icon: 'laserpointer'
      },
      {
        capability: SESSION_CAPABILITY_TYPE_DISAPPEARING_INK,
        tool: SESSION_TOOL_TYPE_DISAPPEARING_INK,
        deviceCapabilityDefaultValue: false,
        title: t('Disappearing ink'),
        testId: 'toolbar-button-disappearing-ink',
        icon: 'disappearing-ink'
      },
      {
        capability: SESSION_TOOL_TYPE_DRAWING,
        Component: <DrawingToolBtn
          disabled={isDisabled(SESSION_CAPABILITY_TYPE_DRAWING)}
          shouldRenderClearBtn={hasActiveDrawings && selectedTool === SESSION_TOOL_TYPE_DRAWING}
          selectedTool={selectedTool}
          onToolSelected={onToolSelected}
          performAction={performAction}
                   />
      },
      control && {
        capability: SESSION_CAPABILITY_GROUP_REMOTE_CONTROL,
        tool: SESSION_TOOL_TYPE_CONTROL,
        title: t('Remote control'),
        testId: 'toolbar-button-remote-control',
        icon: 'hand-pointer'
      },
      allowFullDevice && {
        className: 'data-[selected=true]:bg-violet-500 data-[selected=true]:text-slate-50',
        title: t('Full device'),
        selected: Session.isFullDevice(session),
        onClick: () => onFullDeviceChanged(!Session.isFullDevice(session)),
        testId: 'toolbar-button-full-device',
        icon: 'full-device',
        disabled: false
      }
    ]
      .filter(Boolean)
      .filter(({ capability, deviceCapabilityDefaultValue }) => EmbedParams.agentTools() && isSupportedByDevice(capability, deviceCapabilityDefaultValue))
      .map(({ deviceCapabilityDefaultValue, ...rest }) => rest)
  }, [t, isDisabled, hasActiveDrawings, selectedTool, onToolSelected, performAction, onFullDeviceChanged, session, isSupportedByDevice, allowFullDevice, control])

  const hasExpandableMobileMenu = tools.length > 3 || (EmbedParams.popout() && EmbedParams.sessionDetails() && EmbedParams.agentTools() && isInIframe())

  return (
    <div className='flex w-full items-center justify-between'>
      {EmbedParams.agentTools()
        ? showUpgrade
          ? <SessionTimeoutCountdown expires={session.expires} />
          : <Stopwatch className='hidden min-w-12 text-sm font-semibold text-gray-200 sm:block md:min-w-16' start={session.activated} end={ServerTime.now()} />
        : <div />}
      {EmbedParams.agentTools() && (
        <Toolbar className={mergeClassNames('flex w-auto', className)}>
          <div className='h-fit w-full items-center sm:w-fit' {...getTestId('session-toolbar')}>
            <div className='flex w-full items-center justify-between gap-x-2'>
              <ColorPicker
                colors={getAvailableColors(session.colours)}
                selectedColor={selectedColor}
                onColorPicked={onColorPicked}
              />

              {tools.map((props, index) => (
                <ToolRenderer
                  key={`desktop-${props.testId ?? props.title?.replace(/ /g, '-') ?? index}`}
                  index={index}
                  shouldHideBtnsFromIndex={3}
                  isDisabled={isDisabled}
                  selectedTool={selectedTool}
                  onToolSelected={onToolSelected}
                  className='hidden xs:flex'
                  {...props}
                />
              ))}

              <DisconnectButton
                session={session}
                user={user}
                performAction={performAction}
                account={account}
              />
            </div>
          </div>
        </Toolbar>
      )}
      <div className='flex gap-x-4 xs:gap-x-6 md:gap-x-2'>
        {EmbedParams.popout() && <PopoutButton className={mergeClassNames(getClassName(true), 'flex xs:hidden md:flex md:bg-transparent')} {...getTestId('desktop-toolbar-popout')} />}
        {EmbedParams.sessionDetails() && <DesktopToggleButton className={getClassName(true, 'bg-transparent')} />}
        {hasExpandableMobileMenu
          ? (
            <ExpandableVerticalMenu
              className='hidden xs:flex md:hidden'
              Button={MobileSubMenu}
              menuClassname='gap-y-2 p-2'
            >
              {tools.slice(3).map((props, index) => (
                <ToolRenderer
                  key={`mobile-${props.testId ?? props.title?.replace(/ /g, '-') ?? index}`}
                  index={index}
                  isDisabled={isDisabled}
                  selectedTool={selectedTool}
                  onToolSelected={onToolSelected}
                  {...props}
                />
              ))}

              {EmbedParams.popout() && <PopoutButton className={getClassName()} {...getTestId('mobile-expandable-toolbar-popout')} />}
              {EmbedParams.sessionDetails() ? <MobileToggleButton /> : null}
            </ExpandableVerticalMenu>
            )
          : (
            <>
              {EmbedParams.popout() && <PopoutButton className='rounded-full md:hidden' {...getTestId('mobile-toolbar-popout')} />}
              {EmbedParams.sessionDetails() ? <MobileToggleButton className='md:hidden' /> : null}
            </>
            )}
        {EmbedParams.agentTools() && (
          (
            <ExpandableVerticalMenu
              className='xs:hidden'
              Button={MobileSubMenu}
              menuClassname='gap-y-2 p-2'
            >
              {tools.map((props, index) => (
                <ToolRenderer
                  key={`mobile-${props.testId ?? props.title?.replace(/ /g, '-') ?? index}`}
                  index={index}
                  isDisabled={isDisabled}
                  selectedTool={selectedTool}
                  onToolSelected={onToolSelected}
                  {...props}
                />
              ))}
              {EmbedParams.sessionDetails() ? <MobileToggleButton /> : null}
            </ExpandableVerticalMenu>
          )
        )}
      </div>
    </div>
  )
}

export default SessionToolbar
