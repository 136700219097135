import React, { useCallback } from 'react'
import Device from './Device'
import mergeClassNames from '../../utils/mergeClassNames'
import { useSidebarState } from '../../components/Sidebar'
import { DeviceHeader } from './DeviceHeader'
import { DeviceInfoPills } from './DeviceInfoPills'

export function DeviceRow ({ getPresence, refreshDevices, setExpandedDevice, addFilter, deviceClassName, ...device }) {
  const [, toggle] = useSidebarState()

  const openSidebar = useCallback(() => {
    if (setExpandedDevice == null) return
    toggle(true)
    setExpandedDevice(device)
  }, [setExpandedDevice, device, toggle])

  return (
    <Device
      key={device.id}
      device={device}
      getPresence={getPresence}
      openSidebar={openSidebar}
      className={deviceClassName}
    >
      <>
        <DeviceHeader device={device} showChevron>
          <DeviceInfoPills
            device={device.device}
            customData={device.custom_data}
            className='hidden md:flex'
            addFilter={addFilter}
          />
        </DeviceHeader>
        <div className={mergeClassNames('min-w-0 empty:hidden md:hidden')}>
          <DeviceInfoPills
            device={device.device}
            customData={device.custom_data}
            addFilter={addFilter}
          />
        </div>
      </>

    </Device>
  )
}
