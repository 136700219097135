import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonLink from '../../components/ButtonLink'
import StandardList from '../../components/StandardList'
import FilterParams from '../filters/FilterParams'
import EmptyDeviceList from './EmptyDeviceList'
import { tw } from '../../utils/tw'
import { DeviceHeader } from './DeviceHeader'
import ConnectButton from './ConnectButton'
import { useScrollToTop } from '../../routing/DashboardLayout'
import { DeviceInfoSidebar } from '../../components/DeviceInfoSidebar'
import { DeviceRow } from './DeviceRow'
import { DeviceListHeader } from './DeviceListHeader'
import { useSetFilters } from '../filters/useSetFilters'
import { useLoadMore } from '../../hooks/useLoadMore'

function getFilters () {
  return {
    filter: FilterParams.search,
    ...FilterParams.parse()
  }
}

const DeviceList = ({ refresh, devices, getPresence }) => {
  const { t } = useTranslation()
  const scrollToTop = useScrollToTop()
  const { setParams } = useSetFilters()

  const filterEditorRef = useRef()
  const sidebarTriggerContainer = useRef(null)

  const [filters, setFilters] = useState(getFilters())

  const [expandedDevice, setExpandedDevice] = useState(null)

  const refreshDevices = useCallback(() => {
    const filters = getFilters()

    setFilters(filters)
    refresh(filters)
    scrollToTop()
  }, [scrollToTop, refresh])

  useEffect(() => {
    refreshDevices()
  }, [refreshDevices])

  const refreshWrapper = useCallback(async (last) => {
    return refresh({
      ...filters,
      seen_before: (last && last.last_active) || 0
    }, { merge: true })
  }, [filters, refresh])

  const { hasMore, hasError, loadMore } = useLoadMore(devices, refreshWrapper)

  const clearFilters = () => {
    filterEditorRef.current.clearFilters()
  }

  const renderEmptyList = () => {
    if (devices.working) {
      return null
    }

    if (FilterParams.search || !FilterParams.empty()) {
      return (
        <div className='mt-5 text-center'>
          <span>{t('No devices found that match your filters.')}</span>{' '}
          <ButtonLink className='underline' onClick={clearFilters}>{t('Clear filters')}</ButtonLink>
        </div>
      )
    }

    return (
      <EmptyDeviceList />
    )
  }

  const addFilter = useCallback((key, value) => {
    setParams({ ...FilterParams.params, [key]: value })
    filterEditorRef.current.setParams()
    refreshDevices()
  }, [refreshDevices, setParams])

  const renderDevice = useCallback((props) => {
    return (
      <DeviceRow
        getPresence={getPresence}
        refreshDevices={refreshDevices}
        setExpandedDevice={setExpandedDevice}
        addFilter={addFilter}
        {...props}
      />
    )
  }, [getPresence, refreshDevices, setExpandedDevice, addFilter])

  return (
    <>
      <DeviceListHeader
        devices={devices}
        filters={filters}
        refreshDevices={refreshDevices}
        filterEditorRef={filterEditorRef}
      />

      <div ref={sidebarTriggerContainer}>
        <StandardList
          testId='device-list'
          items={devices.collection}
          renderItem={renderDevice}
          renderEmptyList={renderEmptyList}
          onBottomReached={loadMore}
          listContainerClassName='md:pb-24'
          hasMore={hasMore}
          hasError={hasError}
          loading={devices.working}
        />

        <DeviceInfoSidebar
          device={expandedDevice}
          addFilter={addFilter}
          Header={<DeviceHeader device={expandedDevice} identifierClassName={tw`md:grid`} />}
          HeaderAction={<ConnectButton device={expandedDevice} className='mt-3' />}
          sidebarTriggerContainer={sidebarTriggerContainer}
        />
      </div>
    </>
  )
}

export default DeviceList
