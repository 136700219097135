import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Stopwatch from '../../components/Stopwatch'
import Timestamp from '../../components/Timestamp'
import mergeClassNames from '../../utils/mergeClassNames'
import { Icon } from '../../components/Icon'

const userIdentifier = (data = {}) => {
  return data.user_email || data.user_id
}

const sessionDate = (session) => {
  return <Timestamp timestamp={session.created} />
}

const SessionOverview = ({ session, ip, variant = 'listing' }) => {
  const { t } = useTranslation()
  const endUser = userIdentifier(session.custom_data)
  const recordingVariant = variant === 'recording'

  return (
    <>
      <div className={mergeClassNames('truncate text-slate-900', recordingVariant && 'flex flex-wrap gap-1')}>
        {session.agent ? session.agent.name : t('No agent')}
        {endUser && recordingVariant && <span className='ps-1 text-slate-900'> <Trans>with <span className='inline-block ps-1 text-black'>{endUser}</span></Trans></span>}
      </div>
      <div className='flex items-center gap-x-2'>
        <div className='flex items-center gap-x-1'>
          <Icon type='clock-hour-3' className='text-slate-900' size='small' />
          <Stopwatch className='inline' start={session.activated} end={session.ended} live={!session.ended} />
        </div>
        <div className='h-4 w-px border-l-2 border-gray-200' />
        {session.state === 'active' && (
          <div className='flex items-center gap-x-1'>
            <div className='size-2 rounded-full bg-green-500' />
            <span className=''>{t('Active')}</span>
          </div>
        )}
        {session.state === 'ended' && (
          <span className='text-gray-700'>
            {sessionDate(session)}
            {ip && (
              <span> {t('with IP {{ip}}', { ip })}</span>
            )}
          </span>
        )}
      </div>
    </>
  )
}

export default SessionOverview
