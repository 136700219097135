import React, { useId } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from '../users/UserIcon'
import Select from '../../components/Select'
import Option from '../../components/SelectOption'
import Button from '../../components/Button'
import { Icon } from '../../components/Icon'

const MemberRole = ({ member, updateAccountMember, readOnly = false }) => {
  const { t } = useTranslation()
  const selectId = useId()

  if (!member.role) {
    return null
  }

  return (
    <div>
      <label htmlFor={selectId} className='sr-only'>{t('Role')}</label>
      <Select
        id={selectId}
        variant='simple'
        disabled={!updateAccountMember}
        readOnly={readOnly}
        value={member.role}
        className='ps-1'
        onChange={(e) => updateAccountMember({ ...member, role: e.target.value })}
      >
        <Option value='agent'>{t('Support agent')}</Option>
        <Option value='administrator'>{t('Administrator')}</Option>
      </Select>
    </div>
  )
}

const MemberControls = ({ member, updateAccountMember, removeAccountMember, readOnly = false }) => {
  const { t } = useTranslation()

  if (!member.role || readOnly) {
    return null
  }

  return (
    <Button
      variant='plain'
      size='small'
      isIconButton
      className='flex items-center text-gray-700'
      disabled={!updateAccountMember}
      onClick={() => removeAccountMember(member)}
    >
      <Icon size='small' type='circle-minus' />
      <span className='sr-only'>{t('Remove member')}</span>
    </Button>
  )
}

const Member = ({ member, self, updateAccountMember, removeAccountMember, readOnly = false }) => {
  const { t } = useTranslation()

  return (
    <div key={member.id} className='flex items-center gap-4 py-3'>
      <div className='min-w-0 shrink-0'>
        <UserIcon user={member} className='size-8' />
      </div>
      <div className='min-w-0 shrink'>
        <div className='truncate text-xs text-slate'>{self ? t('You') : member.name}</div>
        <div className='truncate text-xs text-gray-700'>{member.username || member.email || ''}</div>
      </div>
      <div className='ms-auto flex items-center gap-x-2'>
        <MemberRole member={member} updateAccountMember={updateAccountMember} readOnly={readOnly} />
        <MemberControls member={member} updateAccountMember={updateAccountMember} removeAccountMember={removeAccountMember} readOnly={readOnly} />
      </div>
    </div>
  )
}

export default Member
