import { useEffect, useState } from 'react'

export function useIsAboveBreakpoint (breakpoint) {
  const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(window.innerWidth > breakpoint)

  useEffect(() => {
    // if matchMedia is not supported just return the initial value
    if (!window.matchMedia) return

    const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`)
    const handleResize = () => setIsAboveBreakpoint(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleResize)
    handleResize()

    return () => mediaQuery.removeEventListener('change', handleResize)
  }, [breakpoint])

  return isAboveBreakpoint
}
