import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import qs from 'qs'
import LoginButton from './LoginButton'
import MagicLinkLogin from './MagicLinkLogin'
import Input from './../../components/Input'
import Link from '../../components/Link'
import Alert from '../../components/Alert'

const LoginButtons = ({ providers, register, query }) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const text = (provider) => {
    return register
      ? t('Sign up with {{provider}}', { provider })
      : t('Sign in with {{provider}}', { provider })
  }

  const enabled = () => {
    if (!register) {
      return true
    } else {
      return !!name
    }
  }

  const params = () => {
    return {
      ...query,
      organisation_name: name || undefined
    }
  }

  const allow = (provider) => {
    if (providers && !providers.includes(provider)) {
      return false
    }

    return true
  }

  const renderLoginError = () => {
    const { error } = qs.parse(window.location.search.substring(1))

    if (!error) {
      return null
    }

    let message = t('Sorry, something went wrong logging into your account. Try again, or contact hello@cobrowse.io for assistance.')
    if (error === 'google_unverified_email') message = t('Your email address must be verified with {{provider}} before signing up.', { provider: 'Google' })
    if (error === 'intercom_unverified_email') message = t('Your email address must be verified with {{provider}} before signing up.', { provider: 'Intercom' })
    if (error === 'account_create_denied') message = t('You do not have permission to create new accounts.')
    if (error === 'login_no_accounts') message = t('You are not a member of any Cobrowse accounts yet. Ask your administrator for access to Cobrowse or register a new account.')
    if (error === 'jwt expired') message = t('Your login link has expired. Please request a new link and try again.')
    if (error === 'magic_link_used') message = t('Your login link has already been used. Please request a new link and try again.')

    return (
      <Alert type='alert' variant='inline' icon='alert'>{message}</Alert>
    )
  }

  const renderRegistration = () => {
    if (!register) {
      return null
    }

    return (
      <div className='flex flex-col gap-y-2'>
        <p className='text-xs'>
          <span className='text-slate'>{t('Step 1')}</span>
          {' '}
          <span className='text-gray-700'>{t('Enter your company name')}</span>
        </p>
        <Input
          className='h-10'
          required
          name='organisation_name'
          placeholder={t('Company name')}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    )
  }

  const renderLoginMethods = () => {
    return (
      <>
        <div className='flex flex-col gap-y-2'>
          {register && (
            <p className='text-xs'>
              <span className='text-slate'>{t('Step 2')}</span>
              {' '}
              <span className='text-gray-700'>{t('Choose your login method')}</span>
            </p>
          )}
          <div className='flex w-full flex-auto flex-col gap-y-4'>
            {allow('email') && (
              <MagicLinkLogin
                disabled={!enabled()}
                text={register ? t('Sign up') : t('Sign in')}
                query={params()}
                process={register ? 'register' : 'login'}
              />
            )}
            <div className='flex h-4 items-center justify-center'>
              <div className='relative w-full border-t border-gray-200'>
                <p className='absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 bg-white px-1 text-xs text-gray-700'>{t('or')}</p>
              </div>
            </div>
            <div className='flex flex-col gap-y-2'>
              {allow('google') && (
                <LoginButton
                  variant='tertiary'
                  disabled={!enabled()}
                  icon='google'
                  text={text('Google')}
                  onClick={() => { window.location = `/api/1/google/auth?${qs.stringify(params())}` }}
                />
              )}
              {allow('github') && (
                <LoginButton
                  variant='tertiary'
                  disabled={!enabled()}
                  icon='github'
                  text={text('GitHub')}
                  onClick={() => { window.location = `/api/1/github/auth?${qs.stringify(params())}` }}
                />
              )}
              {allow('intercom') && (
                <LoginButton
                  variant='tertiary'
                  disabled={!enabled()}
                  icon='intercom'
                  text={text('Intercom')}
                  onClick={() => { window.location = `/api/1/intercom/auth?${qs.stringify(params())}` }}
                />
              )}
              {allow('zendesk') && (
                <LoginButton
                  variant='tertiary'
                  disabled={!enabled()}
                  icon='zendesk'
                  text={text('Zendesk')}
                  onClick={() => { window.location = `/login/zendesk?${qs.stringify(params())}` }}
                />
              )}
            </div>
          </div>
          {register && (
            <div className='mt-2 flex flex-col gap-y-4 text-center text-xs text-gray-700'>
              <p>
                <Trans>By continuing you agree to our <Link target='_blank' className='text-slate no-underline' to='/legal'>terms and policies</Link>.</Trans>
              </p>
              <p>
                {t('Already have an account?')} <Link className='text-slate no-underline' to='/login'>{t('Log in')}</Link>.
              </p>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div className='mx-auto flex w-full flex-col gap-y-6'>
      {renderLoginError()}
      {renderRegistration()}
      {renderLoginMethods()}
    </div>
  )
}

export default LoginButtons
