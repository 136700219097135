import { useCallback, useState } from 'react'

export function useLoadMore (resource, fetch) {
  const [hasMore, setHasMore] = useState(true)
  const [hasError, setHasError] = useState(false)

  const loadMore = useCallback(async () => {
    if (resource.working) {
      return
    }

    const resourceCollection = resource.collection
    const last = resourceCollection[resourceCollection.length - 1]

    const result = await fetch(last)

    if (result?.length === 0) {
      setHasMore(false)
    }

    if (result === undefined) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [resource.collection, resource.working, fetch])

  return { hasMore, hasError, loadMore }
}
