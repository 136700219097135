import React, { Fragment } from 'react'
import { Listbox as HeadlessListBox, Transition } from '@headlessui/react'
import { Icon } from './Icon'
import mergeClassNames from '../utils/mergeClassNames'
import { inputClassName } from './Input'

const ListBoxRoot = ({ selected, onChange, className, buttonClassName, children }) => {
  return (
    <div className={mergeClassNames('relative z-10 w-full', className)}>
      <HeadlessListBox value={selected} onChange={onChange}>
        <HeadlessListBox.Button className={mergeClassNames(inputClassName, buttonClassName)}>
          <span className='block truncate text-xs leading-5'>{selected.title}</span>
          <span className='pointer-events-none absolute inset-y-0 end-0 flex items-center pe-3'>
            <Icon type='down-open' size='small' className='text-gray-700 ui-open:rotate-180' />
          </span>
        </HeadlessListBox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <HeadlessListBox.Options className='absolute mt-6 flex w-full flex-col gap-y-4 rounded-lg bg-white p-4 shadow-menus outline-none' as='div'>
            {children}
          </HeadlessListBox.Options>
        </Transition>
      </HeadlessListBox>
    </div>
  )
}
const Option = ({ className, children, ...props }) => (
  <HeadlessListBox.Option
    className={() => mergeClassNames('flex items-center', className)}
    {...props}
  >
    {({ selected, active }) => typeof children === 'function' ? children({ selected, active }) : children}
  </HeadlessListBox.Option>
)

export const ListBox = Object.assign(ListBoxRoot, { Option })
