import React from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import mergeClassNames from '../utils/mergeClassNames'

const Switch = ({ name, label, stacked, checked, onChange, disabled, readOnly, labelPosition = 'after', inline = false, className, labelClassName, children, ...props }) => {
  return (
    <HeadlessSwitch.Group>
      <div className={mergeClassNames('flex gap-x-3', inline && 'inline-flex', stacked && '-mt-1 flex-col gap-y-1 md:gap-y-2')}>
        <HeadlessSwitch
          checked={checked}
          onChange={(checked) => onChange(name, checked)}
          disabled={disabled}
          readOnly={readOnly}
          className={mergeClassNames(
            `relative inline-block h-7 w-12 cursor-pointer rounded-2xl bg-gray-100 align-middle transition-colors
            after:absolute after:start-1 after:top-1 after:size-5 after:rounded-full after:bg-slate-700 after:transition-[left] after:delay-100 after:ease-linear hover:bg-gray-200
            focus-visible:outline-none focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-violet
            disabled:cursor-not-allowed disabled:bg-gray-100 disabled:after:bg-gray-400
            after:motion-reduce:transition-none`,
            checked && 'bg-violet-100 after:start-[calc(100%_-_theme(spacing.6))] after:bg-violet hover:bg-violet-200',
            className
          )}
          {...props}
        >
          <span className='sr-only'>{label}</span>
        </HeadlessSwitch>
        <div className={mergeClassNames(children && 'flex flex-1 flex-col', labelPosition === 'before' ? 'order-first' : 'order-last')}>
          <HeadlessSwitch.Label
            as='span'
            className={mergeClassNames('text-xs text-slate', stacked && '-mt-1', labelClassName)}
          >
            {label || name}
          </HeadlessSwitch.Label>
          {children}
        </div>
      </div>
    </HeadlessSwitch.Group>
  )
}

export default Switch
