import React, { forwardRef, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'

const CodeEntryInput = forwardRef(({ className, onKeyDown, onChange, onPaste, disabled, value, digit, focusOnRender = false }, ref) => {
  const { t } = useTranslation()
  const id = useId()
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = (event) => {
    setIsFocused(true)
    const input = event.target

    setTimeout(() => {
      input.selectionStart = input.selectionEnd = input.value.length
    }, 0)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Input
      id={id}
      autoFocus={focusOnRender}
      className={mergeClassNames('m-0.5 size-10 overflow-visible bg-violet px-0 py-2.5 text-base text-white', className)}
      inputClassName='text-center placeholder:text-slate-50/35'
      type='text'
      onKeyDown={onKeyDown}
      onChange={onChange}
      onPaste={onPaste}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
      pattern='\d*'
      data-digit={digit}
      ref={ref}
      disabled={disabled}
      placeholder={isFocused ? '' : digit + 1}
      aria-label={t('Code entry digit {{digit}}', { digit: digit + 1 })}
      autoComplete='off'
      {...getTestId(`code-entry-digit-${digit}`)}
    />
  )
})

export default CodeEntryInput
