import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterParams from './FilterParams'
import EmbedParams from '../../utils/EmbedParams'

export function useSetFilters () {
  const [, setSearchParams] = useSearchParams()

  const setParams = useCallback((params) => {
    FilterParams.params = params
    setSearchParams(EmbedParams.parse())
  }, [setSearchParams])

  const setSearch = useCallback((query) => {
    FilterParams.search = query
    setSearchParams(EmbedParams.parse())
  }, [setSearchParams])

  return useMemo(() => ({ setSearch, setParams }), [setSearch, setParams])
}
