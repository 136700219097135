import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Device from '../modules/devices/Device.state'

const useGetDevicePresence = () => {
  const dispatch = useDispatch()

  return useCallback((device) => dispatch(Device.actionCreators().getPresence(device)), [dispatch])
}

export default useGetDevicePresence
