import React from 'react'
import mergeClassNames from '../../utils/mergeClassNames'

const AuthContainerRoot = ({ children, className, ...props }) => (
  <div className={mergeClassNames('flex flex-col items-center justify-start bg-white py-8 [@media(min-height:550px)]:h-full [@media(min-height:550px)]:justify-center', className)} {...props}>{children}</div>
)

const Body = ({ children, className, ...props }) => (
  <div className={mergeClassNames('mx-auto w-full max-w-sm px-5 md:px-0', className)} {...props}>{children}</div>
)

export const AuthContainer = Object.assign(AuthContainerRoot, { Body })
