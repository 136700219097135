import React from 'react'
import { useTranslation } from 'react-i18next'
import Account from '../accounts/Account.state'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import { Icon } from '../../components/Icon'
import mergeClassNames from '../../utils/mergeClassNames'
import Button from '../../components/Button'
import LinkButton from '../../components/LinkButton'

export const showUpgradeBanner = (account) => {
  const { created } = account.resource
  const accountAgeInMilliseconds = (Date.now() - new Date(created).getTime())
  const isNewAccount = accountAgeInMilliseconds < (3 * 24 * 60 * 60 * 1000) // 3 days

  if (isNewAccount) {
    return false
  }

  return Account.hasFeature(account.resource, 'upgrade_banners')
}

const UpgradeBanner = ({ className, showCloseButton, onCloseClick, buttonClassName }) => {
  const account = useActiveAccountSelector()
  const { t } = useTranslation()

  if (showUpgradeBanner(account)) {
    return (
      <div className={mergeClassNames('grid w-[230px] gap-2 rounded-lg bg-slate-900 p-3', className)}>
        <div className='flex h-8 items-center justify-between gap-2 text-slate-50'>
          <h3 className='text-sm font-semibold'>{t('Ready for production?')}</h3>
          {showCloseButton && (
            <Button variant='text' env='dark' size='small' isIconButton onClick={onCloseClick} className={buttonClassName}>
              <Icon size='medium' type='close' />
            </Button>
          )}
        </div>

        <p className='text-xs text-slate-300'>{t('Upgrade to a production license before launching.')}</p>

        <LinkButton
          to='/dashboard/settings/billing'
          size='small'
          isFullWidth
          onClick={onCloseClick}
        >
          {t('Upgrade')}
        </LinkButton>
      </div>
    )
  }
}

export default UpgradeBanner
