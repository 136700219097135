import React from 'react'

const Toolbar = ({ style, className, children }) => {
  return (
    <nav style={style} className={className}>
      {children}
    </nav>
  )
}

export default Toolbar
