import React from 'react'
import { transform } from 'lodash'
import mergeClassNames from '../utils/mergeClassNames'

const TimelineEvent = ({ icon, title, subtitle, changes, variant = 'audit', className, children }) => {
  const renderChange = (key, value) => {
    if (typeof value === 'boolean') {
      value = JSON.stringify(value)
    }

    if (value === null) {
      value = 'default'
    }

    return (
      <div key={key} className='flex flex-wrap items-center gap-x-1 rounded-lg bg-gray-50 px-2 py-1 text-xs text-gray-700'>
        <span className='break-all text-slate-900 sm:break-normal'>{key}</span>
        <span> → </span>
        <span className='break-all'>{value}</span>
      </div>
    )
  }

  const renderChanges = (changes) => {
    function flattenObject (ob, prefix = false, accumulator = {}) {
      return transform(ob, (result, value, key) => {
        const prefixed = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'object' && value !== null) {
          return flattenObject(value, prefixed, result)
        }

        result[prefixed] = value

        return result
      }, accumulator)
    }

    if (!changes || Object.keys(changes).length === 0) {
      return null
    }

    return <div>{Object.entries(flattenObject(changes)).map(e => renderChange(...e))}</div>
  }

  return (
    <div className={mergeClassNames('relative size-full min-w-0 text-xs text-gray-700', className)}>
      <div className='flex gap-x-3'>
        <div className='relative flex flex-row gap-x-1.5'>
          {/* Vertical line */}
          <div className='ms-1 mt-5 h-[calc(100%+var(--connecting-line-length))] min-h-[--connecting-line-length] border-l border-gray-200 [--connecting-line-length:24px] group-last/list-item:hidden' />
          {/* Circle next to each entry */}
          <div className='absolute start-px top-3 size-2 rounded-full border border-gray-200 bg-white lg:start-[.5px]' />
          {/* Extra margin on last element to keep it aligned with the rest */}
          <div className='group-last/list-item:ms-2.5' />
          <div className='flex w-9 min-w-fit justify-center empty:me-0 empty:w-0'>{icon}</div>
        </div>
        <div className='flex w-full min-w-0 flex-col'>
          <div>
            <span className='text-slate-900'>{title}</span>{' '}
            <span>{subtitle}</span>
          </div>
          <div className={mergeClassNames('mt-2 flex flex-col gap-y-2 empty:hidden')}>
            {children}
            {renderChanges(changes)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimelineEvent
