import React from 'react'
import Sidebar from '../../components/Sidebar'
import OrganisationName from './OrganisationName'
import AccountIdentifiers from './AccountIdentifiers'
import DeviceCount from './DeviceCount'
import AccountCreatedAt from './AccountCreatedAt'
import AdvancedConfiguration from './AdvancedConfiguration'
import Switch from '../../components/Switch'
import Account from '../accounts/Account.state'
import LimitEditor from './LimitEditor'
import StripeSubscription from './StripeSubscription'
import { useHasPaidSubscription } from '../../hooks/useHasPaidSubscription'
import Collapsible from '../../components/Collapsible'
import ViewAccountButton from './ViewAccountButton'

const Header = ({ account }) => {
  return (
    <div>
      <OrganisationName account={account} variant='text' />
      <AccountIdentifiers account={account} />
    </div>
  )
}

const AccountEditorSidebar = ({ account, user, updateFeatureSwitch, updateLimit, updateStripeSubscription, handleKeyDown }) => {
  const hasPaidSubscription = useHasPaidSubscription(account)

  return (
    <Sidebar
      className='fixed inset-y-4 right-0 z-30 max-h-[calc(100vh-32px)]'
      Header={<Header account={account} />}
      HeaderAction={<ViewAccountButton account={account} />}
      scrollableContainerClassName='py-2 md:gap-y-4'
    >
      {account && (
        <>
          <DeviceCount account={account} />
          <AccountCreatedAt account={account} />
          <div className='flex items-center gap-x-2'>
            <Switch
              name='upgrade_banners'
              label='Banners'
              disabled={hasPaidSubscription}
              checked={Account.hasFeature(account, 'upgrade_banners')}
              onChange={(name, checked) => updateFeatureSwitch(account, 'upgrade_banners', checked)}
              labelClassName='flex items-center h-full text-gray-700'
            />
          </div>
          <div className='flex flex-col gap-y-2 text-xs text-gray-700'>
            <Collapsible title='Limits' className='flex flex-col gap-y-2'>
              <LimitEditor
                label='Period Sessions'
                account={account}
                field='sessions'
                currentValueField='sessions_period'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
              <LimitEditor
                label='Active Sessions'
                account={account}
                field='session_concurrency'
                currentValueField='sessions_active'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
              <LimitEditor
                label='Timeout'
                account={account}
                field='session_duration'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
              <LimitEditor
                label='Period Members'
                account={account}
                field='members'
                currentValueField='members_period'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
              <LimitEditor
                label='Daily Members'
                account={account}
                field='members_daily'
                currentValueField='members_daily'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
              <LimitEditor
                label='Members Concurrency'
                account={account}
                field='members_concurrency'
                user={user}
                updateLimit={updateLimit}
                handleKeyDown={handleKeyDown}
              />
            </Collapsible>
            <Collapsible title='Stripe' className='flex flex-col gap-y-2'>
              <StripeSubscription
                account={account}
                user={user}
                updateStripeSubscription={updateStripeSubscription}
                handleKeyDown={handleKeyDown}
              />
            </Collapsible>
            <AdvancedConfiguration account={account} />
          </div>
        </>
      )}
    </Sidebar>
  )
}

export default AccountEditorSidebar
