import React, { useCallback } from 'react'
import { ExpandableVerticalMenu } from './ExpandableVerticalMenu'
import mergeClassNames from '../utils/mergeClassNames'
import { useTranslation } from 'react-i18next'

export function ColorPicker ({
  selectedColor,
  onColorPicked,
  colors
}) {
  const { t } = useTranslation()

  const Btn = useCallback(({ isExpanded, className, ...props }) => (
    <button
      className={mergeClassNames('size-5 cursor-pointer rounded-full shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_rgba(245,246,251,0.5)] hover:shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_rgba(245,246,251,1)]', className)}
      style={{ background: selectedColor }}
      title={t('Annotations color')}
      {...props}
    />
  ), [selectedColor, t])

  if (colors.length === 1) {
    return <div className='flex size-12 items-center justify-center'><Btn className='cursor-not-allowed' /></div>
  }

  return (
    <ExpandableVerticalMenu Button={Btn}>
      {colors.map((color) => {
        return (
          <div
            className='flex items-center justify-center md:rounded'
            key={`color-picker-${color}`}
          >
            <button
              className={mergeClassNames('size-5 cursor-pointer rounded-full hover:shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_#F5F6FB]', color === selectedColor && 'shadow-[0_0_0_2px_#0E0C26,0_0_0_3px_#F5F6FB]')}
              style={{ background: color }}
              onClick={() => onColorPicked(color)}
            />
          </div>
        )
      })}
    </ExpandableVerticalMenu>

  )
}
