import React from 'react'
import { Icon } from '../../components/Icon'
import Timestamp from '../../components/Timestamp'

const AccountCreatedAt = ({ account }) => {
  return (
    <div className='flex shrink-0 items-center gap-x-1 text-xs'>
      <Icon type='calendar-event' size='small' className='text-gray-700' />
      <span>Created <Timestamp timestamp={account.created} /></span>
    </div>
  )
}

export default AccountCreatedAt
