import React from 'react'
import mergeClassNames from '../../utils/mergeClassNames'

const DetailItem = ({ label, value, limit, className }) => (
  <div className={mergeClassNames('flex flex-col gap-1 text-xs text-gray-700', className)}>
    <div>{label}</div>
    <div className='truncate font-medium'>
      <span className='text-slate'>{value}</span>
      {limit && (
        <>
          <span className='font-normal'> of </span>
          <span className='text-slate'>{limit}</span>
        </>
      )}
    </div>
  </div>
)

export default DetailItem
