// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../icons/laserpointer-cursor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnnotationEditor.tool-laser *,
.AnnotationEditor.tool-laser {
    cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 12 12.5, auto !important;
}

.AnnotationEditor.tool-control *,
.AnnotationEditor.tool-control {
    cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/modules/sessions/AnnotationEditor.css"],"names":[],"mappings":"AAAA;;IAEI,wEAA2E;AAC/E;;AAEA;;IAEI,0BAA0B;AAC9B","sourcesContent":[".AnnotationEditor.tool-laser *,\n.AnnotationEditor.tool-laser {\n    cursor: url('../../icons/laserpointer-cursor.svg') 12 12.5, auto !important;\n}\n\n.AnnotationEditor.tool-control *,\n.AnnotationEditor.tool-control {\n    cursor: pointer !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
