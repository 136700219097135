import React, { useCallback } from 'react'
import mergeClassNames from '../../utils/mergeClassNames'
import { Icon } from '../../components/Icon'

function AndroidControl ({ key, className, onClick, children }) {
  return <button data-android='true' key={key} className={mergeClassNames('w-8 cursor-pointer text-sm hover:text-slate-300', className)} onClick={onClick}>{children}</button>
}

export function AndroidControls ({
  width,
  scale,
  onToolUsed
}) {
  const handleAndroidBack = useCallback(() => {
    onToolUsed('control', {
      type: 'keydown',
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
      key: 'GoBack',
      code: 'GoBack'
    })
  }, [onToolUsed])

  const handleAndroidHome = useCallback(() => {
    onToolUsed('control', {
      type: 'keydown',
      // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
      key: 'GoHome',
      code: 'GoHome'
    })
  }, [onToolUsed])

  return (
    <div
      className='m-2 flex min-w-0 max-w-[80%] flex-row flex-wrap items-center justify-between gap-4 whitespace-nowrap text-slate-50 empty:hidden md:flex-nowrap'
      style={{ minWidth: width * scale }}
    >
      <AndroidControl className='me-1 ms-0 rotate-180' onClick={handleAndroidBack}><Icon type='play' size='small' /></AndroidControl>
      <AndroidControl className='me-1 ms-1' onClick={handleAndroidHome}><Icon type='circle' size='small' /></AndroidControl>
    </div>
  )
}
