import React from 'react'
import mergeClassNames from '../utils/mergeClassNames'

const SettingsContainerRoot = ({ children, className }) => (
  <div className={mergeClassNames('flex flex-col divide-y divide-gray-200 px-3 md:px-4', className)}>
    {children}
  </div>
)

const Header = ({ children, className, ...props }) => (
  <div className={mergeClassNames('flex flex-col gap-y-3 pb-4 pt-6 md:px-2 md:py-6 [&>h2]:text-lg [&_a]:underline [&_p]:text-sm [&_p]:text-gray-700', className)} {...props}>
    {children}
  </div>
)

const Body = ({ children, className, ...props }) => (
  <div className={mergeClassNames('flex flex-col divide-y divide-gray-200', className)} {...props}>
    {children}
  </div>
)

export const SettingsContainer = Object.assign(SettingsContainerRoot, { Header, Body })
