import React, { Component } from 'react'
import DOMNode from './DOMNode'

export default class DOMImg extends Component {
  static nodeFilter (node) {
    return node
  }

  handleError = (e) => {
    const { src } = this.props.node.attributes
    const error = new Error(`Failed to load Image: "${src}"`, { cause: 'load-error' })
    this.props.onDOMError(this.props.node.id, error)

    // withPriority uses onError to track loading within the scheduler
    this.props.onError?.(e)
  }

  render () {
    return <DOMNode {...this.props} key={this.props.node.id} node={this.props.node} onError={this.handleError} />
  }
}
