import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import User from '../users/User.state'

const LoginSuccessScreen = () => {
  const user = useSelector(state => User.fromState(state))
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.resource) {
      navigate(`/login${window.location.search}`, { replace: true })
    }
  }, [user.resource, navigate])

  if (user.working) {
    return null
  }

  return (
    <div className='flex h-full flex-col items-center justify-center gap-y-3'>
      <div><b>{t('You are now logged into Cobrowse.')}</b></div>
      <div>{t('You can close this window.')}</div>
    </div>
  )
}

export default LoginSuccessScreen
