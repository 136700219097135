import { useLayoutEffect } from 'react'

const originalTitle = document.title

const useDocumentTitle = (title) => {
  useLayoutEffect(() => {
    if (!title) {
      return
    }

    document.title = [title, originalTitle].join(' | ')

    return () => {
      document.title = originalTitle
    }
  }, [title])
}

export default useDocumentTitle
