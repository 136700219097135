import React, { forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTestId } from '../../utils/getTestId'
import mergeClassNames from '../../utils/mergeClassNames'
import CodeEntryInput from './CodeEntryInput'
import Session from './Session.state'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const CODE_LENGTH = 6

function getElementIndex (refsArr, elem) {
  return refsArr.findIndex(el => el === elem)
}

function focusPreviousInput (refsArr, elem) {
  refsArr[Math.max(getElementIndex(refsArr, elem) - 1, 0)]?.focus()
}

function focusNextInput (refsArr, elem) {
  refsArr[Math.min(getElementIndex(refsArr, elem) + 1, CODE_LENGTH - 1)]?.focus()
}

const CodeEntry = forwardRef(({ className, inputClassName, focusOnRender = false, children, inputStyleFn }, ref) => {
  const [validating, setValidating] = useState(false)
  const [code, setCode] = useState(['', '', '', '', '', ''])
  const codeEntryLabelId = useId()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useImperativeHandle(ref, () => {
    return {
      clear () {
        setCode(['', '', '', '', '', ''])
      },
      focus (index) {
        refs.current[index]?.focus()
      }
    }
  }, [])

  const refs = useRef([])

  // Run the tryComplete function when the code state changes
  useEffect(() => {
    tryComplete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  const validateCode = async (code) => {
    const session = await dispatch(Session.actionCreators().sessionForCode(code))

    if (session) {
      navigate(`/session/${session.id}${window.location.search}`)
    }
  }

  const getCode = () => {
    return code.join('').substring(0, CODE_LENGTH)
  }

  const setDigit = (digit, value) => {
    if (digit > (CODE_LENGTH - 1)) {
      return
    }

    setCode((code) => {
      const updatedCode = [...code]

      updatedCode[digit] = value

      return updatedCode
    })
  }

  const isEditable = () => {
    return !validating
  }

  const tryComplete = () => {
    if (!isEditable()) {
      return
    }

    const codeValue = getCode()

    if (codeValue.length !== CODE_LENGTH) {
      return
    }

    setValidating(true)
    validateCode(codeValue)
      .then((isValid) => {
        if (!isValid) {
          setValidating(false)
        }

        if (!isValid) {
          setTimeout(() => {
            refs.current[CODE_LENGTH - 1]?.focus()
          }, 0)
        }
      }).catch(() => {
        setValidating(false)
      })
  }

  const handlePaste = (event) => {
    if (!isEditable()) {
      return false
    }

    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text').toString()

    // Exit early if the pasted data contains anything other than just digits
    if (!/^\d+$/.test(pastedData)) {
      return event.preventDefault()
    }

    const digits = pastedData.split('')
    // Start pasting from the first digit if the clipboard holds a full code,
    // otherwise just paste from the currently selected input onwards
    let digit = pastedData.length === CODE_LENGTH
      ? 0
      : parseInt(event.target.getAttribute('data-digit'), 10)

    digits.forEach((value) => {
      setDigit(digit, value)

      digit += 1
    })

    refs.current[Math.min(digit, CODE_LENGTH - 1)]?.focus()

    return event.preventDefault()
  }

  const handleOnChange = (event) => {
    const value = event.target.value.slice(-1)

    // validate text on any change
    const digit = parseInt(event.target.getAttribute('data-digit'), 10)

    // Only allow digits and deleting the current value
    if (/\d/.test(value) || value === '') {
      setDigit(digit, value)
    }

    if (!event.repeat && /\d/.test(value)) {
      event.target.value = ''

      focusNextInput(refs.current, event.target)
    }
  }

  const handleKeyDown = (event) => {
    // on backspace on empty node go back to previous
    if (event.target.value.length === 0 && event.keyCode === 8) {
      focusPreviousInput(refs.current, event.target)
    } else if (event.keyCode === 37) { // left arrow moves to previous
      focusPreviousInput(refs.current, event.target)
      return event.preventDefault()
    } else if (event.keyCode === 39) { // right arrow moves to next
      focusNextInput(refs.current, event.target)
      return event.preventDefault()
    }
  }

  const invalid = (!validating) && (getCode().length === CODE_LENGTH)

  return (
    <div className={className} {...getTestId('code-entry')}>
      <div className={mergeClassNames('flex items-center gap-x-1', invalid && 'animate-shake')} role='group' aria-labelledby={`code-entry-label-${codeEntryLabelId}`}>
        <span className='sr-only' id={`code-entry-label-${codeEntryLabelId}`}>{t('Code entry')}</span>
        {code.map((value, index) => (
          <CodeEntryInput
            key={index}
            className={typeof inputClassName === 'function' ? inputClassName(index) : inputClassName}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            onPaste={handlePaste}
            disabled={!isEditable()}
            digit={index}
            value={value}
            focusOnRender={focusOnRender && index === 0}
            ref={(el) => { refs.current[index] = el }}
          />
        ))}
        {children}
      </div>
    </div>
  )
})

export default CodeEntry
