import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import Input from '../../components/Input'
import FirebaseConfig from '../push-notifications/FirebaseConfig.state'
import { SettingsGroup } from '../../components/SettingsGroup'
import { SettingsContainer } from '../../components/SettingsContainer'
import Label from '../../components/Label'
import Alert from '../../components/Alert'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'
import { Icon } from '../../components/Icon'
import Dialog from '../../components/Dialog'

const FirebaseSettings = () => {
  const [firebasePrivateKeyConfig, setFirebasePrivateKeyConfig] = useState('')
  const [showSavePrivateKeyConfigDialog, setShowSavePrivateKeyConfigDialog] = useState(false)
  const [showSavePrivateKeyConfigError, setShowSavePrivateKeyConfigError] = useState(false)
  const dispatch = useDispatch()
  const firebaseConfig = useSelector(state => FirebaseConfig.fromState(state))
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(FirebaseConfig.actionCreators().getFirebaseConfig())
  }, [dispatch])

  const updateFirebaseConfig = () => {
    setShowSavePrivateKeyConfigError(false)

    try {
      const config = JSON.parse(firebasePrivateKeyConfig)

      // Ensure all required keys are present
      if (['private_key', 'client_email', 'project_id'].every(key => config[key])) {
        dispatch(FirebaseConfig.actionCreators().updateFirebaseConfig({
          private_key: config.private_key,
          client_email: config.client_email,
          project_id: config.project_id
        }))

        setShowSavePrivateKeyConfigDialog(false)

        return
      }

      console.warn('Invalid private key config')
    } catch (e) {
      console.warn('Invalid private key config', e)
    }

    setShowSavePrivateKeyConfigError(true)
  }

  const renderFirebaseConfig = () => {
    if (!firebaseConfig.resource) {
      return null
    }

    return (
      <SettingsGroup>
        <SettingsGroup.Title>{t('Firebase configuration')}</SettingsGroup.Title>
        <SettingsGroup.HelpText>
          {t('Add your Firebase Private Key here to enable push notifications for agent initiated sessions.')}
        </SettingsGroup.HelpText>
        <SettingsGroup.Body className='flex flex-col gap-y-4'>
          {firebaseConfig?.resource.configured && (
            firebaseConfig.resource.using_legacy
              ? (
                <Alert type='warning'>
                  {t('You have configured Firebase using a server key which is deprecated and will be removed in a future release. Please update your configuration to use a private key instead.')}
                </Alert>
                )
              : (
                <>
                  <div>
                    <Label htmlFor='firebase-project-id'>{t('Firebase project ID')}</Label>
                    <Input
                      id='firebase-project-id'
                      readOnly
                      value={firebaseConfig.resource.project_id}
                    />
                  </div>
                  <div>
                    <Label htmlFor='firebase-client-email'>{t('Firebase client email')}</Label>
                    <Input
                      id='firebase-client-email'
                      readOnly
                      value={firebaseConfig.resource.client_email}
                    />
                  </div>
                  <div>
                    <Label htmlFor='firebase-private-key'>{t('Firebase private key')}</Label>
                    <TextArea
                      className='overflow-hidden font-mono'
                      id='firebase-private-key'
                      rows={3}
                      readOnly
                      value={firebaseConfig.resource.private_key}
                    />
                  </div>
                </>
                )
          )}
          <div>
            <Button variant='tertiary' className='md:h-9' onClick={() => setShowSavePrivateKeyConfigDialog(true)}>
              <Icon type='plus' />
              {t('Save new private key')}
            </Button>
          </div>
          <Dialog
            panelClassName='max-w-[600px]'
            isOpen={showSavePrivateKeyConfigDialog}
            titleText={t('Confirm new private key')}
            acceptText={t('Save new private key')}
            onAccept={updateFirebaseConfig}
            onCancel={() => setShowSavePrivateKeyConfigDialog(false)}
            onClose={() => setShowSavePrivateKeyConfigDialog(false)}
          >
            <p>{t('Once you have downloaded the private key JSON file from Firebase, paste its contents below.')}</p>
            {showSavePrivateKeyConfigError && (
              <Alert type='alert'>
                {t('Invalid private key config.')}
              </Alert>
            )}
            <div>
              <Label htmlFor='firebase-private-key-config'>{t('Firebase private key config')}</Label>
              <TextArea
                className='font-mono'
                id='firebase-private-key-config'
                rows={10}
                onChange={(e) => setFirebasePrivateKeyConfig(e.target.value)}
                placeholder={`{
  "type": "service_account",
  "project_id": "your-project-id",
  "private_key_id": "your-private-key-id",
  "private_key": "----- your-private-key -----",
  "client_email": "your-client-email",
  "client_id": "your-client-id",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://accounts.google.com/o/oauth2/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/your-client-email",
  "universe_domain": "googleapis.com"
}`}
              />
            </div>
          </Dialog>
        </SettingsGroup.Body>
      </SettingsGroup>
    )
  }

  return (
    <SettingsContainer>
      <SettingsContainer.Header>
        <h2>{t('Push notifications')}</h2>
        <p>
          {t("By default, the Cobrowse SDKs open a socket to handle incoming session requests so there's nothing you have to do to use agent initiated sessions.")}
          {' '}
          {t('You may optionally choose to send new session requests over the devices native push channel instead. Cobrowse supports this configuration out of box using Firebase Cloud Messaging (FCM).')}
        </p>
        <Alert type='info'>
          <Trans>See the <a className='text-slate underline' target='_blank' rel='noopener noreferrer' href='https://docs.cobrowse.io/sdk-features/initiate-sessions-with-push'>documentation for your platform</a> for more information.</Trans>
        </Alert>
      </SettingsContainer.Header>
      <SettingsContainer.Body>
        {renderFirebaseConfig()}
      </SettingsContainer.Body>
    </SettingsContainer>
  )
}

export default FirebaseSettings
