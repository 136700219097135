import React from 'react'
import { getTestId } from '../utils/getTestId'
import mergeClassNames from '../utils/mergeClassNames'

export function SessionOverlayMessage ({ children, testId = 'session-overlay-message', className }) {
  return (
    <div
      className={mergeClassNames('absolute inset-0 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-slate-900/85 p-5 px-2 text-center text-white', className)}
      {...getTestId(testId)}
    >
      {children}
    </div>
  )
}
