import qs from 'qs'
import { gte } from 'semver/preload'
import RESTObject from '../redux-rest'
import User from '../users/User.state'
import { updatePresence, resetPresence } from './Presence.state'

export default class Device extends RESTObject {
  static namespace () {
    return 'Device'
  }

  static url (params = {}, context = {}) {
    return `/api/1/devices/${params.id || ''}?${qs.stringify(context, { arrayFormat: 'brackets' })}`
  }

  static sdkVersion (device) {
    return device.sdk_version
  }

  static meetsVersion (device, versions) {
    const version = versions[device.platform]
    if ((!version) || (!Device.sdkVersion(device))) return true
    return gte(Device.sdkVersion(device), version)
  }

  static lastSeen (device, presence) {
    const lastSeen = new Date((presence && presence.last_seen) || 0).getTime()
    const lastActive = new Date(device.last_active).getTime()
    return new Date(Math.max(lastSeen, lastActive))
  }

  static isConnectable (device, presence) {
    // if the device record says the device isn't connectable we can stop here
    if (!device.connectable) return false
    // otherwise, if the device looks like it should have a presence record,
    // check if it's actually online
    if (device.notification_token) return !!(presence && presence.present)
    // otherwise this is probably a firebase-enabled device, so it is always "online"
    return true
  }

  static isAwaitingPresence (device) {
    return !!device.notification_token && !device.presence
  }

  static _doSubscribe (socket, device) {
    if (device.notification_token) {
      socket.send('subscribe', device.notification_token)
      socket.send('sync-presence', device.id)
    }
  }

  static _sendFilter (socket) {
    if (socket.connected) { socket.send('filter', { events: ['presence', 'device'] }) }
  }

  static subscribe (device) {
    return (dispatch, getState) => {
      if (!this.socket) {
        this.socket = dispatch(User.getSocket())
        this.socket.on('device', resource => dispatch(Device.cache(resource, { add: false })))
        this.socket.on('presence', presence => dispatch(updatePresence(presence)))
        this.socket.on('open', () => {
          dispatch(resetPresence())
          this._sendFilter(this.socket)
          Device.fromState(getState()).collection.forEach(d => {
            this._doSubscribe(this.socket, d)
          })
        })
        this._sendFilter(this.socket)
      }
      if (this.socket.connected && (!device.presence)) this._doSubscribe(this.socket, device)
    }
  }

  static fromState (state) {
    const augment = d => {
      if (!d) return d
      return {
        ...d,
        presence: state.presence[d.id],
        last_seen: this.lastSeen(d, state.presence[d.id]),
        connectable: this.isConnectable(d, state.presence[d.id])
      }
    }
    const deviceState = super.fromState(state)
    return {
      ...deviceState,
      collection: deviceState.collection.map(augment),
      resource: augment(deviceState.resource)
    }
  }

  static actionCreators () {
    return {
      ...super.actionCreators(),
      getPresence: this.subscribe.bind(this)
    }
  }
}
