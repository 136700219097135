import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import Dialog from './Dialog'
import mergeClassNames from '../utils/mergeClassNames'
import { useDispatch, useSelector } from 'react-redux'
import { updateUiState } from '../modules/ui-state/UI.state'
import { getTestId } from '../utils/getTestId'
import { useIsAboveBreakpoint } from '../hooks/useIsAboveBreakpoint'
import { Icon } from './Icon'
import { Transition } from '@headlessui/react'

export function useSidebarState () {
  const isOpen = useSelector((state) => state.ui.sidebarOpen)
  const dispatch = useDispatch()

  const toggle = useCallback((forceState) => {
    dispatch(updateUiState({ sidebarOpen: typeof forceState === 'boolean' ? forceState : !isOpen }))
  }, [dispatch, isOpen])

  return useMemo(() => [isOpen, toggle], [isOpen, toggle])
}

export function SidebarHeader ({ children, HeaderAction, onClose }) {
  const [, toggle] = useSidebarState()
  const { t } = useTranslation()

  const close = () => {
    toggle(false)
    onClose?.()
  }

  return (
    <>
      <div className='flex w-full flex-col rounded-t-lg bg-white p-4'>
        <div className='flex items-center justify-between'>
          {children}
          <Button
            variant='text'
            onClick={close}
            isIconButton
            size='big'
          >
            <span className='sr-only'>{t('Close')}</span>
            <Icon type='close' size='large' className='text-slate-900' reverse />
          </Button>
        </div>
        {HeaderAction}
      </div>
    </>
  )
}

function SidebarContent ({ children, className }) {
  return (
    <div className='min-h-0 flex-1 overflow-hidden'>
      <div className={mergeClassNames('flex h-full flex-col gap-y-4 overflow-y-auto px-4 pb-4 md:gap-y-6 md:pt-2', className)}>
        {children}
      </div>
    </div>
  )
}

export const DesktopToggleButton = ({ className }) => {
  const { t } = useTranslation()
  const [, toggle] = useSidebarState()

  return (
    <Button
      env='dark'
      isIconButton
      variant='text'
      size='extra-large'
      title={t('Device info')}
      data-selected={false}
      onClick={toggle}
      {...getTestId('desktop-toolbar-device-info')}
      className={mergeClassNames('rounded-full', className)}
    >
      <span className='sr-only'>{t('Device info')}</span>
      <Icon type='info-circle' size='large' />
    </Button>
  )
}

export function MobileToggleButton ({ className }) {
  const { t } = useTranslation()
  const [, toggle] = useSidebarState()

  return (
    <Button
      env='dark'
      isIconButton
      variant='tertiary'
      size='extra-large'
      title={t('Device info')}
      onClick={toggle}
      {...getTestId('toolbar-device-info')}
      className={mergeClassNames('rounded-full', className)}
    >
      <Icon type='info-circle' size='large' />
    </Button>
  )
}

function MobileSidebar ({ children, Header, HeaderAction, scrollableContainerClassName, onClose }) {
  const [isOpen, toggle] = useSidebarState()
  const [dialogIsOpen, setDialogIsOpen] = useState(isOpen)
  const isDesktop = useIsAboveBreakpoint(740)

  useEffect(() => {
    if (isDesktop && isOpen) {
      setDialogIsOpen(false)
    }

    if (!isDesktop) {
      setDialogIsOpen(isOpen)
    }
  }, [isOpen, isDesktop])

  const close = () => {
    toggle()
    onClose?.()
  }

  return (
    <Dialog
      isOpen={dialogIsOpen}
      onClose={close}
      panelClassName='absolute inset-4 rounded-lg bg-white p-0 md:hidden'
      bodyClassName='h-full max-h-full gap-y-0 p-0'
    >
      <SidebarHeader HeaderAction={HeaderAction}>
        {Header}
      </SidebarHeader>
      <SidebarContent className={scrollableContainerClassName}>{children}</SidebarContent>
    </Dialog>
  )
}

export function DesktopSidebar ({ children, Header, className, HeaderAction, scrollableContainerClassName, onClose }) {
  const [isOpen, toggle] = useSidebarState()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        toggle(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggle])

  return (
    <div className={mergeClassNames('invisible h-full max-h-[calc(100vh-96px)] w-0 transition-all duration-300 ease-in-out motion-reduce:transition-none', isOpen && 'visible w-[calc(335px+16px)]', className)}>
      <Transition
        show={isOpen}
        className='flex h-full w-[335px] flex-col rounded-lg bg-white shadow-menus transition-opacity duration-300 motion-reduce:transition-none'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <SidebarHeader HeaderAction={HeaderAction} onClose={onClose}>
          {Header}
        </SidebarHeader>
        <SidebarContent className={scrollableContainerClassName}>{children}</SidebarContent>
      </Transition>
    </div>
  )
}

function Sidebar (props) {
  const dispatch = useDispatch()

  useEffect(() => {
    // ensure we reset the state when unmounting
    // we use dispatch directly as the toggle reference is being changed
    // when isOpen changes
    return () => dispatch(updateUiState({ sidebarOpen: false }))
  }, [dispatch])

  return (
    <div className='relative'>
      <div className='block md:hidden'>
        <MobileSidebar {...props} />
      </div>
      <div className='hidden h-full md:block'>
        <DesktopSidebar {...props} />
      </div>
    </div>
  )
}

export default Sidebar
