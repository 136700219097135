import React, { Fragment } from 'react'
import Account from './Account.state'
import { ListBox } from '../../components/Listbox'
import { Link as NavLink } from 'react-router-dom'
import mergeClassNames from '../../utils/mergeClassNames'
import isInIframe from '../../utils/isInIframe'
import useActiveAccountSelector from '../../hooks/useActiveAccountSelector'
import LinkButton from '../../components/LinkButton'
import { Icon } from '../../components/Icon'

const SettingsMenu = ({ items, currentItem }) => {
  const account = useActiveAccountSelector().resource
  const viewTransitionsEnabled = window.matchMedia('(prefers-reduced-motion: reduce)') === true || window.matchMedia('(prefers-reduced-motion: reduce)').matches === true

  if (!currentItem) {
    return false
  }

  return (
    <nav className='sticky top-0 z-10 flex w-full justify-center border-b border-gray-200 bg-white p-3 md:justify-start md:px-4 md:pb-0 md:pt-2'>
      <div className='flex w-full md:hidden'>
        <ListBox selected={currentItem}>
          {items.map((item, index) => {
            if (item.feature && !Account.hasFeature(account, item.feature, false)) {
              return null
            }

            return (
              <ListBox.Option key={index} value={item} as={Fragment}>
                {({ selected, active }) => (
                  <LinkButton
                    to={`${item.path}${window.location.search}`}
                    variant={selected ? 'primary' : 'text'}
                    target={item.targetIfIframe && isInIframe() ? item.targetIfIframe : undefined}
                    title={item.title}
                    className={mergeClassNames('h-auto w-full justify-between gap-x-1 py-2.5 font-normal', (selected || active) && 'bg-slate-100 text-slate-900 hover:bg-gray-100')}
                  >
                    {item.title}
                    {selected && <Icon type='circle-check' size='small' className='text-violet' />}
                  </LinkButton>
                )}
              </ListBox.Option>
            )
          })}
        </ListBox>
      </div>
      <div className='relative hidden md:flex'>
        {items.map((item, index) => {
          if (item.feature && !Account.hasFeature(account, item.feature, false)) {
            return null
          }

          const isCurrent = currentItem.path === item.path

          return (
            <NavLink
              key={index}
              title={item.title}
              to={`${item.path}${window.location.search}`}
              target={item.targetIfIframe && isInIframe() ? item.targetIfIframe : undefined}
              className={mergeClassNames(
                'relative flex cursor-pointer items-center gap-x-3 rounded-lg p-2 text-gray-700 hover:text-black md:w-auto md:rounded md:p-1 md:px-2 md:py-3',
                isCurrent && 'text-violet'
              )}
              data-tooltip={item.title}
              viewTransition={!viewTransitionsEnabled}
            >
              <span className={mergeClassNames('hidden text-xs font-medium md:block')}>
                {item.title}
              </span>
              <div className={mergeClassNames('absolute bottom-0 left-0 h-0.5 w-full bg-violet [view-transition-name:active-link-indicator]', !isCurrent && 'hidden')} />
            </NavLink>
          )
        })}
      </div>
    </nav>
  )
}

export default SettingsMenu
