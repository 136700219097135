import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import Callout from '../../components/Callout'
import { SettingsGroup } from '../../components/SettingsGroup'
import mergeClassNames from '../../utils/mergeClassNames'
import ServerTime from '../../utils/ServerTime'

const SessionTimeoutCountdown = ({ expires }) => {
  const { t } = useTranslation()
  const [, setRenderCount] = useState(0)

  useEffect(() => {
    const renderInterval = setInterval(() => setRenderCount((renderCount) => ++renderCount), 1000)

    return () => clearInterval(renderInterval)
  }, [])

  const remainingTime = () => {
    if (!expires) {
      return null
    }

    return Math.max(0, moment(new Date(expires)).diff(ServerTime.now()))
  }

  const renderMessage = () => {
    const remaining = remainingTime()

    if (remaining === null) {
      return null
    }

    const nearTimeout = remaining < (30 * 1000)

    return (
      <div className={mergeClassNames('hidden min-w-13 items-center gap-x-1 text-gray-200 sm:flex md:min-w-32', nearTimeout && 'text-red-500')}>
        <Trans><b>{{ time: moment.utc(remaining).format('mm:ss') }}</b><span className='hidden font-normal text-slate-500 md:inline'> remaining</span></Trans>
      </div>
    )
  }

  const renderCallout = () => {
    return (
      <SettingsGroup className='z-50 w-[300px] max-w-[calc(100vw-100px)] whitespace-normal rounded-lg p-3 text-black'>
        <SettingsGroup.Body className='p-0'>
          <Trans><b className='font-semibold'>Development version only.</b> This time limit exists during development and testing only.</Trans>
          <p className='mt-2.5 text-gray-700'><Trans>When you’re ready to go live please <Link to='/dashboard/settings/billing' className='underline'>upgrade</Link> to a production license.</Trans></p>
        </SettingsGroup.Body>
        <SettingsGroup.Footer className='mt-2.5 bg-none p-0 text-center'>
          <LinkButton variant='primary' target='_blank' to='/pricing' isFullWidth>{t('See pricing')}</LinkButton>
        </SettingsGroup.Footer>
      </SettingsGroup>
    )
  }

  return (
    <div className='text-slate-900' title='Free session time remaining'>
      <Callout content={renderCallout} contentClassName='bottom-12 start-0'>
        {renderMessage()}
      </Callout>
    </div>
  )
}

export default SessionTimeoutCountdown
