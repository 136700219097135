import { useEffect } from 'react'

export function useClickOutside (ref, onClickOutside, shouldListen = true) {
  useEffect(() => {
    if (!shouldListen) return

    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      event.stopImmediatePropagation()
      event.preventDefault()

      // clicking on a button will trigger pointerdown and click events
      // if we call onClickOutside directly, shouldListen will be false
      // and we'll remove the listener before the click event is triggered
      // so the button will still be clickable
      // to avoid this, we'll only call onClickOutside on the last event
      if (event.type === 'click' || event.type === 'touchstart') {
        onClickOutside(event)
      }
    }

    // Listen to pointer events in the capturing phase
    document.addEventListener('mousedown', listener, { capture: true, passive: false })
    document.addEventListener('touchstart', listener, { capture: true, passive: false })
    document.addEventListener('click', listener, { capture: true, passive: false })

    return () => {
      document.removeEventListener('mousedown', listener, { capture: true, passive: false })
      document.removeEventListener('touchstart', listener, { capture: true, passive: false })
      document.removeEventListener('click', listener, { capture: true, passive: false })
    }
  }, [ref, onClickOutside, shouldListen])
}
